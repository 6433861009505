import { useRef } from 'react';

export const useLastDefinedValue = <T>(value: T | undefined) => {
  const lastValueRef = useRef<T | undefined>(value);

  if (value !== undefined) {
    lastValueRef.current = value;
  }

  return lastValueRef.current;
};
