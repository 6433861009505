import {
  type ListeningExperience,
  type EmbeddedReadingBundle as SDKEmbeddedReadingBundle,
  type ReadingBundle as SDKReadingBundle,
  type VoiceSpecOfAvailableVoice
} from '@speechifyinc/multiplatform-sdk';

import { PSPDFKitFacade } from 'lib/pdf/pspdfkit';
import type { Virtualizer } from 'modules/listening/features/reader/components/classic/ClassicReaderV2';
import { listeningSettingsStoreActions } from 'modules/listening/features/settings/settingsStore';
import { MeasurementKey } from 'modules/profiling/measurementTypes';
import { profilingStoreActions } from 'modules/profiling/profilingStore';
import { fetchInitialSpeedInWPM } from 'modules/speed/store/speedStore';
import { subscriptionStoreSelectors } from 'modules/subscription/stores/selectors';
import { voiceStoreActions } from 'modules/voices/stores/actions';
import { BaseDestructible } from 'utils/destructible';

import { ListenableContent, PDFOverlayInfo, PlaybackInfo, SDKContentBundlerOptionsFacade, SDKFacade } from './lib';
import { SDKBundleCreationRequirements } from './lib/facade/bundle';
import { ClassicOverlayInfo } from './lib/facade/overlay/ClassicOverlayInfo';
import { EmbeddedOverlayInfo } from './lib/facade/overlay/EmbeddedOverlayInfo';

type BaseListeningRequirements = {
  listenableContent: ListenableContent;
};

type PDFListeningRequirements = BaseListeningRequirements;

type ClassicListeningRequirements = BaseListeningRequirements & {
  overlayElement: HTMLElement;
  scrollerElement: HTMLElement;
  virtualizer: Virtualizer | undefined;
};

export type ListeningRequirements = PDFListeningRequirements | ClassicListeningRequirements;

export type EmbeddedListeningRequirements = {
  element: HTMLElement;
  overlayElement: HTMLElement;
  scrollerElement: HTMLElement;
};

export class ListeningDependencies extends BaseDestructible {
  constructor(
    private readonly bundle: SDKReadingBundle,
    public readonly playbackInfo: PlaybackInfo,
    public readonly overlayInfo: PDFOverlayInfo | ClassicOverlayInfo,
    public readonly contentBundlerOptionsFacade: SDKContentBundlerOptionsFacade,
    public readonly listeningExperience: ListeningExperience
  ) {
    super();
  }

  destroy(): void {
    this.playbackInfo.destroy();
    this.overlayInfo.destroy();
    this.bundle.destroy();
  }
}

export class EmbeddedListeningDependencies extends BaseDestructible {
  constructor(
    private readonly bundle: SDKEmbeddedReadingBundle,
    public readonly playbackInfo: PlaybackInfo,
    public readonly overlayInfo: EmbeddedOverlayInfo
  ) {
    super();
  }

  destroy(): void {
    this.playbackInfo.destroy();
    this.overlayInfo.destroy();
    this.bundle.destroy();
  }
}

export class ListeningDependenciesFactory {
  private static _instance: ListeningDependenciesFactory;
  private readonly sdkFacade!: SDKFacade;

  private constructor(sdkFacade: SDKFacade) {
    if (ListeningDependenciesFactory._instance) {
      return ListeningDependenciesFactory._instance;
    }
    this.sdkFacade = sdkFacade;
    ListeningDependenciesFactory._instance = this;
  }

  public static initialize(sdkFacade: SDKFacade): ListeningDependenciesFactory {
    return new ListeningDependenciesFactory(sdkFacade);
  }

  public static get singleton(): ListeningDependenciesFactory {
    if (!ListeningDependenciesFactory._instance) {
      throw new Error('ListeningDependenciesFactory not initialized. Call initialize() first.');
    }
    return ListeningDependenciesFactory._instance;
  }

  public async getBundleCreationRequirements(): Promise<SDKBundleCreationRequirements> {
    const [initialVoiceInfo, initialWPM, allVoices, skipContentSettings] = await Promise.all([
      voiceStoreActions.fetchInitialVoice(),
      fetchInitialSpeedInWPM(),
      profilingStoreActions.measureAction(() => this.sdkFacade.voice.getAllVoices(), MeasurementKey.sdkGetAllVoices),
      profilingStoreActions.measureAction(() => listeningSettingsStoreActions.fetchInitialSkipSettings(), MeasurementKey.skipContentSettingsHydration)
    ]);
    const initialVoice = this.sdkFacade.voice.getVoiceSpec(initialVoiceInfo);
    const shouldEnableOCR = subscriptionStoreSelectors.getIsPremium();
    return { initialVoice, initialWPM, allVoices, shouldEnableOCR, skipContentSettings };
  }

  private async createPlaybackInfo(bundle: SDKReadingBundle | SDKEmbeddedReadingBundle, initialVoice: VoiceSpecOfAvailableVoice): Promise<PlaybackInfo> {
    return new PlaybackInfo(this.sdkFacade.sdk, bundle, initialVoice);
  }

  public async create({ listenableContent, ...requirements }: ListeningRequirements): Promise<ListeningDependencies> {
    const bundleRequirements = await this.getBundleCreationRequirements();

    profilingStoreActions.startMeasurement(MeasurementKey.sdkBundleCreation);
    const { readingBundle: bundle, contentBundlerOptions } = await this.sdkFacade.bundle.createBundles(listenableContent, {
      ...requirements,
      ...bundleRequirements
    });
    profilingStoreActions.endMeasurement(MeasurementKey.sdkBundleCreation);
    profilingStoreActions.endMeasurement(MeasurementKey.sdkSetup);

    const playbackInfo = await this.createPlaybackInfo(bundle, bundleRequirements.initialVoice);

    const createOverlayInfo = async () => {
      if (listenableContent.isPDF()) {
        const pspdfKitFacade = await PSPDFKitFacade.getMostRecentInstance();
        const overlayInfo = new PDFOverlayInfo(this.sdkFacade.sdk, bundle, playbackInfo, pspdfKitFacade);
        await overlayInfo.addRenderedContentToOverlayProvider();
        return overlayInfo;
      }
      const classicRequirements = requirements as ClassicListeningRequirements;
      const classicOverlayInfo = new ClassicOverlayInfo(
        this.sdkFacade.sdk,
        bundle,
        playbackInfo,
        classicRequirements.scrollerElement,
        classicRequirements.overlayElement,
        classicRequirements.virtualizer
      );
      await classicOverlayInfo.addRenderedContentToOverlayProvider();
      return classicOverlayInfo;
    };

    const overlayInfo = await createOverlayInfo();

    const { ListeningExperience, NavigationIntent } = this.sdkFacade.sdk.sdkModule;

    const listeningExperience = ListeningExperience.Companion.fromBundle(this.sdkFacade.sdk.client, bundle, new NavigationIntent.GoToCurrentPlaybackLocation());

    return new ListeningDependencies(bundle, playbackInfo, overlayInfo, contentBundlerOptions, listeningExperience);
  }

  public async createEmbeddedListeningDeps({
    overlayElement,
    scrollerElement,
    ...requirements
  }: EmbeddedListeningRequirements): Promise<EmbeddedListeningDependencies> {
    const bundleRequirements = await this.getBundleCreationRequirements();

    profilingStoreActions.startMeasurement(MeasurementKey.sdkBundleCreation);
    const bundle = await this.sdkFacade.bundle.createEmbeddedBundles({
      ...requirements,
      ...bundleRequirements,
      shouldEnableOCR: false
    });
    profilingStoreActions.endMeasurement(MeasurementKey.sdkBundleCreation);
    profilingStoreActions.endMeasurement(MeasurementKey.sdkSetup);

    const playbackInfo = await this.createPlaybackInfo(bundle, bundleRequirements.initialVoice);
    const overlayInfo = new EmbeddedOverlayInfo(this.sdkFacade.sdk, bundle, playbackInfo, overlayElement, scrollerElement);

    return new EmbeddedListeningDependencies(bundle, playbackInfo, overlayInfo);
  }
}
