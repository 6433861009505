import type { BoldElementMeta } from 'modules/sdk/lib/facade/legacyReader/classic/ClassicBlockInfo';

import { ClassicElementRenderer } from '../ClassicElementRenderer';
import type { InternalClassicElementProps } from './';

export function ClassicElementBold(props: InternalClassicElementProps<BoldElementMeta>) {
  return (
    <span className="whitespace-pre-wrap font-bold" data-element-id={props.element.elementId}>
      {props.element.elements.map(element => (
        <ClassicElementRenderer key={element.elementId} element={element} />
      ))}
    </span>
  );
}
