import { useEffect } from 'react';

import type { ListenableContent } from 'modules/sdk/lib';
import { ContentMetaTypeLowercase } from 'modules/sdk/lib/facade/listenableContent/content.types';
import { DEFAULT_DOCUMENT_TITLE } from 'utils/constants';

import { AnalyticsEventKey, logAnalyticsEvent } from '../logAnalyticsEvent';

export const useLogAnalyticsEventOnDocumentOpen = (listenableContent: ListenableContent) => {
  useEffect(() => {
    if (listenableContent && listenableContent.metaType) {
      const type = listenableContent.metaType.toLowerCase() as ContentMetaTypeLowercase;
      logAnalyticsEvent(AnalyticsEventKey.documentOpened, {
        type
      });

      if (listenableContent.title.toLowerCase() === DEFAULT_DOCUMENT_TITLE) {
        logAnalyticsEvent(AnalyticsEventKey.documentOpenedDefault, {});
      }

      const firstListenTime = localStorage.getItem('firstListenTime');

      if (!firstListenTime) {
        localStorage.setItem('firstListenTime', String(new Date()));
      } else {
        const time24HoursAfterFirstListen = new Date(firstListenTime).getTime() + 1 * 24 * 60 * 60 * 1000;

        if (new Date().getTime() > time24HoursAfterFirstListen) {
          logAnalyticsEvent(AnalyticsEventKey.documentOpenedRelisten, { type });
          localStorage.setItem('firstListenTime', 'logged');
        }
      }
    }
  }, [listenableContent]);
};

export const logAnalyticsEventOnDocumentCompleted = () => {
  logAnalyticsEvent(AnalyticsEventKey.documentCompleted, {});
};
