import React from 'react';
import { CgSpinner } from 'react-icons/cg';

interface Props {
  size?: number;
  isDarkMode?: boolean;
}

const Loading: React.FC<Props> = ({ size = 30, isDarkMode = false }) => {
  return <CgSpinner className={`animate-spin ${isDarkMode ? 'text-white' : ''}`} size={size} />;
};

export default Loading;
