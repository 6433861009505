import 'dayjs/locale/de';
import 'dayjs/locale/es';

import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import durationPlugin from 'dayjs/plugin/duration';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';
import { DayName } from 'interfaces';

dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);
dayjs.extend(updateLocale);
dayjs.extend(durationPlugin);
dayjs.extend(advancedFormat);
dayjs.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: 'a few secs',
    m: 'a min',
    mm: '%d mins',
    h: 'an hour',
    hh: '%d hours',
    d: 'a day',
    dd: '%d days',
    M: 'a month',
    MM: '%d months',
    y: 'a year',
    yy: '%d years'
  }
});

export const DAY_NAMES: DayName[] = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export function durationToHumanReadable(duration: number): string {
  if (duration < 60) return `0:${String(Math.floor(duration)).padStart(2, '0')}`;

  const hours = Math.floor(duration / 3600);
  const minutes = Math.floor((duration % 3600) / 60);
  const seconds = Math.floor((duration % 3600) % 60);

  return [hours, minutes, seconds]
    .filter((x, i) => x + i)
    .map((val, i) => (i === 0 ? String(val) : String(val).padStart(2, '0')))
    .join(':');
}
export function durationToHumanReadableWithDays(duration: number): string {
  const days = Math.floor(duration / (3600 * 24));
  const hours = Math.floor((duration - days * 3600 * 24) / 3600);

  return [days, hours]
    .filter((x, i) => x + i)
    .map((val, i) => (i === 0 ? String(val) + 'd' : String(val).padStart(2, '0') + 'h'))
    .join(':');
}

export function format(date: string, format: string): string {
  return dayjs(date).format(format);
}

export function toDate(date: string): Date {
  return dayjs(date).toDate();
}

export function formatDuration(durationInMinutes: number, showFullUnits = false) {
  const durationInMinutesCeil = Math.ceil(durationInMinutes);
  const hours = Math.floor(durationInMinutesCeil / 60);
  const minutes = durationInMinutesCeil - hours * 60;

  const hoursUnit = showFullUnits ? (hours > 1 ? 'hours' : 'hour') : 'hr';
  const minutesUnit = showFullUnits ? (minutes !== 1 ? 'minutes' : 'minute') : 'min';

  return (hours > 0 ? `${hours} ${hoursUnit} ` : '') + (hours === 0 || minutes > 0 ? `${minutes} ${minutesUnit}` : '');
}

export function formatDateInYYYYMMDD(date: Date): string {
  let month = String(date.getMonth() + 1);
  let day = String(date.getDate());
  const year = date.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
}

// @ts-expect-error TS(2366): Function lacks ending return statement and return ... Remove this comment to see the full error message
export function fromNow(dateString: Date, locale: string): string {
  const currentDate = dayjs();
  const targetDate = dayjs(dateString);

  // Set the locale
  dayjs.locale(locale);

  // Calculate the difference in hours
  const hoursDifference = currentDate.diff(targetDate, 'hour');

  if (hoursDifference < 48) {
    // Within 48 hours, use relative time
    if (hoursDifference < 1) {
      return targetDate.fromNow();
    } else if (hoursDifference < 24) {
      return targetDate.from(currentDate);
    } else if (hoursDifference < 48) {
      return 'yesterday';
    }
  } else {
    // Above 48 hours, use exact date format
    if (currentDate.year() !== targetDate.year()) {
      return targetDate.format('MMM D, YYYY');
    } else {
      return targetDate.format('MMM D');
    }
  }
}

export function getPreviousSunday(date = new Date()): Date {
  const previousMonday = new Date();
  previousMonday.setDate(date.getDate() - date.getDay());
  return previousMonday;
}

export function lessThanXDaysApart(date1: Date, date2: Date, x: number): boolean {
  const millisecondsInADay = 86400000;
  const differenceInMilliseconds = Math.abs(date1.getTime() - date2.getTime());
  const differenceInDays = differenceInMilliseconds / millisecondsInADay;
  return differenceInDays < x;
}

export function transformDayNumberToEnglish(date: Date | number): string {
  date = date instanceof Date ? date : new Date(`2021-01-${date}`);
  const dayJsObj = dayjs(date);
  return dayJsObj.format('Do');
}
