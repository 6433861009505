import { useCallback } from 'react';
import { useSelector } from 'store';
import { doItemsMatch } from 'utils';

import { IUser } from 'interfaces/user';
import { isOnTrial, isPremium } from 'lib/speechify';
import { AnalyticsEventKey, logAnalyticsEvent } from 'modules/analytics/logAnalyticsEvent';
import { useEnabledDownloadMp3Version } from 'modules/download-audio/hooks/useEnabledDownloadMp3Version';
import { setDownloadAudioStoreState, useDownloadAudioStore } from 'modules/download-audio/store';
import { UseListenableContentItemIdState } from 'modules/listening/hooks/useListenableContentItemId';
import { navStoreActions } from 'modules/listening/navStore';
import { playbackStoreActions } from 'modules/listening/stores/playback/playbackStore';
import { showUpsellModal } from 'modules/upsells/stores/actions/showUpsellModal';
import { UpsellModalType } from 'modules/upsells/stores/types';
import { selectors as librarySelectors } from 'store/library';

import { NavFeature } from '../Nav';

type DownloadMp3HandlerProps = {
  title: string;
  itemIdState: UseListenableContentItemIdState;
  enabledFeaturesSet: Set<NavFeature>;
};

export const useHandleDownloadMp3Click = ({ title, itemIdState, enabledFeaturesSet }: DownloadMp3HandlerProps) => {
  const item = useSelector(state => librarySelectors.getById(itemIdState.isLoading ? '' : itemIdState.itemId)(state), doItemsMatch);
  const currentUser = useSelector<IUser | null>(state => state.auth.user);

  const { enabledDownloadMp3Version } = useEnabledDownloadMp3Version(enabledFeaturesSet);

  const handleDownloadMp3Click = useCallback(() => {
    if (itemIdState.isLoading) return;

    if (enabledDownloadMp3Version === 2) {
      const isTrialUser = currentUser ? isOnTrial(currentUser) : false;
      const isPremiumUser = !isTrialUser && isPremium(currentUser);
      const isFreeUser = !isTrialUser && !isPremiumUser;

      const modalTypeEventProperty = isPremiumUser ? 'premium_can_use' : 'trial_needs_to_upgrade';

      playbackStoreActions.pause();
      useDownloadAudioStore.reset();
      if (isFreeUser) {
        showUpsellModal(UpsellModalType.ContextualDownloadMP3v2);
      } else {
        setDownloadAudioStoreState({ enabled: true, title, itemId: itemIdState.itemId });

        logAnalyticsEvent(AnalyticsEventKey.mp3DownloadModalOpened, {
          selected_from: 'toolbar',
          document_type: item.recordType,
          modal_type: modalTypeEventProperty
        });
      }
    } else if (enabledDownloadMp3Version === 1) {
      navStoreActions.setModalState('downloadMp3');
    }
  }, [enabledDownloadMp3Version, itemIdState, title, currentUser, item]);

  return { handleDownloadMp3Click };
};
