import assert from 'assert';

import { AnalyticsEventKey, logAnalyticsEvent } from 'modules/analytics/logAnalyticsEvent';
import { usePlaybackStore } from 'modules/listening/stores/playback/playbackStore';
import { PDFOverlayInfo } from 'modules/sdk/lib';
import { isMobile } from 'utils/browser';

import { useListeningSettingsStore } from '../settingsStore';

const MIN_ZOOM_PERCENTAGE = 20;
const MAX_ZOOM_PERCENTAGE = 1000;
const ZOOM_STEP = 10;

// This is the default width of PDF rendered by PSPDFKit
const PDF_WIDTH_PX = 670;
const getPageWidthPx = () => window.innerWidth;
const getMinZoomPercentageMobile = () => Math.floor((getPageWidthPx() / PDF_WIDTH_PX) * 100);

export const getMinZoomPercentage = () => {
  if (isMobile()) return getMinZoomPercentageMobile();
  return MIN_ZOOM_PERCENTAGE;
};
export const getMaxZoomPercentage = () => MAX_ZOOM_PERCENTAGE;

export const increaseZoom = () => {
  const currentZoom = useListeningSettingsStore.getState().zoomPercentage;
  onZoomChange(currentZoom + ZOOM_STEP, {
    maxZoom: getMaxZoomPercentage(),
    minZoom: getMinZoomPercentage()
  });
};

export const decreaseZoom = () => {
  const currentZoom = useListeningSettingsStore.getState().zoomPercentage;
  onZoomChange(currentZoom - ZOOM_STEP, {
    maxZoom: getMaxZoomPercentage(),
    minZoom: getMinZoomPercentage()
  });
};

export const onZoomChange = (zoomPercentage: number, { maxZoom, minZoom }: { maxZoom: number; minZoom: number }) => {
  zoomPercentage = Math.min(maxZoom, Math.max(minZoom, zoomPercentage));
  zoomPercentage = Math.ceil(Math.round(zoomPercentage));
  useListeningSettingsStore.setState(state => ({ ...state, zoomPercentage }));

  const overlayInfo = usePlaybackStore.getState().currentOverlayInfo;
  assert(overlayInfo, 'overlayInfo is not defined');

  if (overlayInfo instanceof PDFOverlayInfo) {
    const currentPspdfKitFacade = usePlaybackStore.getState().currentPspdfKitFacade;
    assert(currentPspdfKitFacade !== undefined, 'PspdfKitFacade was not initialized');
    currentPspdfKitFacade.setZoom(zoomPercentage);
  }

  overlayInfo.emitZoomEvent(zoomPercentage);

  logAnalyticsEvent(AnalyticsEventKey.listeningSettingsZoomChanged, {
    zoomPercentage
  });
};

export const syncExternalPSPDFKitZoomEvent = (zoomScale: number) => {
  const zoomPercentage = Math.ceil(Math.round(zoomScale * 100));
  useListeningSettingsStore.setState(state => ({ ...state, zoomPercentage }));
  const overlayInfo = usePlaybackStore.getState().currentOverlayInfo;
  overlayInfo?.emitZoomEvent(zoomPercentage);
};
