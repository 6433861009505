import React from 'react';

export const CheckmarkIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.0168 4.59675C18.4073 4.98728 18.4073 5.62045 18.0168 6.01096L8.62378 15.4034C8.43623 15.5909 8.18185 15.6963 7.91662 15.6963C7.65139 15.6962 7.39703 15.5909 7.20951 15.4033L1.98324 10.1759C1.59276 9.78531 1.59283 9.15215 1.9834 8.76166C2.37397 8.37118 3.00713 8.37125 3.39761 8.76182L7.91679 13.282L16.6026 4.5967C16.9932 4.20619 17.6263 4.20621 18.0168 4.59675Z"
    />
  </svg>
);
