import Loading from 'components/elements/Loading';
import Modal from 'components/elements/Modal';
import RowIcon from 'components/library/items/components/RowIcon';
import { useEffect, useState } from 'react';

import { CheckmarkIcon } from 'assets/icons/checkmark';
import CloseIcon from 'assets/icons/close';
import { SpeechifyTextLogo } from 'assets/icons/SpeechifyTextLogo';
import { useLastDefinedValue } from 'hooks/useLastDefinedValue';
import { IRecord } from 'interfaces/library';
import * as speechify from 'lib/speechify';
import { ClassicBlockRenderer } from 'modules/listening/features/reader/components/classic/ClassicBlockRenderer';
import { useClassicStore } from 'modules/listening/features/reader/stores/classicStore';
import { ReaderMode, useReaderStore } from 'modules/listening/features/reader/stores/readerStore';
import { logSegmentEvent } from 'utils/analytics';
import { formatTotalWords } from 'utils/formatting';

import FacebookIcon from './icons/FacebookIcon';
import GmailIcon from './icons/GmailIcon';
import OutlookIcon from './icons/OutlookIcon';
import WhatsappIcon from './icons/WhatsappIcon';
import XTwitterIcon from './icons/XTwitterIcon';

interface ShareModalProps {
  record: Pick<IRecord, 'coverImagePath' | 'title' | 'wordCount' | 'numPages' | 'recordType' | 'id'> | undefined;
  isOpen: boolean;
  isDarkMode: boolean;
  onClose: () => void;
}

export const ShareModal = ({ record, isOpen, onClose, isDarkMode }: ShareModalProps) => {
  const persistedRecord = useLastDefinedValue(record);
  const sharedLink = useSharedLink(persistedRecord?.id, isOpen);

  useEffect(() => {
    if (!sharedLink) return;
    navigator.clipboard.writeText(sharedLink);
  }, [sharedLink]);

  return (
    <Modal classNames="bg-bg-primary !max-w-md" open={isOpen} onClose={onClose}>
      <div className="flex flex-row items-center justify-between">
        <div className="w-[52px]" />
        <div className="flex-grow flex flex-col items-center justify-center py-3">
          <span className="text-text-primary text-heading-4 font-medium">Share Document</span>
        </div>
        <button
          onClick={onClose}
          className="w-[52px] self-stretch flex items-center justify-center cursor-pointer text-icon-primary hover:opacity-75 active:opacity-50 transition duration-150"
        >
          <CloseIcon />
        </button>
      </div>
      <div className="flex flex-row justify-center p-4">
        <div className="flex flex-col items-stretch flex-shrink w-[300px]">
          <ContentPreview record={persistedRecord} isDarkMode={isDarkMode} />
          <ShareButtons link={sharedLink} isDarkMode={isDarkMode} />
        </div>
      </div>
    </Modal>
  );
};

const useSharedLink = (itemId: string | undefined, modalOpen: boolean) => {
  const [sharedLink, setSharedLink] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (!modalOpen || !itemId) return;

    let canceled = false;
    setSharedLink(undefined);
    speechify.shareItem(itemId).then(link => {
      if (!canceled) {
        logSegmentEvent('web_app_library_share_document', { link });
        setSharedLink(link);
      }
    });

    return () => {
      canceled = true;
    };
  }, [modalOpen, itemId]);

  return sharedLink;
};

const ContentPreview = ({ record, isDarkMode }: Pick<ShareModalProps, 'record' | 'isDarkMode'>) => {
  const currentReaderMode = useReaderStore(s => s.currentReaderMode);
  const previewWidth = 230;

  if (!record) return null;

  let previewImage = (
    <div className="flex-grow flex items-center justify-center">
      <RowIcon kind={record.recordType} className="h-[100px] w-[100px]" />
    </div>
  );

  if (record.coverImagePath) {
    // eslint-disable-next-line @next/next/no-img-element -- we need to load img from user defined urls here and next/image is not really useful here anyway
    previewImage = <img src={record.coverImagePath} alt={record.title} width={previewWidth} height={242} className="object-cover shadow-spl-150" />;
  } else if (currentReaderMode === ReaderMode.CLASSIC) {
    // TODO(jan.k): Make classic preview available when shared modal is triggered from the library for text items
    previewImage = <ClassicPreview previewWidth={previewWidth} />;
  }

  return (
    <div className="flex flex-col items-stretch justify-between border-border-primary border-[1px] rounded-250 mb-2">
      <div className="h-[242px] overflow-hidden flex flex-col items-center">
        <SpeechifyTextLogo isDarkMode={isDarkMode} height={16} className="flex-shrink-0 m-3" />
        {previewImage}
      </div>
      <div className="flex flex-col items-stretch bg-bg-secondary rounded-b-250 py-3 px-4">
        <span className="line-clamp-1 text-heading-6 text-text-primary break-all">{record.title}</span>
        <div className="flex flex-row items-center gap-2 text-body-7 text-text-secondary">
          {record.wordCount && (
            <>
              <span>{formatTotalWords(record.wordCount)} words</span>
              <div className="w-1 h-1 bg-current rounded-full" />
            </>
          )}
          {record.numPages && (
            <>
              <span>{record.numPages} pages</span>
              <div className="w-1 h-1 bg-current rounded-full" />
            </>
          )}
          <span>{record.recordType}</span>
        </div>
      </div>
    </div>
  );
};

const ClassicPreview = ({ previewWidth }: { previewWidth: number }) => {
  const readerInfo = useClassicStore(s => s.classicReaderInfo);

  if (!readerInfo) return null;

  // We divide by 2 because we want the text to be bigger and readable
  const originalWidth = 768 / 2;
  return (
    <div
      className={`flex flex-col items-stretch classic-reader`}
      style={{
        zoom: previewWidth / originalWidth,
        width: originalWidth
      }}
    >
      {readerInfo.classicBlocks
        // We don't need to be efficient here, but this add some sane upper limit so the app is not super slow when sharing huge text files
        .filter((_, idx) => idx < 15)
        .map(block => (
          <ClassicBlockRenderer block={block} key={block.reactKey} />
        ))}
    </div>
  );
};

const createLink = (base: string, params: Record<string, string>) => {
  const url = new URL(base);
  Object.entries(params).forEach(([key, value]) => {
    url.searchParams.set(key, value);
  });
  return url.toString();
};

const ShareButtons = ({ link, isDarkMode }: { link: string | undefined; isDarkMode: boolean }) => {
  const isLoading = link === undefined;
  // Let's render bogus link while we are loading, it won't be clickable anyway
  const usedLink = link ?? '#';

  const mailtoLink = createLink('mailto:', { subject: 'Check out this document on Speechify', body: usedLink });

  return (
    <div className={`relative flex flex-col items-stretch pb-4 ${isLoading ? 'pointer-events-none' : ''}`}>
      {isLoading && (
        <div className="absolute inset-0 flex flex-col items-center justify-center bg-bg-primary">
          <Loading isDarkMode={isDarkMode} />
        </div>
      )}
      <div className="flex flex-row items-center justify-between py-4">
        <ShareIconContainer href={mailtoLink}>
          <GmailIcon />
        </ShareIconContainer>
        <ShareIconContainer href={mailtoLink}>
          <OutlookIcon />
        </ShareIconContainer>
        <ShareIconContainer href={createLink('https://wa.me/', { text: usedLink })}>
          <WhatsappIcon />
        </ShareIconContainer>
        <ShareIconContainer href={createLink('https://www.facebook.com/sharer/sharer.php', { u: usedLink })}>
          <FacebookIcon />
        </ShareIconContainer>
        <ShareIconContainer href={createLink('https://x.com/intent/tweet', { url: usedLink })}>
          <XTwitterIcon />
        </ShareIconContainer>
      </div>
      <div
        className="
          !rounded-250
          text-icon-primary-cta-inverted
          bg-transparent
          cursor-default
          pointer-events-none
          !outline-none
          !shadow-none
          flex flex-row items-center justify-center gap-1.5
          py-2
          border-transparent
          border-[1px]
        "
      >
        <CheckmarkIcon />
        <span className="text-subheading-6">Link Copied to Clipboard</span>
      </div>
    </div>
  );
};

const ShareIconContainer = ({ children, href }: { children: React.ReactNode; href: string }) => {
  return (
    <a
      href={href}
      target="_blank"
      className="
        p-2 flex items-center justify-center
        rounded-full border-border-primary border-[1px]
        hover:opacity-75 active:opacity-50
        transition duration-150
      "
      rel="noreferrer"
    >
      {children}
    </a>
  );
};
