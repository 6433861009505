import React, { useMemo } from 'react';
import { twMerge } from 'tailwind-merge';
import { v4 } from 'uuid';

export type CircularProgressProps = {
  size?: number;
  strokeWidth?: number;
};

const CircularProgressInfinite = ({ size = 40, strokeWidth = 2, className }: CircularProgressProps & JSX.IntrinsicElements['span']) => {
  const radius = (size - strokeWidth) / 2;
  const circumference = 2 * Math.PI * radius;
  const strokeDasharray = `${circumference * 0.8} ${circumference * 0.2}`;
  const strokeDashoffset = 0;
  const uniqId = useMemo(() => v4(), []);

  const keyframes = `
  @keyframes circularProgress_${uniqId} {
    0% {
      stroke-dasharray: ${circumference * 0.01} ${circumference};
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: ${circumference * 0.8} ${circumference};
      stroke-dashoffset: ${circumference * -0.12};
    }
    100% {
      stroke-dasharray: ${circumference * 0.8} ${circumference};
      stroke-dashoffset: ${circumference * -1};
    }
  }
  `;

  return (
    <span className={twMerge('inline-block animate-circularSpin', className)} style={{ width: `${size}px`, height: `${size}px` }} role="progressbar">
      <style>{keyframes}</style>
      <svg className="w-full h-full" viewBox={`0 0 ${size} ${size}`}>
        <circle
          className="stroke-current"
          style={{ animation: `2s ease-in-out 0s infinite normal none running circularProgress_${uniqId}` }}
          cx={size / 2}
          cy={size / 2}
          r={radius}
          fill="none"
          strokeWidth={strokeWidth}
          strokeDasharray={strokeDasharray}
          strokeDashoffset={strokeDashoffset}
          strokeLinecap="round"
        />
      </svg>
    </span>
  );
};

export default CircularProgressInfinite;
