import { UpgradeSource } from 'modules/subscription/utils/UpgradeSource';

import { AnalyticsEventKey } from '../analyticsTypes';
import { logAnalyticsEvent } from '../logAnalyticsEvent';

export const UPGRADE_SOURCE_TO_ANALYTICS_EVENT_KEY: Record<UpgradeSource, AnalyticsEventKey> = {
  [UpgradeSource.contextualUpsell]: AnalyticsEventKey.upgradePressedContextual,
  [UpgradeSource.contextualUpsellControl]: AnalyticsEventKey.upgradePressedContextualControl,
  [UpgradeSource.ocrUpsell]: AnalyticsEventKey.upgradePressedOcrUpsell,
  [UpgradeSource.skipContentUpsell]: AnalyticsEventKey.upgradePressedDashboard,
  [UpgradeSource.visualCta]: AnalyticsEventKey.upgradePressedAudioVisualCta,
  [UpgradeSource.voiceCloneCta]: AnalyticsEventKey.upgradePressedDashboard,
  [UpgradeSource.speedPickerUpsell]: AnalyticsEventKey.speedPickerUpsell,
  [UpgradeSource.helpCenterMessageBtn]: AnalyticsEventKey.helpCenterMessageUpgrade,
  [UpgradeSource.unlimitedSpeedPopup]: AnalyticsEventKey.unlimitedSpeedPopup,
  [UpgradeSource.profoile]: AnalyticsEventKey.profoile,
  [UpgradeSource.subscriptionSettings]: AnalyticsEventKey.subscriptionSettings,
  [UpgradeSource.accountPage]: AnalyticsEventKey.accountPage,
  [UpgradeSource.askAI]: AnalyticsEventKey.askAI,
  [UpgradeSource.downloadMp3v2]: AnalyticsEventKey.downloadMp3v2,
  [UpgradeSource.downloadMp3]: AnalyticsEventKey.downloadMp3,
  [UpgradeSource.imageOnlyPDFs]: AnalyticsEventKey.imageOnlyPDFs,
  [UpgradeSource.premiumVoices]: AnalyticsEventKey.premiumVoices,
  [UpgradeSource.unlimitedFiles]: AnalyticsEventKey.unlimitedFiles
};

export const UPGRADE_SOURCE_TO_ANALYTICS_PROPERTIES_FN: Partial<Record<UpgradeSource, () => Record<string, unknown>>> = {
  [UpgradeSource.skipContentUpsell]: () => ({ source: 'skip_header_footer_upsell' }),
  [UpgradeSource.voiceCloneCta]: () => ({ source: window.location.pathname, vcw: true })
};

export const logUpgradeButtonAnalyticsEvent = (source: UpgradeSource, variant?: string) => {
  const analyticsEvent = UPGRADE_SOURCE_TO_ANALYTICS_EVENT_KEY[source];

  if (analyticsEvent) {
    const analyticsProperties = UPGRADE_SOURCE_TO_ANALYTICS_PROPERTIES_FN[source]?.() || {};

    if (variant) {
      analyticsProperties.variant = variant;
    }

    logAnalyticsEvent(analyticsEvent, analyticsProperties);
  }
};
