// faro accepts only string values in event attributes

import { MetaAttributes } from '@grafana/faro-web-sdk';

export const prepareData = (data?: MetaAttributes) => {
  if (data !== null && typeof data === 'object') {
    return Object.keys(data).reduce((acc, key) => {
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      acc[key] = prepareData(data[key]);
      return acc;
    }, {});
  }

  if (typeof data === 'boolean' || typeof data === 'number') {
    return String(data);
  }

  return data;
};
