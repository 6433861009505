import { createLocalStorageStore } from 'lib/zustand';

type ExportingLibraryItem = {
  itemId: string;
  exportTaskId: string;
  title: string;

  progress: number;
  status: 'exporting' | 'downloading' | 'success' | 'failed';

  // Fields needed for analytic events
  pageCount: number;
  estimatedDuration: number;
  remainingQuota: number;
};

type ExportingLibraryItemsState = {
  items: Record<string, ExportingLibraryItem>;
};

export const useExportingLibraryItemsStore = createLocalStorageStore<ExportingLibraryItemsState>(() => ({ items: {} }), {
  storageName: 'downloadingAudioFiles'
});

export const addExportingLibraryItem = (item: ExportingLibraryItem) => {
  useExportingLibraryItemsStore.setState(state => ({
    ...state,
    items: { ...state.items, [item.itemId]: item }
  }));
};

export const removeExportingLibraryItem = (itemId: string) => {
  useExportingLibraryItemsStore.setState(({ items }) => {
    const { [itemId]: _, ...restItems } = items;
    return { items: restItems };
  });
};

export const updateExportingLibraryItem = (itemId: string, data: Partial<Pick<ExportingLibraryItem, 'status' | 'progress'>>) => {
  useExportingLibraryItemsStore.setState(state => {
    const item = state.items[itemId];

    if (!item) return state;

    return {
      ...state,
      items: { ...state.items, [itemId]: { ...item, ...data } }
    };
  });
};
