import ConfirmModal from 'components/dashboard/ConfirmModal';
import { IRecord } from 'interfaces';
import useTranslation from 'next-translate/useTranslation';
import React, { useState } from 'react';
import { useDispatch } from 'store';

import { ErrorSource, LibraryErrorOperation } from 'config/constants/errors';
import { logError } from 'lib/observability';
import { deleteAllArchivedItems } from 'lib/speechify';
import { actions as toastActions } from 'store/toast';

interface TrashSubHeaderProps {
  archivedItemsCount: number;
  setArchivedItems: (items: IRecord[]) => void;
}

export const TrashSubHeader: React.FC<TrashSubHeaderProps> = ({ archivedItemsCount, setArchivedItems }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('common');
  const [showConfirmModal, setShowConfirmModal] = useState('');

  const handleConfirm = async () => {
    setShowConfirmModal('');
    try {
      // Empty the state
      setArchivedItems([]);

      await deleteAllArchivedItems();

      dispatch(
        toastActions.add({
          title: '',
          description: t('Trash emptied')!,
          type: 'success'
        })
      );
    } catch (e) {
      // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
      logError(e, ErrorSource.LIBRARY, {
        context: {
          operation: LibraryErrorOperation.DELETE_ALL_ARCHIVED_ITEMS
        }
      });

      dispatch(
        toastActions.add({
          title: '',
          description: t('Trash emptied error')!,
          type: 'error'
        })
      );
    }
  };

  return (
    <>
      <div className="m-[24px] flex justify-between rounded bg-glass-200 px-[18px] py-[16px]">
        <div className="flex-none font-ABCDiatype text-base font-bold text-glass-600">{t('Trash title')}</div>
        {archivedItemsCount > 0 && (
          <div onClick={() => setShowConfirmModal('true')} className="flex-none cursor-pointer font-ABCDiatype text-base font-bold text-glass-600">
            {t('Empty Trash')}
          </div>
        )}
      </div>
      <ConfirmModal
        description={t('Empty Trash confirmation')}
        id={showConfirmModal}
        onCancel={() => setShowConfirmModal('')}
        onConfirm={handleConfirm}
        title={t('Empty Trash')}
      />
    </>
  );
};

export default TrashSubHeader;
