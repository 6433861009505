import { useEffect } from 'react';

import { logError } from 'lib/observability';
import { ClassicBlockInfo, ClassicBlockInfoType } from 'modules/sdk/lib/facade/legacyReader/classic/ClassicBlockInfo';

import { ClassicBlockHeading } from './blocks/ClassicBlockHeading';
import { ClassicBlockList } from './blocks/ClassicBlockList';
import { ClassicBlockParagraph } from './blocks/ClassicBlockParagraph';

type ClassicBlockProps = {
  block: ClassicBlockInfo;
};

function Unreachable(value: never) {
  useEffect(() => {
    logError(new Error(`Unexpected ClassicBlockInfoType value: ${value}`));
  }, [value]);

  return <></>;
}

export const ClassicBlockRenderer = (props: ClassicBlockProps) => {
  const block = props.block;
  const meta = block.meta;

  switch (meta.type) {
    case ClassicBlockInfoType.Heading:
      return <ClassicBlockHeading meta={meta} block={block} />;
    case ClassicBlockInfoType.List:
      return <ClassicBlockList meta={meta} block={block} />;
    case ClassicBlockInfoType.Paragraph:
      return <ClassicBlockParagraph meta={meta} block={block} />;
    default:
      return Unreachable(meta);
  }
};
