import React from 'react';
import { twMerge } from 'tailwind-merge';

import { CheckmarkIcon } from '../../assets/icons/checkmarkOld';

const VIEWBOX_WIDTH = 100;
const VIEWBOX_HEIGHT = 100;
const VIEWBOX_HEIGHT_HALF = 50;
const VIEWBOX_CENTER_X = 50;
const VIEWBOX_CENTER_Y = 50;

function Path({
  className,
  counterClockwise,
  dashRatio,
  pathRadius,
  strokeWidth,
  xOffset = 0,
  yOffset = 0,
  style
}: {
  className?: string;
  counterClockwise: boolean;
  dashRatio: number;
  pathRadius: number;
  strokeWidth: number;
  yOffset?: number;
  xOffset?: number;
  style?: React.CSSProperties;
}) {
  return (
    <path
      className={className}
      d={getPathDescription({
        pathRadius,
        counterClockwise,
        positionX: VIEWBOX_CENTER_X + xOffset,
        positionY: VIEWBOX_CENTER_Y + yOffset
      })}
      fillOpacity={0}
      strokeWidth={strokeWidth}
      style={Object.assign({ ...style }, getDashStyle({ pathRadius, dashRatio, counterClockwise }))}
    />
  );
}

// SVG path description specifies how the path should be drawn
function getPathDescription({
  pathRadius,
  counterClockwise,
  positionX = VIEWBOX_CENTER_X,
  positionY = VIEWBOX_CENTER_Y
}: {
  pathRadius: number;
  counterClockwise: boolean;
  positionX?: number;
  positionY?: number;
}) {
  const radius = pathRadius;
  const rotation = counterClockwise ? 1 : 0;

  // Move to center of canvas
  // Relative move to top canvas
  // Relative arc to bottom of canvas
  // Relative arc to top of canvas
  return `
      M ${positionX},${positionY}
      m 0,-${radius}
      a ${radius},${radius} ${rotation} 1 1 0,${2 * radius}
      a ${radius},${radius} ${rotation} 1 1 0,-${2 * radius}
    `;
}

function getDashStyle({ counterClockwise, dashRatio, pathRadius }: { counterClockwise: boolean; dashRatio: number; pathRadius: number }) {
  const diameter = Math.PI * 2 * pathRadius;
  const gapLength = (1 - dashRatio) * diameter;

  return {
    // Have dash be full diameter, and gap be full diameter
    strokeDasharray: `${diameter}px ${diameter}px`,
    // Shift dash backward by gapLength, so gap starts appearing at correct distance
    strokeDashoffset: `${counterClockwise ? -gapLength : gapLength}px`
  };
}

export type CircularProgressbarProps = {
  circleRatio?: number;
  className?: string;
  counterClockwise?: boolean;
  maxValue?: number;
  minValue?: number;
  strokeWidth?: number;
  maxValueCheckDisabled?: boolean;
  value: number;
  strokeClassname?: string;
  pathClassname?: string;
};

export const CircularProgressBar = (props: CircularProgressbarProps & JSX.IntrinsicElements['div']) => {
  const {
    circleRatio = 1,
    counterClockwise = false,
    maxValue = 100,
    minValue = 0,
    strokeWidth = 18,
    value,
    maxValueCheckDisabled = false,
    className,
    strokeClassname,
    pathClassname,
    ...rest
  } = props;
  const isMaxValue = value === maxValue;

  const getPathRadius = () => {
    // The radius of the path is defined to be in the middle, so in order for the path to
    // fit perfectly inside the 100x100 viewBox, need to subtract half the strokeWidth
    return VIEWBOX_HEIGHT_HALF - strokeWidth / 2;
  };

  // Ratio of path length to trail length, as a value between 0 and 1
  const getPathRatio = () => {
    const boundedValue = Math.min(Math.max(value, minValue), maxValue);
    return (boundedValue - minValue) / (maxValue - minValue);
  };

  const pathRadius = getPathRadius();
  const pathRatio = getPathRatio();

  return (
    <div className={twMerge('relative flex h-[36px] w-[36px] flex-col items-center justify-center', className)} {...rest}>
      {isMaxValue && !maxValueCheckDisabled && <CheckmarkIcon className="z-10 fill-white" />}
      <svg
        viewBox={`0 0 ${VIEWBOX_WIDTH} ${VIEWBOX_HEIGHT}`}
        data-test-id="CircularProgressbar"
        className={twMerge(
          'absolute left-0 top-0 flex w-full items-center align-middle' //
        )}
      >
        {isMaxValue && !maxValueCheckDisabled && (
          <circle
            cx={VIEWBOX_CENTER_X} //
            cy={VIEWBOX_CENTER_Y}
            r={VIEWBOX_HEIGHT_HALF}
            className="fill-electric-350"
          />
        )}

        <Path
          counterClockwise={counterClockwise} //
          dashRatio={circleRatio}
          pathRadius={pathRadius}
          strokeWidth={strokeWidth}
          className={twMerge(pathClassname ? pathClassname : isMaxValue && !maxValueCheckDisabled ? 'stroke-electric-350' : 'stroke-glass-300')}
        />

        <Path
          counterClockwise={counterClockwise} //
          dashRatio={pathRatio * circleRatio}
          pathRadius={pathRadius}
          strokeWidth={strokeWidth}
          className={strokeClassname ?? 'stroke-electric-350'}
          style={{ transition: 'stroke-dashoffset 0.5s ease 0s' }}
        />
      </svg>
    </div>
  );
};

export default CircularProgressBar;
