export const triggerDownloadFile = (title: string, data: Blob) => {
  const href = URL.createObjectURL(data);

  const temporaryLink = document.createElement('a');
  temporaryLink.href = href;
  temporaryLink.style.display = 'none';
  temporaryLink.download = `${title}.mp3`;
  temporaryLink.click();
  URL.revokeObjectURL(href);
};
