import { ChatResponse, CreateChatRequest, SummaryFormatTuple, SummaryLengthTuple } from './types';

export const convertFromApiLength = (length: string): SummaryLengthTuple => {
  switch (length.toLowerCase()) {
    case 'long':
      return 'long';

    case 'medium':
      return 'medium';

    case 'short':
      return 'short';

    default:
      throw new Error(`Unknown length: ${length}`);
  }
};

export const convertFromApiSummaryFormat = (format: string): SummaryFormatTuple => {
  switch (format.toLowerCase()) {
    case 'outline':
      return 'keypoints';

    case 'paragraph':
      return 'paragraph';

    default:
      throw new Error(`Unknown format: ${format}`);
  }
};

export const validateChatRequest = (request: CreateChatRequest): void => {
  if (!request) {
    throw new Error('Invalid chat request: null or undefined');
  }

  if (!request.title) {
    throw new Error('Chat title is required');
  }

  if (request.source) {
    if (request.source.type !== 'libraryItem') {
      throw new Error('Invalid source type. Must be "libraryItem"');
    }

    if (!request.source.id) {
      throw new Error('Source ID is required');
    }
  }
};

export const validateChatResponse = (response: ChatResponse): void => {
  if (!response) {
    throw new Error('Invalid chat response: null or undefined');
  }

  if (!response.chatId) {
    throw new Error('Invalid chat response: missing chatId');
  }
};
