import { capitalize, find, groupBy, map, uniq, uniqBy } from 'lodash';

import { VoiceInfo } from 'modules/sdk/lib';
import { Nullable } from 'utils/types';

import { VoiceLabelType } from '../types';

export type VoicesByLanguage = Record<string, VoiceInfo[]>;

export const groupVoicesByLanguage = (voices: VoiceInfo[]): VoicesByLanguage => {
  const uniqVoices = uniqBy(voices, v => v.id);
  const groups = groupBy(uniqVoices, v => v.language);
  return groups;
};

export const getUniqueLanguageCodes = (voicesByLanguage: VoicesByLanguage): string[] => {
  const allVoices = Object.values(voicesByLanguage).flat();
  return uniq(allVoices.map(voice => voice.languageCodeWithLocale));
};

export type LanguageMap = { id: string; name: string; regions: string[] };

function getLocalizedLanguageName(code: string, locale: string): string {
  try {
    const languageName = new Intl.DisplayNames([locale], { type: 'language' }).of(code);
    return languageName ? capitalize(languageName) : code;
  } catch {
    return code;
  }
}

export const transformLanguageCodes = (codes: string[]): LanguageMap[] => {
  const grouped = groupBy(codes, code => code.split('-')[0]);

  return map(grouped, (values, key) => {
    const regions = values.map(code => code.split('-')[1]).filter(Boolean);

    return {
      id: key,
      name: getLocalizedLanguageName(key, key), // Get the name in the local language
      regions
    };
  });
};

export function getTypeFromLabel(labels: Nullable<string[]>, isPremiumUser?: boolean): VoiceLabelType | undefined {
  const label = (find(labels, l => l != null) || 'premium') as VoiceLabelType;
  if (isPremiumUser && label === 'premium') {
    // hide premium label for Premium users
    return;
  }
  return label;
}

export function convertTypetoText(type: VoiceLabelType) {
  switch (type) {
    case 'ai-enhanced':
      return 'AI Enhanced';

    case 'celebrity':
      return 'Celebrity';

    case 'custom':
      return 'Custom';

    case 'premium':
      return 'Premium';

    default:
      return 'Premium';
  }
}
