import { MetaAttributes } from '@grafana/faro-web-sdk';
import {
  AbstractLocalMediaPlayer,
  AbstractLocalMediaPlayerAdapter,
  LocalMediaPlayer,
  LocalMediaPlayerEvent,
  LocalMediaPlayerOptions
} from '@speechifyinc/multiplatform-sdk/api/adapters/mediaplayer';
import { Result, SDKError } from '@speechifyinc/multiplatform-sdk/api/util';
import { Howl } from 'howler';

import { ErrorSource } from 'config/constants/errors';
import { measure } from 'lib/observability';

import { logError, logEventInObservability as logEvent } from '../../observability';
import { Callback } from './lib/typeAliases';

export class WebLocalMediaPlayerAdapter extends AbstractLocalMediaPlayerAdapter {
  createMediaPlayer(mediaUrl: string, initialOptions: LocalMediaPlayerOptions, callback: Callback<LocalMediaPlayer>): void {
    callback(new Result.Success(new WebLocalPlayer(mediaUrl, initialOptions)));
  }
}

let counter = 0;

export class WebLocalPlayer extends AbstractLocalMediaPlayer {
  private readonly sound: Howl;
  private ident = counter++;
  private _rate = 1;
  private playCalled: number = 0;
  private mediaSize: number = 0;

  get rate(): number {
    return this._rate;
  }

  set rate(speed: number) {
    this.sound.rate(speed);
    this._rate = speed;
  }

  private log(eventName: string, params?: MetaAttributes) {
    logEvent(`weblocalplayer.${eventName}`, params);
  }

  constructor(mediaUrl: string, { volume, speed }: LocalMediaPlayerOptions) {
    super();
    try {
      this.sound = new Howl({
        src: [mediaUrl],
        html5: true,
        format: ['ogg']
      });
      this.sound.volume(volume);
      this.rate = speed;
      this.mediaSize = mediaUrl.length;
    } catch (e) {
      logError(e as Error, ErrorSource.PLAYBACK);
      throw new Error(`Error initializing WebLocalPlayer ${this.ident}`);
    }
  }

  getOptions(callback: (p1: LocalMediaPlayerOptions) => void): void {
    callback(new LocalMediaPlayerOptions(this.rate, this.sound.volume()));
  }

  setSpeed(speed: number): void {
    this.rate = speed;
  }

  setVolume(volume: number): void {
    this.sound.volume(volume);
  }

  setEventListener(callback: Callback<LocalMediaPlayerEvent>): void {
    // ESLint: '_' is defined but never used
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    this.sound.on('play', (_: number) => {
      callback(new Result.Success(LocalMediaPlayerEvent.Started));

      const timeSinceLastPlay = performance.now() - this.playCalled;
      if (timeSinceLastPlay > 4 * 1000) {
        measure('weblocalplayer_long_playback_delay', timeSinceLastPlay, {
          context: {
            speed: this.rate.toString(),
            counter: this.ident.toString(),
            duration: this.sound.duration().toString(),
            webAudio: Howler.usingWebAudio.toString(),
            html5PoolSize: Howler.html5PoolSize.toString(),
            mediaSize: this.mediaSize.toString()
          }
        });
      }
    });
    // ESLint: '_' is defined but never used
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    this.sound.on('pause', (_: number) => {
      callback(new Result.Success(LocalMediaPlayerEvent.Stopped));
    });
    // ESLint: '_' is defined but never used
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    this.sound.on('end', (_: number) => {
      callback(new Result.Success(LocalMediaPlayerEvent.Ended));
    });
    this.sound.on('playerror', (_: number, error: unknown) => {
      callback(new Result.Failure(new SDKError.OtherMessage(`error playing ${error}`)));
    });
    this.sound.on('loaderror', (_: number, error: unknown) => {
      callback(new Result.Failure(new SDKError.OtherMessage(`error loading ${error}`)));
    });
  }

  destroy(): void {
    this.sound.off('play');
    this.sound.off('pause');
    this.sound.off('end');
    this.sound.off('playerror');
    this.sound.off('loaderror');
    this.sound.unload();
  }

  getCurrentTimeInMilliseconds(callback: (milis: number) => void) {
    return callback(this.sound.seek() * 1000);
  }

  pause(): void {
    this.sound.pause();
  }

  play(): void {
    this.playCalled = performance.now();
    this.sound.play();
  }

  seek(timeInMilliseconds: number): void {
    this.log('seek', { timeInMilliseconds: timeInMilliseconds.toString() });
    this.sound.seek(timeInMilliseconds / 1000.0);
  }

  updateOptions({ volume, speed }: LocalMediaPlayerOptions): void {
    this.log('updateOptions', { volume: volume.toString(), speed: speed.toString() });
    this.rate = speed;
    this.sound.volume(volume);
  }
}
