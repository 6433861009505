import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { createNonPersistentStore } from 'lib/zustand';
import { isMobile } from 'utils/browser';

export const useMobileAppPromptsStore = createNonPersistentStore<{
  isFullScreenPromptShown: boolean | undefined;
}>(
  () => ({
    isFullScreenPromptShown: undefined
  }),
  {
    isListeningScreenStore: false
  }
);

const dismissFullScreenPrompt = () => useMobileAppPromptsStore.setState({ isFullScreenPromptShown: false });

export const useIsFullScreenPromptShown = () => {
  const isFullScreenPromptShown = useMobileAppPromptsStore(state => state.isFullScreenPromptShown);

  useEffect(() => {
    if (isFullScreenPromptShown === undefined) {
      useMobileAppPromptsStore.setState({ isFullScreenPromptShown: isMobile() });
    }
  }, [isFullScreenPromptShown]);

  const pathname = useRouter().pathname;
  // We don't want to show the full screen prompt outside of these views, but we want to show it as soon as we get to them
  if (!pathname.startsWith('/item/') && pathname !== '/') {
    return [false, dismissFullScreenPrompt] as const;
  }

  return [isFullScreenPromptShown ?? false, dismissFullScreenPrompt] as const;
};
