import { useEffect } from 'react';

import { ClassicElementInfo, ClassicElementInfoType, InternalClassicElementInfo } from 'modules/sdk/lib/facade/legacyReader/classic/ClassicBlockInfo';
import { logError } from 'utils/errorLogging';

import { ClassicElementAnchor } from './internal/ClassicElementAnchor';
import { ClassicElementBold } from './internal/ClassicElementBold';
import { ClassicElementCode } from './internal/ClassicElementCode';
import { ClassicElementHeading } from './internal/ClassicElementHeading';
import { ClassicElementItalics } from './internal/ClassicElementItalics';
import { ClassicElementList } from './internal/ClassicElementList';
import { ClassicElementListItem } from './internal/ClassicElementListItem';
import { ClassicElementParagraph } from './internal/ClassicElementParagraph';
import { ClassicElementTable, ClassicElementTableCell, ClassicElementTableRow } from './internal/ClassicElementTable';
import { ClassicElementUnderlined } from './internal/ClassicElementUnderlined';
import { ClassicElementImage } from './leaf/ClassicElementImage';
import { ClassicElementText } from './leaf/ClassicElementText';

function Unreachable(value: never) {
  useEffect(() => {
    logError(new Error(`Unexpected ClassicElementInfoType value: ${value}`));
  }, [value]);

  return <></>;
}

export function ClassicElementRenderer(props: { element: ClassicElementInfo }) {
  const element = props.element;

  if (element.isLeafElement()) {
    const meta = element.meta;
    switch (meta.type) {
      case ClassicElementInfoType.Text:
        return <ClassicElementText meta={meta} element={element} />;
      case ClassicElementInfoType.ImageRemote:
        return <ClassicElementImage meta={meta} element={element} />;
      default:
        return Unreachable(meta);
    }
  }
  const internalElement = element as InternalClassicElementInfo;
  const meta = internalElement.meta;

  switch (meta.type) {
    case ClassicElementInfoType.Heading:
      return <ClassicElementHeading meta={meta} element={internalElement} />;
    case ClassicElementInfoType.Paragraph:
      return <ClassicElementParagraph meta={meta} element={internalElement} />;
    case ClassicElementInfoType.Anchor:
      return <ClassicElementAnchor meta={meta} element={internalElement} />;
    case ClassicElementInfoType.Bold:
      return <ClassicElementBold meta={meta} element={internalElement} />;
    case ClassicElementInfoType.Italics:
      return <ClassicElementItalics meta={meta} element={internalElement} />;
    case ClassicElementInfoType.Underlined:
      return <ClassicElementUnderlined meta={meta} element={internalElement} />;
    case ClassicElementInfoType.Table:
      return <ClassicElementTable meta={meta} element={internalElement} />;
    case ClassicElementInfoType.TableRow:
      return <ClassicElementTableRow meta={meta} element={internalElement} />;
    case ClassicElementInfoType.TableCell:
      return <ClassicElementTableCell meta={meta} element={internalElement} />;
    case ClassicElementInfoType.Code:
      return <ClassicElementCode meta={meta} element={internalElement} />;
    case ClassicElementInfoType.List:
      return <ClassicElementList meta={meta} element={internalElement} />;
    case ClassicElementInfoType.ListItem:
      return <ClassicElementListItem meta={meta} element={internalElement} />;
    default:
      return Unreachable(meta);
  }
}
