import type { CodeElementMeta } from 'modules/sdk/lib/facade/legacyReader/classic/ClassicBlockInfo';

import { ClassicElementRenderer } from '../ClassicElementRenderer';
import type { InternalClassicElementProps } from './';

export function ClassicElementCode(props: InternalClassicElementProps<CodeElementMeta>) {
  return (
    <code data-element-id={props.element.elementId}>
      <tbody>
        {props.element.elements.map(element => (
          <ClassicElementRenderer key={element.elementId} element={element} />
        ))}
      </tbody>
    </code>
  );
}
