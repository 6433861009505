import { createNonPersistentStore } from 'lib/zustand';
import { VoiceItemUIProp } from 'modules/voices/types';

export enum DownloadAudioViewEnum {
  SelectVoice,
  DownloadStepFreeUser,
  DownloadStepTrial,
  DownloadStepSuccess,
  DownloadStepMonthlyLimit,
  DownloadStepYearlyLimit
}

type DownloadAudioState = {
  // Whether modal is enabled or not
  enabled: boolean;
  currentView: DownloadAudioViewEnum;

  voice?: VoiceItemUIProp;
  initialVoice?: VoiceItemUIProp;
  showVoicesNotification: boolean;

  selectedPages: Set<number>;
  selectPagesModalActive: boolean;
  entitlementsQuotaInSeconds: number;
  subscriptionSecondsLimit: number;
  annualSubscriptionSecondsLimit: number;

  duration: number;
  approximateDuration: number | null;

  title?: string;
  itemId?: string;
  isDurationLoading: boolean;
  isFullDocument: boolean;

  isCheckoutModalOpen: boolean;
};

export const useDownloadAudioStore = createNonPersistentStore<DownloadAudioState>(
  () => {
    return {
      enabled: false,
      currentView: DownloadAudioViewEnum.SelectVoice,
      selectedPages: new Set(),
      selectPagesModalActive: false,
      entitlementsQuotaInSeconds: 0,
      subscriptionSecondsLimit: 0,
      annualSubscriptionSecondsLimit: 0,
      duration: 0,
      approximateDuration: null,
      isDurationLoading: false,
      isFullDocument: true,
      showVoicesNotification: false,
      isCheckoutModalOpen: false
    };
  },
  {
    isListeningScreenStore: true
  }
);

export const toggleSelectPagesModal = (active?: boolean) => useDownloadAudioStore.setState({ selectPagesModalActive: active });

export const setDownloadAudioStoreState = useDownloadAudioStore.setState;

export const setDownloadAudioView = (view: DownloadAudioViewEnum) => setDownloadAudioStoreState({ currentView: view });

export const isDownloadStepView = (view: DownloadAudioViewEnum) =>
  [
    DownloadAudioViewEnum.DownloadStepFreeUser,
    DownloadAudioViewEnum.DownloadStepTrial,
    DownloadAudioViewEnum.DownloadStepSuccess,
    DownloadAudioViewEnum.DownloadStepMonthlyLimit,
    DownloadAudioViewEnum.DownloadStepYearlyLimit
  ].includes(view);

export const durationSelector = (state: DownloadAudioState) => (state.isDurationLoading ? (state.approximateDuration ?? 0) : state.duration);

export const openCheckoutModal = () => setDownloadAudioStoreState({ isCheckoutModalOpen: true });
export const closeCheckoutModal = () => setDownloadAudioStoreState({ isCheckoutModalOpen: false });
