import type { PlayPauseButton, PlaybackTime as SDKPlaybackTime } from '@speechifyinc/multiplatform-sdk';

import { logError } from 'lib/observability';

import { MultiplatformSDKInstance } from '../../sdk';
import { SDKFacade } from '../_base';
import { PlaybackTime } from '../playback';
import { PlayButtonState } from '../playback/constants';

export class SDKSingletonCommonReaderFacade extends SDKFacade {
  private static _singleton: SDKSingletonCommonReaderFacade;

  constructor(sdk: MultiplatformSDKInstance) {
    super(sdk);
    SDKSingletonCommonReaderFacade._singleton = this;
  }

  static override get singleton(): SDKSingletonCommonReaderFacade {
    return SDKSingletonCommonReaderFacade._singleton;
  }

  get PlaybackTime() {
    return this.sdk.sdkModule.PlaybackTime;
  }

  get PlayPauseButton() {
    return this.sdk.sdkModule.PlayPauseButton;
  }

  sdkPlaybackTimeToOurPlaybackTime(sdkPlaybackTime: SDKPlaybackTime): PlaybackTime {
    if (sdkPlaybackTime instanceof this.PlaybackTime.Ready) {
      return {
        isLoading: false,
        currentTimeSeconds: sdkPlaybackTime.currentTimeSeconds,
        totalTimeSeconds: sdkPlaybackTime.totalTimeSeconds
      };
    }

    return {
      isLoading: true
    };
  }

  sdkPlayButtonStateToOurPlaybackTime(sdkPlaybackTime: SDKPlaybackTime): PlaybackTime {
    if (sdkPlaybackTime instanceof this.PlaybackTime.Ready) {
      return {
        isLoading: false,
        currentTimeSeconds: sdkPlaybackTime.currentTimeSeconds,
        totalTimeSeconds: sdkPlaybackTime.totalTimeSeconds
      };
    }

    return {
      isLoading: true
    };
  }

  playPauseButtonToPlayButtonState(playPauseButton: PlayPauseButton): PlayButtonState {
    if (playPauseButton === this.PlayPauseButton.ShowPlay) {
      return PlayButtonState.SHOW_PLAY;
    }
    if (playPauseButton === this.PlayPauseButton.ShowPause) {
      return PlayButtonState.SHOW_PAUSE;
    }
    if (playPauseButton === this.PlayPauseButton.ShowRestart) {
      return PlayButtonState.SHOW_RESTART;
    }
    if (playPauseButton === this.PlayPauseButton.ShowBuffering) {
      return PlayButtonState.SHOW_BUFFERING;
    }

    const error = new Error(`Unknown playPauseButton state: ${playPauseButton}`);
    logError(error);
    throw error;
  }
}
