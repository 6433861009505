export * from './bundle';
export * from './library';
export * from './subscription';
export * from './voice';

export * from './playback';
export * from './overlay';
export * from './listenableContent';

import type { MultiplatformSDKInstance } from '../sdk';
import { SDKAskAiFacade } from './askAi';
import { SDKBundleFacade } from './bundle';
import { SDKLibraryFacade } from './library';
import { SDKContentFacade } from './listenableContent/facade';
import { SDKSingletonClassicReaderFacade } from './reader/SDKSingetonClassicReaderFacade';
import { SDKSingletonCommonReaderFacade } from './reader/SDKSingetonCommonReaderFacade';
import { SDKSubscriptionFacade } from './subscription';
import { SDKVoiceFacade } from './voice';

export { SDKContentFacade };

export type SDKFacade = {
  sdk: MultiplatformSDKInstance;
  content: SDKContentFacade;
  library: SDKLibraryFacade;
  bundle: SDKBundleFacade;
  subscription: SDKSubscriptionFacade;
  voice: SDKVoiceFacade;
  askAi: SDKAskAiFacade;
  singletonClassicReader: SDKSingletonClassicReaderFacade;
  singletonCommonReader: SDKSingletonCommonReaderFacade;
};
