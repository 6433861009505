import { ContentMetaType } from '../../content.types';
import { mimeTypeToMetaType } from '../../utils';
import { BaseURLListenableContent } from '../BaseURLListenableContent';

export class ClassicURLListenableContent extends BaseURLListenableContent {
  get metaType(): ContentMetaType {
    const type = mimeTypeToMetaType(this._mimeType);
    if (type === ContentMetaType.UNKNOWN) {
      // fallback to HTML for ClassicURLListenableContent
      return ContentMetaType.HTML;
    }
    return type;
  }
}
