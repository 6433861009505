import { XIcon } from '@heroicons/react/outline';
import { yupResolver } from '@hookform/resolvers/yup';
import { IRecord } from 'interfaces';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { RootState, useDispatch, useSelector } from 'store';
import { twMerge } from 'tailwind-merge';
import { ensureFullUrl } from 'utils';
import * as yup from 'yup';

import Chrome from 'assets/icons/chrome';
import Edge from 'assets/icons/edge';
import { Spinner } from 'assets/images';
import ExtensionBanner from 'assets/images/extension-banner.png';
import { FeatureNameEnum } from 'config/constants/featureDefinitions';
import { useBrowserInfo } from 'hooks/useBrowserInfo';
import { logVariant, useFeatureFlag } from 'hooks/useFeatureFlags';
import { useIsWebLinkImportModalOpen } from 'hooks/useIsWebLinkImportModalOpen';
import { useTranslation } from 'hooks/useTypedTranslation';
import { actions as libraryActions } from 'store/library';
import { logSegmentEvent } from 'utils/analytics';
import { BrowserNameEnum, isOnMicrosoftEdgeBrowser } from 'utils/browser';
import { ExtensionType, checkIfEdgeExtensionIsInstalled, getRecommendedExtensionToInstall, saveImportedItem } from 'utils/extension';

import { WebAppImportType } from './constants';
import { useImportFile } from './hooks/useImportFile';

type WebLinkFormFields = { url: string };

const browsersForExtension: Array<string> = [
  BrowserNameEnum.Chrome,
  BrowserNameEnum.Brave,
  BrowserNameEnum.Arc,
  BrowserNameEnum.MicrosoftEdge,
  BrowserNameEnum.Opera
];

const upsellVideoShownLocalStorageKey = 'upsell_extension_video_on_weblink_shown';
const upsellTextShownLocalStorageKey = 'upsell_extension_text_on_weblink_shown';

export function UrlImport() {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const importWebLink = useImportFile();
  const { initialUrl } = useIsWebLinkImportModalOpen();

  const schema = yup.object().shape({
    url: yup.string().required(t('A valid web link is required')).url(t('A valid web link is required')).transform(ensureFullUrl) // Transforming input to ensure it includes a protocol
  });

  const {
    formState: { errors, isValid },
    handleSubmit: handleSubmitWrapper,
    register
  } = useForm<WebLinkFormFields>({
    values: {
      url: initialUrl
    },
    resolver: yupResolver(schema),
    shouldUnregister: true
  });

  const [isImporting, setIsImporting] = useState(false);

  const { variant: showExtensionUpsellVariant, isLoading: showExtensionUpsellVariantLoading } = useFeatureFlag(
    FeatureNameEnum.SHOW_EXTENSION_UPSELL_ON_WEB_LINK
  );

  const { browserName, browserIcon } = useBrowserInfo();
  const [upsellExtensionVideoOnWebLinkShown, setUpsellExtensionVideoOnWebLinkShown] = useState(Boolean(localStorage.getItem(upsellVideoShownLocalStorageKey)));
  const [upsellExtensionTextOnWebLinkShown, setUpsellExtensionTextOnWebLinkShown] = useState(Boolean(localStorage.getItem(upsellTextShownLocalStorageKey)));
  const chromeExtensionInstalled = useSelector((state: RootState) => state.usage.userData.chromeExtension !== null);
  const [edgeExtensionInstalled, setEdgeExtensionInstalled] = useState(false);
  const [hiddenUpsellExtension, setHiddenUpsellExtension] = useState(!!initialUrl);

  const extensionNotInstalled = useMemo(() => {
    if (isOnMicrosoftEdgeBrowser()) {
      return !chromeExtensionInstalled && !edgeExtensionInstalled;
    }
    return !chromeExtensionInstalled;
  }, [chromeExtensionInstalled, edgeExtensionInstalled]);

  const shouldShowUpsellV2 = useMemo(() => showExtensionUpsellVariant === 'videoAndText', [showExtensionUpsellVariant]);
  const isChromiumBrowser = useMemo(() => browsersForExtension.includes(browserName), [browserName]);

  const shouldShowVideo = useMemo(() => {
    return isChromiumBrowser && extensionNotInstalled && !upsellExtensionVideoOnWebLinkShown && shouldShowUpsellV2 && !hiddenUpsellExtension;
  }, [isChromiumBrowser, extensionNotInstalled, upsellExtensionVideoOnWebLinkShown, shouldShowUpsellV2, hiddenUpsellExtension]);

  const shouldShowTextUpsell = useMemo(
    () => isChromiumBrowser && (extensionNotInstalled || !upsellExtensionTextOnWebLinkShown) && shouldShowUpsellV2 && !hiddenUpsellExtension,
    [isChromiumBrowser, extensionNotInstalled, upsellExtensionTextOnWebLinkShown, shouldShowUpsellV2, hiddenUpsellExtension]
  );

  const shouldShowControlUpsell = useMemo(
    () => isChromiumBrowser && extensionNotInstalled && !shouldShowUpsellV2 && !hiddenUpsellExtension,
    [isChromiumBrowser, extensionNotInstalled, shouldShowUpsellV2, hiddenUpsellExtension]
  );

  const recommendedExtension = useMemo(() => getRecommendedExtensionToInstall(), []);

  useEffect(() => {
    checkIfEdgeExtensionIsInstalled().then(isInstalled => {
      setEdgeExtensionInstalled(isInstalled);
    });
  }, []);

  useEffect(() => {
    if (!showExtensionUpsellVariantLoading) {
      logVariant('showExtensionUpsellOnWebLink', showExtensionUpsellVariant);
    }
  }, [showExtensionUpsellVariant, showExtensionUpsellVariantLoading]);

  useEffect(() => {
    logSegmentEvent('web_app_import_modal_opened', { chrome_extension_upsell_shown: extensionNotInstalled });
    localStorage.setItem(upsellVideoShownLocalStorageKey, 'true');
  }, [extensionNotInstalled]);

  useEffect(() => {
    return () => setUpsellExtensionVideoOnWebLinkShown(true);
  }, []);

  const hideUpsellExtension = () => {
    setUpsellExtensionVideoOnWebLinkShown(true);
    setHiddenUpsellExtension(true);
  };

  const onSkipUpsellExtension = () => {
    logSegmentEvent('web_app_import_modal_extension_link_skipped');
    setUpsellExtensionVideoOnWebLinkShown(true);
  };

  const onTryNowExtension = () => {
    logSegmentEvent('web_app_import_modal_extension_link_clicked');
    window.open(recommendedExtension.extensionStoreUrl, '_blank');
    hideUpsellExtension();
  };

  const onCloseTextUpsellExtension = () => {
    setUpsellExtensionTextOnWebLinkShown(true);
    setHiddenUpsellExtension(true);
    localStorage.setItem(upsellTextShownLocalStorageKey, 'true');
  };

  const handleSubmit = useMemo(
    () =>
      handleSubmitWrapper(async (data: WebLinkFormFields) => {
        setIsImporting(true);
        const itemId = await importWebLink({ data, importType: WebAppImportType.WEB_LINK });

        if (itemId) {
          const item = (await dispatch(libraryActions.fetchItem({ itemId }))).payload as IRecord;
          saveImportedItem({ itemId, sourceURL: data.url, title: item.title });
        }
        setIsImporting(false);
      }),
    [handleSubmitWrapper, importWebLink, dispatch]
  );

  const urlError = errors.url?.message;

  return (
    <>
      {shouldShowVideo && (
        <div className="text-left flex flex-col w-full h-full">
          <div className="flex flex-col h-full overflow-hidden">
            <div className="flex gap-2 space-y-3 bg-electric-200 px-4 py-3">
              <div className="rounded-full bg-electric-250 p-[2px]">{browserIcon}</div>
              <h3 className="text-lg font-medium leading-6 text-gray-900">{t('Listen to any website with Speechify extension')}</h3>
            </div>

            <video src="https://storage.googleapis.com/speechify-website-assets/new-speechify/upsell-extension.mp4" preload="auto" autoPlay loop playsInline />
          </div>

          <div className="flex flex-row-reverse gap-2 bg-glass-300 px-6 py-3 text-right">
            <button
              className={twMerge(
                'flex items-center gap-2',
                'py-2.5 px-5 rounded-[8px] transition-colors text-base font-bold',
                'text-glass-0 bg-electric-400 hover:bg-electric-hovered-400 active:bg-electric-pressed-400'
              )}
              onClick={onTryNowExtension}
            >
              {t('Try now')}
            </button>
            <button
              className={twMerge(
                'flex items-center gap-2',
                'py-2.5 px-5 rounded-[8px] transition-colors text-base font-bold',
                'text-glass-700 bg-glass-200 hover:bg-glass-hovered-200 active:bg-glass-pressed-200'
              )}
              onClick={onSkipUpsellExtension}
            >
              {t('Skip')}
            </button>
          </div>
        </div>
      )}
      {!shouldShowVideo && (
        <form onSubmit={handleSubmit} className="pt-12 px-16 mx-auto flex gap-3 w-full max-w-[780px] h-full text-glass-700">
          <div className="flex flex-col w-full gap-1">
            <input
              id="textImportTitle"
              className={twMerge(
                'h-9 px-3 rounded-md transition-colors text-sm',
                'placeholder:text-glass-400 border border-px border-glass-300',
                'hover:border-glass-hovered-300 focus:border-electric-pressed-350'
              )}
              style={{ boxShadow: 'none' }}
              autoComplete="off"
              placeholder="https://"
              autoFocus
              disabled={isImporting}
              {...register('url')}
            />
            {!urlError && <div className="text-sm text-glass-400">{t('paste_a_link')}</div>}
            {urlError && <div className="text-sm text-red-400">{urlError.charAt(0).toUpperCase() + urlError.slice(1)}</div>}
          </div>
          <div className="shrink-0">
            <button
              type="submit"
              className={twMerge(
                'flex items-center gap-2',
                'py-2 px-4 ml-auto rounded-[8px] transition-colors text-sm font-medium',
                !isValid ? 'text-[#B3BDCA] bg-[#E4E7EC]' : 'text-glass-0 bg-electric-400 hover:bg-electric-hovered-400 active:bg-electric-pressed-400'
              )}
              disabled={isImporting || !isValid}
            >
              {isImporting && <Spinner width="20" fill="white" className="animate-spin" />}
              {t('Submit Link')}
            </button>
          </div>
        </form>
      )}
      {(shouldShowControlUpsell || shouldShowTextUpsell) && (
        <div className="relative shrink-0 align-items flex overflow-hidden h-60 box-border px-14 py-12 bg-glass-200 text-left align-bottom">
          <div className="flex gap-3 justify-center w-full">
            <span className="inline-flex w-12 h-12 items-center justify-center rounded-full bg-glass-300">
              {React.createElement(recommendedExtension.extensionType === ExtensionType.EDGE ? Edge : Chrome, {
                className: 'w-9 h-9'
              })}
            </span>

            <div className="flex flex-col gap-4 max-w-[380px]">
              <div className="flex flex-col gap-2 text-glass-700">
                <h3 className="text-xl font-bold">
                  {t('Add links faster with Chrome Extension', { browser: recommendedExtension.extensionType === ExtensionType.EDGE ? 'Edge' : 'Chrome' })}
                </h3>
                <p>{t('Enjoy easy listening with Speechify on every popular platform. It has never been more easy.')}</p>
              </div>
              <div>
                <button
                  className={twMerge(
                    'text-base font-bold px-5 rounded-lg inline-flex items-center h-11 transition-colors min-w-20 text-center',
                    'text-electric-400 border border-electric-400 hover:border-electric-hovered-400 active:border-electric-pressed-400'
                  )}
                  onClick={onTryNowExtension}
                >
                  {t('Install Chrome extension', { browser: recommendedExtension.extensionType === ExtensionType.EDGE ? 'Edge' : 'Chrome' })}
                </button>
              </div>
            </div>
            <div className="-mr-14 -mt-12 ml-auto">
              <img src={ExtensionBanner.src} className="h-60" />
            </div>
          </div>
          <XIcon
            className="absolute right-1 top-1 float-right block h-6 w-6 cursor-pointer text-glass-500"
            aria-hidden="true"
            onClick={onCloseTextUpsellExtension}
          />
        </div>
      )}{' '}
    </>
  );
}
