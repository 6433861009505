export enum UpgradeSource {
  contextualUpsell = 'web_app_contextual_upsell',
  contextualUpsellControl = 'web_app_contextual_upsell_control',
  ocrUpsell = 'web_app_ocr_upsell_upgrade',
  visualCta = 'web_app_audio_visual_cta_upgrade',
  voiceCloneCta = 'web_app_voice_clone_cta_upgrade',
  skipContentUpsell = 'web_app_skip_content_upsell',
  speedPickerUpsell = 'web_app_speed_picker_upsell',
  helpCenterMessageBtn = 'web_app_help_center_message_upsell',
  unlimitedSpeedPopup = 'web_app_unlimited_speed_popup',
  profoile = 'web_app_profile_upgrade',
  subscriptionSettings = 'web_app_subscription_settings_upgrade',
  accountPage = 'web_app_account_page_upgrade',
  askAI = 'web_app_ask_ai_upgrade',
  downloadMp3 = 'web_app_download_mp3_upgrade',
  downloadMp3v2 = 'web_app_download_mp3_upgrade_v2',
  imageOnlyPDFs = 'web_app_image_only_pdfs_upgrade',
  premiumVoices = 'web_app_premium_voices_upgrade',
  unlimitedFiles = 'web_app_unlimited_files_upgrade'
}
