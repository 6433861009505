import type {
  ContentBundle,
  ContentBundlerOptions,
  BundlerFactory as SDKBundlerFactory,
  ContentBundlerOptions as SDKContentBundlerOptions,
  EmbeddedReadingBundle as SDKEmbeddedReadingBundle,
  ReadingBundle as SDKReadingBundle,
  VoiceSpecOfAvailableVoice
} from '@speechifyinc/multiplatform-sdk';

import { FeatureNameEnum } from 'config/constants/featureDefinitions';
import { getFeatureVariant } from 'hooks/useFeatureFlags';
import { instrumentAction } from 'lib/observability';
import { SettingValue, SkipContentSettings } from 'modules/listening/features/settings/settings';
import { MeasurementKey } from 'modules/profiling/measurementTypes';
import { profilingStoreActions } from 'modules/profiling/profilingStore';

import { SDKContentSubType } from '../analytics/contentSubType';
import { MultiplatformSDKInstance } from '../sdk';
import { SDKFacade } from './_base';
import { SDKLibraryFacade } from './library';
import { ListenableContent } from './listenableContent';
import {
  ImportableListenableContent,
  isFileListenableContent,
  isItemListenableContent,
  isURLListenableContent,
  shouldRelyOnSDKForDeterminingFileTitle
} from './listenableContent/utils';

export class SDKContentBundlerOptionsFacade extends SDKFacade {
  constructor(
    sdk: MultiplatformSDKInstance,
    private options: SDKContentBundlerOptions,
    private bundle: SDKReadingBundle | SDKEmbeddedReadingBundle
  ) {
    super(sdk);
  }

  updateIndividualSetting = <K extends keyof SkipContentSettings>(key: K, value: SettingValue<K>, purgeUtteranceCache = true) => {
    switch (key) {
      case 'braces':
        this.options.preSpeechTransformOptions.shouldSkipBraces = value;
        break;

      case 'brackets':
        this.options.preSpeechTransformOptions.shouldSkipBrackets = value;
        break;

      case 'citations':
        this.options.preSpeechTransformOptions.shouldSkipCitations = value;
        break;

      case 'enhancedSkipping':
        this.options.mlParsingMode = value ? this.sdk.sdkModule.MLParsingMode.ForceEnable : this.sdk.sdkModule.MLParsingMode.ForceDisable;
        break;

      case 'footers':
        this.options.shouldSkipFooters = value;
        break;

      case 'footnotes':
        this.options.shouldSkipFootnotes = value;
        break;

      case 'headers':
        this.options.shouldSkipHeaders = value;
        break;

      case 'parentheses':
        this.options.preSpeechTransformOptions.shouldSkipParentheses = value;
        break;

      case 'urls':
        this.options.preSpeechTransformOptions.shouldSkipUrls = value;
        break;

      default:
        console.warn(`Unhandled key ${key} in updateIndividualSetting`);
        break;
    }

    if (purgeUtteranceCache) this._purgeUtteranceCache();
  };

  updateSkipSettings = (skipSettings: SkipContentSettings) => {
    Object.entries(skipSettings).forEach(([key, value]) => this.updateIndividualSetting(key as keyof SkipContentSettings, value, false));
    this._purgeUtteranceCache();
  };

  private _purgeUtteranceCache = () => {
    // TODO(sdk-pain-point): Updating contentBundlerOptions don't reflect immediately and we manually need to reset the voice to reset the SDK's utterance cache,
    //   ideally SDK should expose a proper API to reset the utterance cache or make the update to the contentBundlerOptions/preSpeechTransforOptions to apply immediately.
    const voiceOfCurrentUtterance = this.bundle.playbackControls.state.voiceOfCurrentUtterance;
    if (voiceOfCurrentUtterance) {
      this.bundle.listeningBundle.audioController.setVoice(voiceOfCurrentUtterance);
    }
  };
}

export type SDKBundleCreationRequirements = {
  initialVoice: VoiceSpecOfAvailableVoice;
  initialWPM: number;
  allVoices: VoiceSpecOfAvailableVoice[];
  shouldEnableOCR: boolean;
  skipContentSettings: SkipContentSettings;
};

type SDKBundleFactoryRequirements = SDKBundleCreationRequirements & {
  contentBundlerOptions: ContentBundlerOptions;
};

type SDKBundleOutput = {
  readingBundle: SDKReadingBundle;
  contentBundlerOptions: SDKContentBundlerOptionsFacade;
};

export class SDKBundleFacade extends SDKFacade {
  private static _singleton: SDKBundleFacade;

  constructor(sdk: MultiplatformSDKInstance) {
    super(sdk);
    SDKBundleFacade._singleton = this;
  }

  static override get singleton(): SDKBundleFacade {
    return SDKBundleFacade._singleton;
  }

  private getOcrStrategy = async (isOCREnabled: boolean) => {
    const sdk = this.sdk.sdkModule;
    if (!isOCREnabled) return sdk.OcrFallbackStrategy.ForceDisable;
    return sdk.OcrFallbackStrategy.ExperimentalStrategy;
  };

  private createContentBundlerOptions = async (isOCREnabled: boolean, skipContentSettings: SkipContentSettings) => {
    const options = new this.sdk.sdkModule.ContentBundlerOptions();

    options.ocrFallbackStrategy = await this.getOcrStrategy(isOCREnabled);

    // Default all skip to false
    options.mlParsingMode = skipContentSettings.enhancedSkipping ? this.sdk.sdkModule.MLParsingMode.ForceEnable : this.sdk.sdkModule.MLParsingMode.ForceDisable;
    options.shouldSkipHeaders = skipContentSettings.headers;
    options.shouldSkipFooters = skipContentSettings.footers;
    options.shouldSkipFootnotes = skipContentSettings.footnotes;
    options.preSpeechTransformOptions.shouldSkipBraces = skipContentSettings.braces;
    options.preSpeechTransformOptions.shouldSkipCitations = skipContentSettings.citations;
    options.preSpeechTransformOptions.shouldSkipParentheses = skipContentSettings.parentheses;
    options.preSpeechTransformOptions.shouldSkipBrackets = skipContentSettings.brackets;
    options.preSpeechTransformOptions.shouldSkipUrls = skipContentSettings.urls;

    options.enableRichBlocksParsingForHtmlContent();
    options.enableRichBlocksParsingForEpubContent();

    return options;
  };

  private handleImport = async (bundle: SDKReadingBundle, listenableContent: ImportableListenableContent) => {
    const WebBoundaryMap = this.sdk.WebBoundaryMap;
    const { FolderReferenceFactory, ImportOptions } = this.sdk.sdkModule;

    const folderId = await listenableContent.folderId;
    let analyticsProperties: Record<string, unknown> = {};
    if (isFileListenableContent(listenableContent)) {
      analyticsProperties = listenableContent.analyticsProperties || {};
    }
    const callback = await listenableContent.onImportCompleteCallback;

    bundle.contentImporter.startImport(
      new ImportOptions(
        // Pass empty string for title if content is URL so that SDK can fetch the title from the URL
        shouldRelyOnSDKForDeterminingFileTitle(listenableContent) ? '' : listenableContent.title,
        FolderReferenceFactory.fromId(folderId || ''),
        new WebBoundaryMap({
          prop: 'value',
          ...analyticsProperties
        })
      ),
      callback
    );
  };

  private createBundleFactory = async ({ initialVoice, initialWPM, allVoices, contentBundlerOptions }: SDKBundleFactoryRequirements) => {
    const { client, sdkModule: sdk } = this.sdk;
    const { ContentSortingStrategy } = sdk;

    const isSlidingWindowEnabled = await getFeatureVariant(FeatureNameEnum.SDK_SLIDING_WINDOW);

    const listeningBundlerOptions = new sdk.ListeningBundlerOptions();
    listeningBundlerOptions.textToSpeechIncludePrecedingContextForAudioSynthesisOverride = isSlidingWindowEnabled;

    profilingStoreActions.startMeasurement(MeasurementKey.sdkBundleFactoryCreation);
    const factory = client.createBundlerFactory(
      new sdk.BundlerFactoryConfig(
        new sdk.ClassicReadingBundlerConfig(new sdk.ClassicReadingBundlerOptions()),
        new sdk.BookReadingBundlerConfig(new sdk.BookReadingBundlerOptions(ContentSortingStrategy.ExperimentalV1)),
        new sdk.EmbeddedReadingBundlerConfig(new sdk.EmbeddedReadingBundlerOptions()),
        new sdk.ListeningBundlerConfig(
          new sdk.AudioConfig(sdk.AudioMediaFormat.MP3),
          initialWPM,
          allVoices,
          new sdk.VoicePreferences(
            new sdk.VoicePreferenceWithStaticValue(initialVoice), //
            new sdk.VoicePreferenceWithStaticValue(initialVoice)
          ),
          listeningBundlerOptions
        ),
        new sdk.ContentBundlerConfig(contentBundlerOptions)
      ),
      plugins => plugins
    );
    profilingStoreActions.endMeasurement(MeasurementKey.sdkBundleFactoryCreation);
    return factory;
  };

  private createReadingBundle = async (listenableContent: ListenableContent, factory: SDKBundlerFactory): Promise<SDKReadingBundle> => {
    const sdk = this.sdk.sdkModule;
    const promisify = this.sdk.promisify;
    const universalBundle = factory.universalSourcesReadingBundler;

    if (isItemListenableContent(listenableContent)) {
      const createBundleForResource = promisify(universalBundle.createBundleForResource.bind(universalBundle));
      const bundle = await createBundleForResource(
        sdk.SpeechifyURI.Companion.fromExistingId(sdk.SpeechifyEntityType.LIBRARY_ITEM, listenableContent.itemId),
        new sdk.BundleMetadata(
          /* contentSubType */ SDKContentSubType.LIBRARY,
          /* contentType */ SDKLibraryFacade.singleton.listenableContentToSDKContentType(listenableContent)
          /* importFlow */ // for already imported items, we don't need to track the import flow any longer
        )
      );
      return bundle;
    }

    const createBundle = async () => {
      const importStartChoice = sdk.ImportStartChoice.DoNotStart;
      const bundleMetadata = new sdk.BundleMetadata(
        SDKLibraryFacade.singleton.webAppImportFlowToSDKContentSubType(listenableContent.importType),
        SDKLibraryFacade.singleton.listenableContentToSDKContentType(listenableContent),
        SDKLibraryFacade.singleton.webAppImportFlowToSDKImportFlow(listenableContent.importFlow),
        isURLListenableContent(listenableContent) && listenableContent.needsUrlHostNameOverride ? listenableContent.urlHostnameWithoutCorsProxy : null
      );
      const createBundleForBlob = promisify(universalBundle.createBundleForBlob.bind(universalBundle));
      const createBundleForURL = promisify(universalBundle.createBundleForURL.bind(universalBundle));

      if (isFileListenableContent(listenableContent)) {
        return createBundleForBlob(listenableContent.file, importStartChoice, bundleMetadata);
      }

      try {
        const urlBundle = await createBundleForURL(listenableContent.url, importStartChoice, bundleMetadata);
        return urlBundle;
      } catch (err: unknown) {
        listenableContent.onErrorCallback?.(err as Error);
        // return an empty blob to avoid breaking the flow
        throw err;
      }
    };

    // note: Import is handled separately in background by the `handleImport` function above which will wait for few deps to resolve.
    const bundle = await createBundle();

    // Run the import in the next tick to avoid blocking the main thread for Instant Listening
    setTimeout(() => {
      this.handleImport(bundle, listenableContent);
    }, 1);

    return bundle;
  };

  public createBundles = async (listenableContent: ListenableContent, bundleCreationRequirements: SDKBundleCreationRequirements): Promise<SDKBundleOutput> => {
    const contentBundlerOptions = await this.createContentBundlerOptions(
      bundleCreationRequirements.shouldEnableOCR,
      bundleCreationRequirements.skipContentSettings
    );
    const factory = await this.createBundleFactory({
      ...bundleCreationRequirements,
      contentBundlerOptions
    });

    const readingBundle = await instrumentAction(() => this.createReadingBundle(listenableContent, factory), 'createReadingBundle');

    return {
      readingBundle: readingBundle,
      contentBundlerOptions: new SDKContentBundlerOptionsFacade(this.sdk, contentBundlerOptions, readingBundle)
    };
  };

  public createBookReaderFromLibraryItem = async (itemId: string, bundleCreationRequirements: SDKBundleCreationRequirements) => {
    const { SpeechifyURI, SpeechifyEntityType } = this.sdk.sdkModule;
    const promisify = this.sdk.promisify;

    const contentBundlerOptions = await this.createContentBundlerOptions(
      bundleCreationRequirements.shouldEnableOCR,
      bundleCreationRequirements.skipContentSettings
    );
    const factory = await this.createBundleFactory({
      ...bundleCreationRequirements,
      contentBundlerOptions
    });

    const bookReadingBundler = factory.universalSourcesReadingBundler;
    const bundle = await promisify(bookReadingBundler.createBundleForResource.bind(bookReadingBundler))(
      SpeechifyURI.Companion.fromExistingId(SpeechifyEntityType.LIBRARY_ITEM, itemId),
      null
    );

    const bookView = (bundle.listeningBundle.contentBundle as ContentBundle.BookBundle)?.bookView;
    const speechView = bundle.listeningBundle.contentBundle.speechView;
    return {
      bundle,
      bookView,
      speechView
    };
  };

  public createEmbeddedBundles = async ({ element, ...factoryRequirements }: { element: HTMLElement } & SDKBundleCreationRequirements) => {
    const { EmbeddedBundleMetadata, ContentType } = this.sdk.sdkModule;
    const contentBundlerOptions = await this.createContentBundlerOptions(factoryRequirements.shouldEnableOCR, factoryRequirements.skipContentSettings);
    const factory = await this.createBundleFactory({
      ...factoryRequirements,
      contentBundlerOptions
    });
    const promisify = this.sdk.promisify;
    const embeddedBundler = factory.createEmbeddedReadingBundler();

    const bundle = await promisify(embeddedBundler.createBundleForHtmlElement.bind(embeddedBundler))(
      element,
      new EmbeddedBundleMetadata(SDKContentSubType.AI_CHAT, ContentType.HTML, null)
    );
    return bundle;
  };
}
