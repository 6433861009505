import { AnalyticsEventKey, logAnalyticsEvent } from 'modules/analytics/logAnalyticsEvent';
import { getIsOnSharedListeningMode } from 'modules/listening/stores/listeningMode/selectors/getIsOnSharedListeningMode';
import { speedStoreActions } from 'modules/speed/store/speedStore';
import { showWordLimitModal } from 'modules/upsells/stores/actions/showWordLimitModal';
import { voiceStoreActions } from 'modules/voices/stores/actions';

import { getIsPremium } from '../selectors/getIsPremium';

export const switchToFree = async () => {
  const isOnSharedListeningMode = getIsOnSharedListeningMode();
  const isPremiumUser = getIsPremium();

  voiceStoreActions.switchToFreeVoice();
  if (!isPremiumUser) {
    speedStoreActions.switchToFreeSpeed();
  }

  if (!isOnSharedListeningMode) {
    showWordLimitModal();
    logAnalyticsEvent(AnalyticsEventKey.switchedToFree, {});
  }
};
