export const easing = ({
  elapsedTime,
  initialScrollTop,
  change,
  duration
}: {
  elapsedTime: number;
  initialScrollTop: number;
  change: number;
  duration: number;
}) => {
  if ((elapsedTime /= duration / 2) < 1) {
    return (change / 2) * elapsedTime * elapsedTime + initialScrollTop;
  }

  return (-change / 2) * (--elapsedTime * (elapsedTime - 2) - 1) + initialScrollTop;
};

export const isMouseOverScrollbar = (element: HTMLElement, event: MouseEvent) => {
  const rect = element.getBoundingClientRect();
  const scrollbarWidth = element.offsetWidth - element.clientWidth;
  const isOverScrollbar = event.clientX > rect.right - scrollbarWidth;
  return isOverScrollbar;
};

type WindowEventListener<K extends keyof WindowEventMap> = (this: Window, ev: WindowEventMap[K]) => unknown;
type HTMLElementEventListener<K extends keyof HTMLElementEventMap> = (this: HTMLElement, ev: HTMLElementEventMap[K]) => unknown;

export function addEventListener<K extends keyof WindowEventMap>(
  target: Window,
  eventName: K,
  listener: WindowEventListener<K>,
  options?: boolean | AddEventListenerOptions
): () => void;
export function addEventListener<K extends keyof HTMLElementEventMap>(
  target: HTMLElement,
  eventName: K,
  listener: HTMLElementEventListener<K>,
  options?: boolean | AddEventListenerOptions
): () => void;
export function addEventListener<K extends string>(
  target: Window | HTMLElement,
  eventName: K,
  listener: (...args: unknown[]) => unknown,
  options?: boolean | AddEventListenerOptions
): () => void {
  target.addEventListener(eventName, listener, options);
  return () => target.removeEventListener(eventName, listener, options);
}

export const getAutoScrollAnchor = () => {
  return window.innerHeight * 0.25;
};
