import type { BookPage } from '@speechifyinc/multiplatform-sdk';
import { BookPageRequestOptions } from '@speechifyinc/multiplatform-sdk/api/content/view/book';
import { IRecord } from 'interfaces';
import { isEmpty } from 'lodash';
import { useCallback, useEffect } from 'react';
import { useSelector } from 'store';
import { doItemsMatch } from 'utils';

import { promisify } from 'lib/speechify/adaptors/promisify';
import { getSDK } from 'modules/sdk/lib';
import { ListeningDependenciesFactory } from 'modules/sdk/listeningDependencies';
import { selectors as librarySelectors } from 'store/library';

import { addThumbnailToCache, setLoadingThumbnails, useThumbnailsCache } from '../helpers/thumbnailsCache';
import { useIsDownloadMp3V2Enabled } from './useFeatureFlag';
import { usePageCount } from './usePageCount';

const imageOptions = new BookPageRequestOptions(0.2);

const getImageUrl = async (page: BookPage) => {
  const thumbnailData = await promisify(page.getImage.bind(page))(imageOptions);
  const { binaryContent } = thumbnailData;
  const {
    blob: { size, type }
  } = binaryContent;
  const data = await promisify(binaryContent.getBytes.bind(binaryContent))(0, size);

  return URL.createObjectURL(new Blob([data.buffer], { type }));
};

export type ThumbnailImage = string | null;

export const useLoadThumbnails = (itemId: string | undefined) => {
  const { isDownloadMP3V2Enabled } = useIsDownloadMp3V2Enabled();
  const { thumbnailsCache } = useThumbnailsCache();
  const { pageCount } = usePageCount();
  const item = useSelector(state => librarySelectors.getById(itemId || '')(state), doItemsMatch);

  const thumbnailCacheEntry = itemId ? thumbnailsCache.get(itemId) : undefined;

  const loadThumbnails = useCallback(
    async (itemId: string, item: IRecord) => {
      setLoadingThumbnails(itemId, pageCount);
      const sdk = await getSDK();
      const dependenciesFactory = ListeningDependenciesFactory.initialize(sdk);
      const { bookView } = await sdk.bundle.createBookReaderFromLibraryItem(item.id, await dependenciesFactory.getBundleCreationRequirements());
      if (!bookView) {
        return;
      }
      const pageRange = Array.from({ length: pageCount }, (_, i) => i);
      const pages = await promisify(bookView.getPages.bind(bookView))(pageRange);
      // It seems that SDK uses stack to get images, that's why iteration is reversed
      for (let index = pages.length - 1; index >= 0; index--) {
        getImageUrl(pages[index]).then(thumbnailData => {
          addThumbnailToCache(itemId, index, thumbnailData);
        });
      }
    },
    [pageCount]
  );

  useEffect(() => {
    useThumbnailsCache.reset();
  }, []);

  useEffect(() => {
    if (!isDownloadMP3V2Enabled) {
      return;
    }

    if (isEmpty(item) || pageCount < 1 || !itemId) {
      return;
    }

    if (thumbnailCacheEntry) {
      return;
    }

    loadThumbnails(itemId, item);
  }, [itemId, item, pageCount, isDownloadMP3V2Enabled, thumbnailCacheEntry, loadThumbnails]);
};
