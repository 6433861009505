import { FC } from 'react';

import { isAndroid } from 'utils/browser';

import { AppStoreButton } from './AppStoreButton';
import { GooglePlayButton } from './GooglePlayButton';

export const DownloadMobileAppButton: FC<{ darkMode: boolean }> = ({ darkMode }) => {
  if (isAndroid()) {
    return <GooglePlayButton darkMode={darkMode} />;
  }
  return <AppStoreButton darkMode={darkMode} />;
};
