import { IRecord, RecordType } from 'interfaces';

import { MultiplatformSDKInstance } from '../../sdk';
import { SDKFacade } from '../_base';
import {
  DOCXItemListenableContent,
  EPUBItemListenableContent,
  HTMLItemListenableContent,
  PDFItemListenableContent,
  ScanItemListenableContent,
  TXTItemListenableContent
} from './item';
import { UnknownItemListenableContent } from './item/impl/UnknownItemListenableContent';
import { ListenableContent } from './types';

export class SDKContentFacade extends SDKFacade {
  private static _singleton: SDKContentFacade;
  constructor(sdk: MultiplatformSDKInstance) {
    super(sdk);
    SDKContentFacade._singleton = this;
  }

  static override get singleton(): SDKContentFacade {
    return SDKContentFacade._singleton;
  }

  public createListenableContent = (item: IRecord): ListenableContent => {
    switch (item.recordType) {
      case RecordType.PDF:
        return new PDFItemListenableContent(item);
      case RecordType.FILE:
        return new DOCXItemListenableContent(item);
      case RecordType.EPUB:
        return new EPUBItemListenableContent(item);
      case RecordType.WEB:
        return new HTMLItemListenableContent(item);
      case RecordType.SCAN:
        return new ScanItemListenableContent(item);
      case RecordType.TXT:
        return new TXTItemListenableContent(item);
      default:
        return new UnknownItemListenableContent(item);
    }
  };
}
