import { Nullable } from 'utils/types';

export type APIMessageContent = {
  type: 'prompt' | 'response' | 'summary';

  prompt?: {
    type: 'text' | 'generateSummaryForDocument' | 'generateSummaryForPages';
    body?: string;
    options?: APISummaryOptions;
  };

  response?: {
    type: 'text' | 'summary';
    body: string;
    regenerationCount: number;
    feedback: null;
    options?: APISummaryOptions;
  };

  body?: string;
  options?: APISummaryOptions;
  feedback?: {
    type: 'positive' | 'negative';
    text: string | null;
  };
};

export type APIMessage = {
  id: string;
  chatId: string;
  message: APIMessageContent;
  createdAt: FirebaseTimestamp;
  updatedAt: FirebaseTimestamp;
  contextAssets?: string[];
};

export type APISummaryOptions = {
  length: SummaryLengthType;
  format: SummaryFormatType;
  pageIndexes?: number[];
};

export type ChatRecord = {
  id: string;
  subject: {
    type: string;
    source: {
      type: string;
      id: string;
      documentType: string;
    };
  };
  createdAt: string;
  lastActiveAt: string;
};

export type ChatMessage =
  | { type: 'reply'; message: ReplyMessage }
  | { type: 'summary'; message: SummaryMessage }
  | {
      type: 'summary_loading';
      options: {
        mode: SummaryFormatTuple;
        length: SummaryLengthTuple;
        pageIndexes?: number[];
      };
      message: {
        id: string;
      };
    }
  | { type: 'user'; message: UserMessage };

export interface ChatResponse {
  chatId: string;
  subject?: {
    type: string;
    source: {
      type: string;
      id: string;
      documentType: DocumentType;
    };
  };
  createdAt?: string;
  lastActiveAt?: string;
}

export interface CreateChatRequest {
  title: string;
  thumbnailImageUrl?: string | null;
  source?: {
    type: 'libraryItem';
    id: string;
    documentType: DocumentType;
  } | null;
}

export type DocumentType = 'PDF' | 'SCAN' | 'HTML' | 'EPUB' | 'TXT';

export type FirebaseTimestamp = {
  _nanoseconds: number;
  _seconds: number;
};

export type ReplyMessage = {
  body: string;
  id: string;
  streaming?: boolean;
};

export type SummaryDialogState = {
  allPages: boolean;
  length: 'short' | 'medium' | 'long';
  mode: 'paragraph' | 'keypoints';
  open: boolean;
  pageIndexes: number[];
};

export type SummaryFormatTuple = 'paragraph' | 'keypoints';
export type SummaryFormatType = 'Paragraph' | 'Outline';

export type SummaryLengthTuple = 'short' | 'medium' | 'long';
export type SummaryLengthType = 'Short' | 'Medium' | 'Long';

export type SummaryMessage = {
  body: string;
  feedback: Nullable<SummaryFeedback>;
  id: string;
  length?: SummaryLengthTuple;
  mode?: SummaryFormatTuple;
  pageIndexes?: number[] | null;
  setFeedback: (feedback: SummaryFeedback) => Promise<void>;
  streaming?: boolean;
};

export type SummaryFeedback = {
  type: SummaryFeedbackType;
  text: Nullable<string>;
};

export type SummaryFeedbackType = 'positive' | 'negative';

export type UserMessage = {
  body: string;
  id: string;
  loading: boolean;
};

export const whileCondition = true;
