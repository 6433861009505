import * as React from 'react';

function FacebookIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="12" cy="12" r="10" fill="#1877F2" />
      <path
        d="M10.4447 14.9638V21.8828C10.4447 21.8828 10.9998 22 11.9998 22C12.9998 22 13.5446 21.8828 13.5446 21.8828V14.9534H15.8824L16.3252 12.0518H13.5446V10.1658C13.5446 9.37827 13.9359 8.5907 15.1821 8.5907H16.4385V6.13474C16.4385 6.13474 15.3057 5.92749 14.214 5.92749C11.9277 5.92749 10.4447 7.32645 10.4447 9.84459V12.0622H7.89062V14.9638H10.4447Z"
        fill="white"
      />
    </svg>
  );
}

export default FacebookIcon;
