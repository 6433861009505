export const DownloadSpeechifyIllustration = () => (
  <div className="w-[146px] h-[146px]">
    <svg className="mt-[-10.12px] mr-[-17.54px]" width="162" height="154" viewBox="0 0 162 154" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.36101 61.9395C4.05804 47.339 3.90656 40.0388 10.9997 31.5744C18.0929 23.11 25.252 21.9908 39.5703 19.7524C46.7286 18.6334 55.0265 17.5757 64.4635 16.7323C73.9005 15.8888 82.2544 15.4581 89.4977 15.29C103.986 14.9537 111.23 14.7856 119.712 21.8579C128.194 28.9303 129.339 36.1417 131.63 50.5644C132.778 57.7865 133.854 66.099 134.689 75.4342C135.475 84.2305 135.888 92.1713 136.06 99.1755C136.437 114.499 136.625 122.16 129.538 130.66C122.452 139.159 114.827 140.36 99.5776 142.761C92.5871 143.862 84.6826 144.882 75.9867 145.659C67.2908 146.436 59.3307 146.834 52.2558 146.991C36.8223 147.331 29.1056 147.502 20.624 140.394C12.1425 133.286 10.969 125.713 8.62194 110.566C7.54914 103.642 6.54781 95.7538 5.76161 86.9574C4.92725 77.6222 4.51273 69.2506 4.36101 61.9395Z"
        fill="#2F43FA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M102.753 16.048C103.952 9.44228 104.551 6.13943 108.521 2.97462C112.491 -0.19018 115.818 -0.0396786 122.474 0.261324C125.801 0.411809 129.637 0.6942 133.965 1.17725C138.294 1.6603 142.097 2.23026 145.376 2.81696C151.934 3.99047 155.213 4.57723 158.388 8.53927C161.563 12.5013 161.419 15.8551 161.132 22.5625C160.989 25.9213 160.714 29.7648 160.236 34.0469C159.785 38.0819 159.246 41.6973 158.682 44.8686C157.451 51.8067 156.835 55.2758 152.865 58.4568C148.895 61.6379 145.35 61.4816 138.26 61.169C135.01 61.0257 131.355 60.7623 127.366 60.3172C123.377 59.8721 119.755 59.3234 116.553 58.7467C109.568 57.4888 106.076 56.8598 102.905 52.8818C99.7336 48.9039 99.8975 45.3844 100.225 38.3454C100.375 35.128 100.646 31.4825 101.096 27.4475C101.574 23.1654 102.153 19.3558 102.753 16.048Z"
        fill="#26E2A8"
      />
      <path
        d="M133.731 15.4146C133.828 14.544 133.201 13.7594 132.33 13.6622C131.46 13.565 130.675 14.192 130.578 15.0627L133.731 15.4146ZM130.578 15.0627L127.23 45.0491L130.383 45.4011L133.731 15.4146L130.578 15.0627Z"
        fill="white"
      />
      <path d="M139.063 37.0288L128.807 45.2253L120.61 34.9687" stroke="white" strokeWidth="3.17254" strokeLinecap="round" strokeLinejoin="round" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.6788 70.3896C42.9764 66.2946 45.0976 64.1817 48.6235 64.9347C50.7571 65.3904 51.8462 67.9625 51.2911 71.3618C50.315 77.3387 50.0372 80.7443 50.3942 84.736C54.6778 73.2263 63.1091 57.6839 66.8906 52.3718C69.5043 48.7001 73.7267 48.761 75.3697 49.3809C78.0392 50.3882 78.4141 53.0564 77.76 55.6987C73.2348 73.9791 73.8691 83.4696 73.9415 92.7383C76.8269 82.1936 80.586 72.1813 85.5577 64.9219C87.6171 61.4692 91.6439 60.083 94.339 60.9038C97.0341 61.7246 97.8523 64.1918 97.5419 67.4029C96.7325 75.7769 96.3105 79.7054 96.5958 82.8959C99.2271 80.4849 101.313 78.4601 106.417 77.5836C111.521 76.707 122.33 77.8021 122.33 77.8021C122.33 77.8021 116.289 79.3921 113.12 80.4756C106.624 82.697 104.474 84.8076 100.737 91.4839C99.6516 93.4239 97.6795 95.0151 95.3003 95.0479C92.9211 95.0807 91.2015 93.7371 90.3288 91.793C89.1159 89.0908 88.4564 84.9457 88.8994 75.8082C83.6065 85.6681 81.5962 98.5983 78.1493 107.362C77.2679 109.603 75.6538 112.211 73.17 112.433C70.6862 112.655 67.3792 111.98 66.3017 104.644C63.5415 85.8517 65.7684 70.5017 65.7684 70.5017C59.7983 82.1435 58.1513 88.7656 56.0313 92.3315C53.9114 95.8973 51.8231 99.008 48.9609 99.1917C46.0987 99.3755 44.1515 96.334 43.5293 93.4494C42.9071 90.5648 42.4699 86.8487 42.9143 79.9203C40.4124 82.9112 38.1629 84.9864 33.9406 86.5374C29.7184 88.0885 24.4859 87.8095 18.38 87.0977C24.3744 86.5617 34.1387 82.046 40.6788 70.3896Z"
        fill="white"
      />
    </svg>
  </div>
);
