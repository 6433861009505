import { SDKError } from '@speechifyinc/multiplatform-sdk/api/util';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'store';

import { ErrorSource } from 'config/constants/errors';
import { useNavigate } from 'hooks/useNavigate';
import { useTranslation } from 'hooks/useTypedTranslation';
import { ALLOWED_MIME_TYPES, TextImport, UrlImport } from 'interfaces/import';
import { IntegrationFile } from 'interfaces/integrations';
import * as faro from 'lib/observability';
import { instrumentAction } from 'lib/observability';
import { ReaderMode, readerStoreActions } from 'modules/listening/features/reader/stores/readerStore';
import { MeasurementKey } from 'modules/profiling/measurementTypes';
import { profilingStoreActions } from 'modules/profiling/profilingStore';
import { ListenableContent } from 'modules/sdk/lib';
import { createListenableContent } from 'modules/sdk/lib/facade/listenableContent/factory';
import { actions as importActions } from 'store/import';
import { actions as libraryActions } from 'store/library';
import { actions as mainPageActions } from 'store/mainPage';
import { actions as toastActions } from 'store/toast';
import { logSegmentEvent } from 'utils/analytics';

import { WebAppImportFlow, WebAppImportType } from '../constants';
import { ImportOptions, importItem } from '../upload/import';
import { isCreatingListenableContentSupported, isIntegrationFile, isTextImport, isUrlImport } from '../utils';
import { useFileUpsell } from './useFileUpsell';

export interface ImportInfo {
  fileName: string;
  mimeType: string;
  fileSize: number;
}

function getImportInfo(data: IntegrationFile | File | TextImport | UrlImport): ImportInfo {
  if (isTextImport(data)) {
    return { fileName: data.title, mimeType: 'text', fileSize: 0 };
  }

  if (isUrlImport(data)) {
    return { fileName: '', mimeType: 'web_link', fileSize: 0 };
  }

  if (data instanceof File) {
    return { fileName: data.name, mimeType: data.type, fileSize: data.size };
  }

  const isGoogleDoc = data.fileExtension === 'gdoc';
  const fileName = isGoogleDoc ? `${data.name}.pdf` : data.name;
  const mimeType = isGoogleDoc ? ALLOWED_MIME_TYPES.PDF : data.mimeType;
  const fileSize = data.sizeBytes;

  return { fileName, mimeType, fileSize };
}

export type ImportFileData =
  | {
      data: File | TextImport | UrlImport;
      getFileUrl?: never;
      getFileBlob?: never;
    }
  | {
      data: IntegrationFile;
      getFileUrl: () => Promise<string | null>;
      getFileBlob: (filename: string, mimeType: string) => Promise<File>;
    };

export type ImportFileOptions = ImportFileData & {
  instantListening?: boolean;
  folderId?: string;
  source?: string;
  analyticsProperties?: Record<string, unknown>;
  coverImagePath?: string;
  onConverted?: () => void;
  importType: WebAppImportType;
  importFlow?: WebAppImportFlow;
};

const startInstantListening = (dispatch: ReturnType<typeof useDispatch>, listenableContent: ListenableContent) => {
  // TODO(overhaul, albertusdev): Refactor determineReaderMode to infer from listenableContent automatically, currently forgetting to call this will cause infinite loading state.
  const readerMode = readerStoreActions.determineReaderMode(listenableContent);

  profilingStoreActions.navigate(); // This is a temporary hack to make sure the instant listening measurement is not inflating other measurements.
  profilingStoreActions.startMeasurement(
    readerMode === ReaderMode.BOOK ? MeasurementKey.instantListeningBookReaderReady : MeasurementKey.instantListeningClassicReaderReady,
    {}
  );
  dispatch(
    mainPageActions.setInstantListening({
      flowStartTimestamp: performance.now(),
      isLoading: false,
      details: { listenableContent }
    })
  );
};

const tryInstantListening = (dispatch: ReturnType<typeof useDispatch>, listenableContent: ListenableContent | null) => {
  if (!listenableContent) return;
  startInstantListening(dispatch, listenableContent);
};

export function useImportFile(): (options: ImportFileOptions) => Promise<string | null> {
  const { t } = useTranslation('common');

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { checkForUpsell } = useFileUpsell();
  const folderId = useSelector(state => state.library.currentFolderId) ?? undefined;

  const handleFileImport = useCallback(
    async (options: ImportFileOptions): Promise<string | null> => {
      const {
        data: importData,
        source,
        onConverted,
        instantListening = true,
        getFileUrl,
        getFileBlob,
        folderId: overrideFolderId,
        importType,
        coverImagePath
      } = options;

      const importFlow = options.importFlow || WebAppImportFlow.PLUS_BUTTON_MODAL;

      if (await checkForUpsell()) {
        return null;
      }

      const importInfo = getImportInfo(importData);
      const { fileName, mimeType, fileSize } = importInfo;

      try {
        const data = getFileUrl ? ((await getFileUrl()) ?? (await getFileBlob(fileName, mimeType))) : importData;

        let listenableContent: ListenableContent | null = null;

        // check if we can start a new instant listening experience right away.
        const analyticsProperties = options.analyticsProperties ?? {};
        if (isCreatingListenableContentSupported(data)) {
          listenableContent = createListenableContent(data, importType, analyticsProperties, importFlow);
        }
        if (instantListening) {
          dispatch(
            mainPageActions.setInstantListening({
              flowStartTimestamp: performance.now(),
              isLoading: true
            })
          );
          tryInstantListening(dispatch, listenableContent);
        }

        const importOptions: ImportOptions = {
          folderId: overrideFolderId || folderId,
          instantListening,
          analyticsProperties,
          listenableContent: listenableContent
        };

        const importState = await instrumentAction(
          () =>
            importItem({ data, name: fileName, mimeType, source, size: fileSize, analyticsProperties, coverImagePath }, importOptions, {
              importType,
              importFlow
            }), //
          'importItem'
        );

        if (!listenableContent) {
          // If we have direct reference to a file we can start listening before the import completes
          if (importState.importSource.data instanceof File) {
            listenableContent = createListenableContent(importState.importSource.data, importType, analyticsProperties, importFlow);
            if (instantListening) {
              tryInstantListening(dispatch, listenableContent);
            }
          }

          // As last option we will wait for the import to complete before starting listening
          if (!listenableContent) {
            listenableContent = await importState.completed;
            if (instantListening) {
              startInstantListening(dispatch, listenableContent);
            }
          }
        }

        dispatch(importActions.closeImportDialog());

        onConverted?.();

        if (data instanceof File || isIntegrationFile(data)) {
          logSegmentEvent('web_app_file_uploaded', {
            type: fileName.split('.').slice(-1)[0],
            size: fileSize,
            source
          });
        }

        const itemId = await importState.imported;

        if (listenableContent) {
          listenableContent.onItemIdReady(itemId);
        }

        dispatch(libraryActions.fetchItemsCount());

        if (instantListening) {
          dispatch(libraryActions.fetchItem({ itemId }));
          if (!instantListening) {
            navigate(`/item/${itemId}?folder=${folderId}`);
          }
        }

        dispatch(
          toastActions.add({
            title: t('Item has been imported successfully')!,
            description: '',
            type: 'success'
          })
        );

        return itemId;
      } catch (e) {
        faro.logError(e as Error, ErrorSource.FILE_IMPORT);

        if (e instanceof SDKError.OtherException) {
          if (e.exception.message === 'INVALID_PASSWORD') {
            logSegmentEvent('password-protected-document-fail');
          }
        } else if (e instanceof Error) {
          if ('stderr' in e && typeof e.stderr === 'string' && e.stderr.includes('This file requires a password for access')) {
            logSegmentEvent('password-protected-document-fail');
          }
        }

        dispatch(mainPageActions.setInstantListening(null));
        dispatch(
          toastActions.add({
            title: t('Error')!,
            description: t('error_processing_document')!,
            type: 'error'
          })
        );

        return null;
      }
    },
    [dispatch, folderId, t, checkForUpsell, navigate]
  );

  return handleFileImport;
}
