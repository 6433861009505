import type { ImageRemoteElementMeta } from 'modules/sdk/lib/facade/legacyReader/classic/ClassicBlockInfo';

import type { LeafClassicElementProps } from './';

export function ClassicElementImage(props: LeafClassicElementProps<ImageRemoteElementMeta>) {
  const meta = props.meta;
  return (
    // Next image can't be used here as you can't have arbritrary remote hosts in it
    // see https://nextjs.org/docs/messages/next-image-unconfigured-host
    // eslint-disable-next-line @next/next/no-img-element
    <img
      className={(meta.data.width && meta.data.width < 450) || !meta.data.width ? 'float-right pt-2 pb-4 pr-0 pl-6' : 'block py-6'}
      alt={meta.data.altText ?? ''}
      src={meta.data.url}
      width={meta.data.width ?? undefined}
      height={meta.data.height ?? 300}
    />
  );
}
