import { IRecord } from 'interfaces/library';

import { ContentMetaType } from '../../content.types';
import { BaseItemListenableContent } from '../BaseItemListenableContent';

export class TXTItemListenableContent extends BaseItemListenableContent {
  public readonly metaType: ContentMetaType = ContentMetaType.TXT;
  constructor(item: IRecord) {
    super(item);
  }
}
