import { useMemo } from 'react';

import { IsDownloadMp3EnabledVariant } from 'config/constants/featureDefinitions';
import { FeatureNameEnum, useFeatureVariant } from 'hooks/useFeatureFlags';

export const useIsDownloadMp3V2Enabled = () => {
  const downloadMp3Variant = useFeatureVariant(FeatureNameEnum.IS_DOWNLOAD_MP3_ENABLED);
  const isDownloadMP3V2Enabled = useMemo(
    () => !downloadMp3Variant.isLoading && downloadMp3Variant.variant === IsDownloadMp3EnabledVariant.ENABLED_V2,
    [downloadMp3Variant]
  );

  return { isDownloadMP3V2Enabled };
};
