import { Spinner } from 'assets/images';

type ButtonColor = 'primary' | 'outlined' | 'alternate' | 'default' | 'red' | 'green' | 'green-outlined' | 'yellow' | 'unset' | 'link' | 'custom';

interface ButtonProps {
  className?: string;
  innerClassName?: string;
  color?: ButtonColor;
  disabled?: boolean;
  full?: boolean;
  isLoading?: boolean;
  size?: 'xs' | 'sm' | 'default' | 'lg' | 'xl';
  title?: string;
}

const classNamesMap: Record<ButtonColor, string> = {
  primary: `
    text-icon-primary-cta
    bg-button-primary-cta
    border-0
    hover:bg-button-primary-cta-hovered
    active:bg-button-primary-cta-pressed
    focus:shadow-outline-button-primary-cta
  `,
  alternate: `
    text-mauve1
    bg-transparent
    hover:bg-white
    focus:border-mauve0
    focus:shadow-outline-mauve1
  `,
  outlined: `
    text-glass-500
    bg-transparent
    hover:bg-white
    focus:border-glass-300
    focus:shadow-outline-glass-500
  `,
  red: `
    text-white
    bg-red-600
    hover:bg-red-500
    focus:border-red-700
    focus:shadow-outline-red
  `,
  green: `
    text-white 
    bg-green-400
    hover:bg-green-300
    focus:border-green-600
    focus:shadow-outline-green
  `,
  'green-outlined': `
    text-green-400
    bg-green-210
    hover:bg-green-300
    focus:border-green-600
    focus:shadow-outline-green
  `,
  yellow: `
    text-black
    bg-yellow-400 
    hover:bg-yellow-100
    focus:border-yellow-600
    focus:shadow-outline-yellow
  `,
  default: `
    text-gray-700
    bg-gray-200
    hover:bg-gray-300
    focus:border-gray-700
    focus:shadow-outline-gray
  `,
  unset: `
    text-gray-700
    bg-white
    hover:bg-gray-100
    focus:border-blue-300
    focus:shadow-outline-gray
  `,
  link: `
    text-blue-500
    bg-transparent
    hover:bg-transparent
    focus:border-transparent
    focus:shadow-outline-transparent
  `,
  custom: ''
};

const Button = ({
  children,
  className = '',
  innerClassName = '',
  color = 'primary',
  disabled = false,
  full = false,
  isLoading = false,
  size = 'default',
  title,
  type = 'button',
  ...buttonProps
}: ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>): JSX.Element => {
  let spacing = 'px-4 py-2';

  switch (size) {
    case 'default':
      spacing = 'px-4 py-2 min-w-24';
      break;

    case 'xs':
      spacing = 'px-2 py-1';
      break;

    case 'lg':
      spacing = 'px-2 py-3';
      break;

    default:
      break;
  }

  let bgOpacity = '';

  if (disabled) bgOpacity = 'bg-opacity-50 cursor-not-allowed';

  const Children = (
    <>
      {title}
      {children}
    </>
  );

  return (
    <button
      className={`
        ${full ? 'w-full' : ''}
        ${color !== 'green-outlined' && color !== 'link' ? 'border shadow-sm' : ''}
        flex justify-center
        rounded-md text-sm transition duration-150
        ease-in-out hover:shadow-none focus:outline-none
        
        ${classNamesMap[color]}
        ${spacing}
        ${bgOpacity}

        ${className}
      `}
      disabled={disabled}
      type={type}
      {...buttonProps}
    >
      {isLoading && <Spinner width="20" fill={color === 'unset' ? 'black' : 'white'} className="absolute animate-spin" />}
      <span className={`${innerClassName} ${isLoading ? 'invisible' : ''}`}>{Children}</span>
    </button>
  );
};

export default Button;
