import { WebAppImportFlow, WebAppImportType } from 'components/library/import/constants';

import { ALLOWED_MIME_TYPES } from 'interfaces/import';

import { EPUBFileListenableContent, FileListenableContent, HTMLFileListenableContent, TXTFileListenableContent } from './';
import { PDFFileListenableContent } from './impl/PDFFileListenableContent';

export const createFileListenableContent = (
  file: File,
  importType: WebAppImportType,
  analyticsProperties: Record<string, unknown>,
  importFlow: WebAppImportFlow = WebAppImportFlow.PLUS_BUTTON_MODAL
): FileListenableContent | null => {
  switch (file.type) {
    case ALLOWED_MIME_TYPES.PDF:
      return new PDFFileListenableContent(file, importType, analyticsProperties, importFlow);
    case ALLOWED_MIME_TYPES.EPUB:
      return new EPUBFileListenableContent(file, importType, analyticsProperties, importFlow);
    case ALLOWED_MIME_TYPES.HTML:
      return new HTMLFileListenableContent(file, importType, analyticsProperties, importFlow);
    case ALLOWED_MIME_TYPES.TXT:
      return new TXTFileListenableContent(file, importType, analyticsProperties, importFlow);
    default:
      return null;
  }
};
