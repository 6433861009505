import CircularProgressBar from 'components/gamification/CircularProgressBar';
import { IRecord, IUser, ItemActionType, RecordStatus, RecordType } from 'interfaces';
import React, { MouseEvent, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'store';
import { twMerge } from 'tailwind-merge';
import { carryParams, doItemsMatch } from 'utils';

import { ErrorSource, LibraryErrorOperation } from 'config/constants/errors';
import { useNavigate } from 'hooks/useNavigate';
import { useTranslation } from 'hooks/useTypedTranslation';
import { logError } from 'lib/observability';
import { deleteItem, isOnTrial, isPremium, isStripe } from 'lib/speechify';
import { actions as libraryActions, selectors as librarySelectors } from 'store/library';
import { actions as toastActions } from 'store/toast';
import { logSegmentEvent, setTimeToPlay } from 'utils/analytics';
import { formatDuration } from 'utils/dates';

import Loading from '../../elements/Loading';
import ItemActionMenu from './ActionMenu';
import RowIcon from './components/RowIcon';

interface RowProps {
  coverImagePath: string | null;
  id: string;
  moveTargets: string[];
  selected?: boolean;
  selectOnClick?: boolean;
  selectionAvailable?: boolean;
  progressPercent?: number;
  onAction?: (item: IRecord, actionType: ItemActionType) => void;
  isTrash?: boolean;
  preventDrag?: boolean;
  archivedItems?: IRecord[];
  setArchivedItems?: (items: IRecord[]) => void;
  recordType: string;
  sourceURL: string;
  status: RecordStatus;
  timeAgo?: string;
  title: string;
  wpm?: number;
  wordCount: number;
}

export const ItemRow: React.FC<RowProps> = ({
  progressPercent,
  id,
  moveTargets = [],
  onAction = () => {},
  selected = false,
  selectOnClick = false,
  selectionAvailable = false,
  recordType,
  sourceURL,
  status,
  isTrash,
  preventDrag,
  archivedItems,
  setArchivedItems,
  timeAgo,
  title,
  wordCount,
  wpm = 200
}) => {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // state
  const [duration, setDuration] = useState<JSX.Element | null>(null);

  const item = useSelector(state => librarySelectors.getById(id)(state), doItemsMatch);

  const [isBeingDragged, setIsBeingDragged] = useState(false);

  const dragState = useSelector(state => ({ isDragging: state.mainPage.isDragging, activeDragTargetId: state.mainPage.activeDragTargetId }));

  // @ts-expect-error TS(2322): Type 'IUser | null' is not assignable to type 'IUs... Remove this comment to see the full error message
  const currentUser = useSelector<IUser>(state => state.auth.user);

  const isDownloadEnabled = isStripe(currentUser) && !isOnTrial(currentUser);
  const isPremiumUser = isPremium(currentUser) && !isOnTrial(currentUser);
  const downloadMp3 = useMemo(() => isDownloadEnabled && item.recordType === RecordType.PDF, [item, isDownloadEnabled]);
  const crossSellVoiceover = (!isPremium(currentUser) || (isPremium(currentUser) && isStripe(currentUser))) && !downloadMp3;

  useEffect(() => {
    if (wordCount && wpm) {
      setDuration(<>{formatDuration(wordCount / wpm)}</>);
    }
  }, [wordCount, wpm]);

  const restoreArchivedItem = async (itemId: string) => {
    try {
      // Remove from the state
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      const newArchivedItems = archivedItems.filter(item => item.id !== itemId);
      // @ts-expect-error TS(2722): Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      setArchivedItems(newArchivedItems);

      dispatch(libraryActions.restoreItem(itemId));
    } catch (e) {
      logError(e as Error, ErrorSource.LIBRARY, {
        context: {
          itemId,
          operation: LibraryErrorOperation.RESTORE_ITEM
        }
      });

      dispatch(
        toastActions.add({
          title: '',
          description: t('Error restoring the item')!,
          type: 'error'
        })
      );
    }
  };

  const deleteArchivedItem = async (itemId: string) => {
    try {
      // Remove from the state
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      const newArchivedItems = archivedItems.filter(item => item.id !== itemId);
      // @ts-expect-error TS(2722): Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      setArchivedItems(newArchivedItems);

      await deleteItem(itemId);

      dispatch(
        toastActions.add({
          title: '',
          description: t('Item deleted')!,
          type: 'success'
        })
      );
    } catch (e) {
      // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
      logError(e, ErrorSource.LIBRARY, {
        context: {
          itemId,
          operation: LibraryErrorOperation.DELETE_ARCHIVED_ITEM
        }
      });

      dispatch(
        toastActions.add({
          title: '',
          description: t('Error deleting the item')!,
          type: 'error'
        })
      );
    }
  };

  const handleActionClick = (actionType: ItemActionType) => {
    switch (actionType) {
      case ItemActionType.Open:
        setTimeToPlay('open-item', recordType);
        navigate(carryParams(`/item/${id}`));
        break;

      case ItemActionType.OpenOriginal:
        window.open(sourceURL, '_blank');
        break;

      case ItemActionType.DeleteArchivedItem:
        deleteArchivedItem(id);
        break;

      case ItemActionType.Restore:
        restoreArchivedItem(id);
        break;

      case ItemActionType.CrossSellVoiceOver:
        logSegmentEvent('web_app_voiceover_cross_sell_pressed', { source: 'card' });
        window.open('https://studio.speechify.com/?fromWebApp=true', '_blank');
        break;

      case ItemActionType.OpenInListeningScreenV2:
        logSegmentEvent('web_app_listening_screen_v2_pressed', { source: 'card' });
        navigate(carryParams(`/item-v2/${id}`));
        break;

      default:
        onAction(item, actionType);
    }
  };

  const handleItemClick = (e: MouseEvent) => {
    if (selectOnClick && selectionAvailable) {
      handleActionClick(selected ? ItemActionType.Unselect : ItemActionType.Select);
      return;
    }

    if (status === RecordStatus.Processing) return;

    setTimeToPlay('open-item', recordType);

    if (e.metaKey) {
      window.open(carryParams(`/item/${id}`), '_blank');
    } else {
      navigate(carryParams(`/item/${id}`));
    }
  };

  const handleSelectionChange = () => {
    handleActionClick(selected ? ItemActionType.Unselect : ItemActionType.Select);
  };

  useEffect(() => {
    if (dragState.isDragging && dragState.activeDragTargetId === item.id) {
      setIsBeingDragged(true);
    } else {
      setIsBeingDragged(false);
    }
  }, [dragState, item]);

  return (
    <li className={twMerge('group flex items-center mr-8')}>
      {selectionAvailable ? (
        <div
          className={twMerge('flex cursor-pointer items-center mx-2', !selectOnClick ? 'opacity-0 group-hover:opacity-100' : '')}
          onClick={e => {
            e.stopPropagation();
            handleSelectionChange();
          }}
        >
          <input
            className="relative h-4 w-4 cursor-pointer focus:ring-0 border-2 border-glass-400 hover:bg-glass-200"
            type="checkbox"
            checked={selected}
            onClick={e => e.stopPropagation()}
            onChange={handleSelectionChange}
          />
        </div>
      ) : (
        <div className="h-8 w-8 " />
      )}

      <a
        className={twMerge(
          moveTargets.length > 0 && !preventDrag && !selectOnClick ? 'cursor-pointer' : 'cursor-default',
          'grid grid-cols-[4fr_1fr_1fr_1fr] w-full py-3 border-b border-glass-300 hover:bg-gray-50',
          selected ? 'bg-glass-300' : '',
          isBeingDragged ? 'opacity-20' : ''
        )}
        onClick={handleItemClick}
      >
        <div className="flex gap-1 items-center">
          <div className="relative ml-2 flex w-5 justify-center">
            <RowIcon kind={recordType as RecordType} />

            {status === RecordStatus.Processing && (
              <div className="absolute left-0 top-0 flex h-full w-full items-center justify-center">
                <Loading />
              </div>
            )}
          </div>

          <h3 className="line-clamp-1 font-ABCDiatype pl-1 pr-8 text-sm font-medium leading-5 break-all text-glass-700 flex-1">{title}</h3>
        </div>
        <div className="text-glass-500 text-sm font-normal font-ABCDiatype">{duration}</div>
        <div className="flex items-center gap-2">
          {
            // @ts-expect-error TS(2532): Object is possibly 'undefined'.
            progressPercent > 0 && (
              <>
                <CircularProgressBar
                  // @ts-expect-error TS(2322): Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
                  value={progressPercent}
                  maxValueCheckDisabled
                  strokeWidth={14}
                  style={{ height: '20px', width: '20px' }}
                />
                <span className="text-glass-500 text-sm font-normal font-ABCDiatype">{progressPercent}%</span>
              </>
            )
          }
        </div>
        <div className="flex items-center">
          <span className="flex-1 text-glass-500 text-sm font-normal font-ABCDiatype">{timeAgo}</span>

          <ItemActionMenu
            hasOriginal={!!sourceURL}
            isRow={true}
            isTrash={isTrash}
            onAction={handleActionClick}
            hasVoiceOverCrossSell={crossSellVoiceover}
            isPremium={isPremiumUser}
            downloadMp3={downloadMp3}
          />
        </div>
      </a>
    </li>
  );
};

export default ItemRow;
