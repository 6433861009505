export const reskinVariables = {
  'bg-prim-w-b': 'var(--color-bg-prim-w-b)',
  'bg-prim-w-110': 'var(--color-bg-prim-w-110)',
  'bg-prim-w-100': 'var(--color-bg-prim-w-100)',
  'bg-prim-w-90': 'var(--color-bg-prim-w-90)',
  'bg-prim-w-80': 'var(--color-bg-prim-w-80)',
  'bg-prim-w-70': 'var(--color-bg-prim-w-70)',
  'bg-prim-inv-b-w': 'var(--color-bg-prim-inv-b-w)',
  'bg-prim-cta': 'var(--color-bg-prim-cta)',
  'bg-sec-0-110': 'var(--color-bg-sec-0-110)',
  'bg-sec-0-100': 'var(--color-bg-sec-0-100)',
  'bg-sec-0-90': 'var(--color-bg-sec-0-90)',
  'bg-sec-0-80': 'var(--color-bg-sec-0-80)',
  'bg-sec-0-70': 'var(--color-bg-sec-0-70)',
  'bg-sec-inv-100-0': 'var(--color-bg-sec-inv-100-0)',
  'bg-sec-cta': 'var(--color-bg-sec-cta)',
  'bg-tert-10-90': 'var(--color-bg-tert-10-90)',
  'bg-tert-10-80': 'var(--color-bg-tert-10-80)',
  'bg-tert-10-70': 'var(--color-bg-tert-10-70)',
  'bg-tert-20-60': 'var(--color-bg-tert-20-60)',
  'bg-tert-inv-60-20': 'var(--color-bg-tert-inv-60-20)',
  'bg-accent-reskin': 'var(--color-bg-accent)',
  'bg-alert-reskin': 'var(--color-bg-alert)',
  'bg-success-reskin': 'var(--color-bg-success)',
  'bg-blue': 'var(--color-bg-blue)',
  'bg-crit': 'var(--color-bg-crit)',
  'bg-dimmer': 'var(--color-bg-dimmer)',

  'brdr-prim-10-100': 'var(--color-brdr-prim-10-100)',
  'brdr-prim-10-80': 'var(--color-brdr-prim-10-80)',
  'brdr-prim-inv-80-10': 'var(--color-brdr-prim-inv-80-10)',
  'brdr-prim-hov': 'var(--color-brdr-prim-hov)',
  'brdr-prim-foc': 'var(--color-brdr-prim-foc)',
  'brdr-prim-cta': 'var(--color-brdr-prim-cta)',
  'brdr-sec-20-60': 'var(--color-brdr-sec-20-60)',
  'brdr-sec-cta': 'var(--color-brdr-sec-cta)',
  'brdr-blue': 'var(--color-brdr-blue)',
  'brdr-tert-40-50': 'var(--color-brdr-tert-40-50)',
  'brdr-crit': 'var(--color-brdr-crit)',

  'sf-prim-cta': 'var(--color-sf-prim-cta)',
  'sf-prim-cta-hov': 'var(--color-sf-prim-cta-hov)',
  'sf-prim-cta-pres': 'var(--color-sf-prim-cta-pres)',
  'sf-prim-w-b': 'var(--color-sf-prim-w-b)',
  'sf-prim-hov-w-b': 'var(--color-sf-prim-hov-w-b)',
  'sf-prim-pres-w-b': 'var(--color-sf-prim-pres-w-b)',
  'sf-prim-w-110': 'var(--color-sf-prim-w-110)',
  'sf-prim-hov-w-110': 'var(--color-sf-prim-hov-w-110)',
  'sf-prim-pres-w-110': 'var(--color-sf-prim-pres-w-110)',
  'sf-prim-w-100': 'var(--color-sf-prim-w-100)',
  'sf-prim-hov-w-100': 'var(--color-sf-prim-hov-w-100)',
  'sf-prim-pres-w-100': 'var(--color-sf-prim-pres-w-100)',
  'sf-prim-w-90': 'var(--color-sf-prim-w-90)',
  'sf-prim-hov-w-90': 'var(--color-sf-prim-hov-w-90)',
  'sf-prim-pres-w-90': 'var(--color-sf-prim-pres-w-90)',
  'sf-prim-w-80': 'var(--color-sf-prim-w-80)',
  'sf-prim-hov-w-80': 'var(--color-sf-prim-hov-w-80)',
  'sf-prim-pres-w-80': 'var(--color-sf-prim-pres-w-80)',
  'sf-sec-0-110': 'var(--color-sf-sec-0-110)',
  'sf-sec-hov-0-110': 'var(--color-sf-sec-hov-0-110)',
  'sf-sec-pres-0-110': 'var(--color-sf-sec-pres-0-110)',
  'sf-sec-0-100': 'var(--color-sf-sec-0-100)',
  'sf-sec-hov-0-100': 'var(--color-sf-sec-hov-0-100)',
  'sf-sec-pres-0-100': 'var(--color-sf-sec-pres-0-100)',
  'sf-sec-0-90': 'var(--color-sf-sec-0-90)',
  'sf-sec-hov-0-90': 'var(--color-sf-sec-hov-0-90)',
  'sf-sec-pres-0-90': 'var(--color-sf-sec-pres-0-90)',
  'sf-act-electric-blue': 'var(--color-sf-act-electric-blue)',
  'sf-act-blue': 'var(--color-sf-act-blue)',
  'sf-act-blue-60': 'var(--color-sf-act-blue-60)',
  'sf-act-blue-70': 'var(--color-sf-act-blue-70)',
  'sf-act-blue-80': 'var(--color-sf-act-blue-80)',
  'sf-crit': 'var(--color-sf-crit)',
  'sf-crit-hov': 'var(--color-sf-crit-hov)',
  'sf-crit-pres': 'var(--color-sf-crit-pres)',

  'icn-txt-white': 'var(--color-icn-txt-white)',
  'icn-txt-black': 'var(--color-icn-txt-black)',
  'icn-txt-prim': 'var(--color-icn-txt-prim)',
  'icn-txt-prim-inv': 'var(--color-icn-txt-prim-inv)',
  'icn-txt-sec': 'var(--color-icn-txt-sec)',
  'icn-txt-tert': 'var(--color-icn-txt-tert)',
  'icn-txt-quat': 'var(--color-icn-txt-quat)',
  'icn-txt-prim-electric': 'var(--color-icn-txt-prim-electric)',
  'icn-txt-accent': 'var(--color-icn-txt-accent)',
  'icn-txt-blue': 'var(--color-icn-txt-blue)',
  'icn-txt-success': 'var(--color-icn-txt-success)',
  'icn-txt-alert': 'var(--color-icn-txt-alert)',
  'icn-txt-crit': 'var(--color-icn-txt-crit)',

  'hglt-prim': 'var(--color-hglt-prim)',
  'hglt-sec': 'var(--color-hglt-sec)',
  'hglt-hov-prim': 'var(--color-hglt-hov-prim)',
  'hglt-hov-sec': 'var(--color-hglt-hov-sec)',

  'speechify-logo': 'var(--color-speechify-logo)',
  'speechify-logo-txt': 'var(--color-speechify-logo-txt)'
};
