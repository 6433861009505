import { NavFeature } from 'components/newListeningExperience/topnav/Nav';

import { useIsDownloadMp3V2Enabled } from './useFeatureFlag';

export const useEnabledDownloadMp3Version = (enabledFeaturesSet: Set<NavFeature>) => {
  const isDownloadMP3V1Enabled = enabledFeaturesSet.has('downloadMp3');
  const { isDownloadMP3V2Enabled } = useIsDownloadMp3V2Enabled();

  const enabledDownloadMp3Version = isDownloadMP3V2Enabled ? 2 : isDownloadMP3V1Enabled ? 1 : null;

  return { enabledDownloadMp3Version };
};
