import * as React from 'react';

function GmailIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_24026_4901)">
        <path d="M3.36364 20.0001H6.54545V12.2729L2 8.86377V18.6365C2 19.391 2.61136 20.0001 3.36364 20.0001Z" fill="#4285F4" />
        <path d="M17.4546 20.0001H20.6364C21.3909 20.0001 22 19.3888 22 18.6365V8.86377L17.4546 12.2729" fill="#34A853" />
        <path d="M17.4546 6.3636V12.2727L22 8.8636V7.04542C22 5.35905 20.075 4.39769 18.7273 5.40905" fill="#FBBC04" />
        <path d="M6.54544 12.2729V6.36377L12 10.4547L17.4545 6.36377V12.2729L12 16.3638" fill="#EA4335" />
        <path d="M2 7.04566V8.86384L6.54545 12.2729V6.36384L5.27273 5.4093C3.92273 4.39793 2 5.3593 2 7.04566Z" fill="#C5221F" />
      </g>
      <defs>
        <clipPath id="clip0_24026_4901">
          <rect width="20" height="15" fill="white" transform="translate(2 5)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default GmailIcon;
