// Base types should be isolated to avoid circular dependencies
export enum ContentMetaType {
  PDF = 'PDF',

  HTML = 'HTML',
  DOCX = 'DOCX',
  TXT = 'TXT',
  SCAN = 'SCAN',
  EPUB = 'EPUB',

  UNKNOWN = 'UNKNOWN'
}

export type ContentMetaTypeLowercase = Lowercase<ContentMetaType>;

export enum ContentType {
  libraryItem = 'libraryItem',
  file = 'file',
  url = 'url'
}
