import type { HeadingElementMeta } from 'modules/sdk/lib/facade/legacyReader/classic/ClassicBlockInfo';

import { ClassicElementRenderer } from '../ClassicElementRenderer';
import type { InternalClassicElementProps } from './';

export function ClassicElementHeading(props: InternalClassicElementProps<HeadingElementMeta>) {
  const level = props.meta.data.level;
  let Heading: React.ElementType = 'h3';
  switch (level) {
    case 1:
      Heading = 'h1';
      break;
    case 2:
      Heading = 'h2';
      break;
    default:
      break;
  }

  return (
    <>
      <div className="clear-right" />
      <Heading className="whitespace-pre-wrap">
        {props.element.elements.map(element => (
          <ClassicElementRenderer key={element.elementId} element={element} />
        ))}
      </Heading>
    </>
  );
}
