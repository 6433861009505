import React from 'react';

const ShareArrowUpIcon: React.FC<React.SVGAttributes<SVGSVGElement>> = props => {
  return (
    <svg width="20" height="20" viewBox="3 3 22 22" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M13.3635 6.03033C13.715 5.67886 14.2849 5.67886 14.6363 6.03033L18.6363 10.0303C18.9878 10.3818 18.9878 10.9516 18.6363 11.3031C18.2849 11.6546 17.715 11.6546 17.3635 11.3031L14.8999 8.83952V17.259C14.8999 17.756 14.497 18.159 13.9999 18.159C13.5029 18.159 13.0999 17.756 13.0999 17.259V8.83952L10.6363 11.3031C10.2849 11.6546 9.71501 11.6546 9.36354 11.3031C9.01207 10.9516 9.01207 10.3818 9.36354 10.0303L13.3635 6.03033Z" />
      <path d="M21.5667 17C21.5667 16.5029 21.1637 16.1 20.6667 16.1C20.1696 16.1 19.7667 16.5029 19.7667 17V19.201C19.7667 19.5665 19.5178 19.8568 19.1994 19.9178C15.6131 20.6051 12.387 20.6051 8.80061 19.9178C8.48227 19.8568 8.23335 19.5665 8.23335 19.201L8.23335 17C8.23335 16.5029 7.83041 16.1 7.33335 16.1C6.83629 16.1 6.43335 16.5029 6.43335 17V19.201C6.43335 20.3882 7.25506 21.4544 8.4618 21.6856C12.272 22.4159 15.728 22.4159 19.5382 21.6856C20.745 21.4544 21.5667 20.3882 21.5667 19.201V17Z" />
    </svg>
  );
};

export default ShareArrowUpIcon;
