import React from 'react';

export const SpeechifyTextLogo = ({ isDarkMode, ...props }: { isDarkMode: boolean } & React.SVGProps<SVGSVGElement>) => {
  const logoFill = isDarkMode ? '#FFFFFF' : '#2F43FA';
  const textFill = isDarkMode ? '#FFFFFF' : '#000000';
  return (
    <svg width="93" height="16" viewBox="0 0 93 16" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.00038 4.65513C6.67217 3.67343 7.25476 3.18802 8.12698 3.45767C8.65479 3.62084 8.87145 4.29461 8.65479 5.14004C8.27383 6.62655 8.12698 7.47984 8.12698 8.4954C9.46683 5.68707 11.9437 1.95454 13.0171 0.69912C13.7591 -0.168604 14.8226 -0.0579465 15.2229 0.135603C15.8735 0.450089 15.9078 1.132 15.6833 1.78414C14.1298 6.29592 14.0757 8.70559 13.885 11.0466C14.8504 8.45028 16.0243 6.00806 17.4418 4.28804C18.039 3.46306 19.0858 3.20409 19.747 3.47209C20.4082 3.74008 20.5589 4.38125 20.4082 5.18473C20.0152 7.28 19.8202 8.26203 19.8202 9.07373C20.5381 8.52459 21.1098 8.06063 22.4168 7.95459C23.7238 7.84855 26.425 8.36891 26.425 8.36891C26.425 8.36891 24.8656 8.63385 24.042 8.83581C22.3536 9.24985 21.764 9.73406 20.671 11.3348C20.3534 11.7999 19.8202 12.157 19.2194 12.1116C18.6186 12.0662 18.2153 11.6883 18.039 11.1779C17.7941 10.4685 17.7213 9.40738 18.039 7.11111C16.4819 9.48025 15.6833 12.6984 14.6163 14.8325C14.3435 15.3782 13.8776 16 13.2462 16C12.6148 16 11.796 15.7551 11.6898 13.8792C11.4175 9.07373 12.3253 5.24971 12.3253 5.24971C10.5571 8.0533 9.99238 9.68752 9.37733 10.5397C8.76227 11.3918 8.16547 12.1298 7.43951 12.1116C6.71355 12.0934 6.29106 11.2818 6.1992 10.5397C6.10734 9.79758 6.0809 8.84978 6.34921 7.11111C5.65079 7.80952 5.03669 8.28252 3.93689 8.5787C2.8371 8.87488 1.52381 8.68637 0 8.36891C1.52381 8.36891 4.08814 7.44955 6.00038 4.65513Z"
        fill={logoFill}
      />
      <g fill={textFill}>
        <path d="M86.7099 5.52734L88.9253 11.8022L88.0171 13.5223L84.9348 5.52734H86.7099ZM85.1136 15.4763V14.0864H86.1182C86.3383 14.0864 86.531 14.0589 86.6961 14.0039C86.8612 13.958 87.008 13.8663 87.1364 13.7287C87.2741 13.5911 87.3887 13.3892 87.4805 13.1232L90.3014 5.52734H92.0352L88.7052 13.8388C88.4575 14.4626 88.1456 14.9167 87.7694 15.2011C87.3933 15.4854 86.9255 15.6276 86.3659 15.6276C86.1273 15.6276 85.9026 15.6139 85.6916 15.5863C85.4898 15.568 85.2971 15.5313 85.1136 15.4763Z" />
        <path d="M80.3681 5.52731H84.6339V6.93089H80.3681V5.52731ZM84.6477 2.15596V3.58706C84.5559 3.58706 84.4596 3.58706 84.3587 3.58706C84.2669 3.58706 84.1706 3.58706 84.0697 3.58706C83.6477 3.58706 83.3633 3.68798 83.2165 3.8898C83.0789 4.09162 83.0101 4.36225 83.0101 4.70167V12.3251H81.3451V4.70167C81.3451 4.07786 81.446 3.57789 81.6478 3.20177C81.8497 2.81647 82.134 2.53667 82.501 2.36237C82.8679 2.1789 83.2945 2.08716 83.7807 2.08716C83.9183 2.08716 84.0605 2.09174 84.2073 2.10092C84.3633 2.11009 84.51 2.12844 84.6477 2.15596Z" />
        <path d="M77.629 12.3249V5.5272H79.3078V12.3249H77.629ZM78.4547 4.1649C78.1703 4.1649 77.9272 4.06858 77.7254 3.87593C77.5327 3.6741 77.4364 3.431 77.4364 3.14661C77.4364 2.86223 77.5327 2.62371 77.7254 2.43106C77.9272 2.23841 78.1703 2.14209 78.4547 2.14209C78.7391 2.14209 78.9776 2.23841 79.1702 2.43106C79.3721 2.62371 79.473 2.86223 79.473 3.14661C79.473 3.431 79.3721 3.6741 79.1702 3.87593C78.9776 4.06858 78.7391 4.1649 78.4547 4.1649Z" />
        <path d="M71.2046 12.3251H69.5258V2.08716H71.2184V6.40799C71.4294 6.07773 71.7229 5.81628 72.0991 5.62363C72.4843 5.42181 72.9247 5.3209 73.4201 5.3209C74.2549 5.3209 74.8879 5.57318 75.319 6.07773C75.7594 6.58229 75.9795 7.27032 75.9795 8.14183V12.3251H74.3008V8.54088C74.3008 8.15559 74.2411 7.83909 74.1219 7.5914C74.0026 7.34371 73.8375 7.16024 73.6265 7.04098C73.4155 6.91254 73.1678 6.84833 72.8834 6.84833C72.5348 6.84833 72.2321 6.92631 71.9752 7.08226C71.7275 7.22904 71.5349 7.43545 71.3973 7.70149C71.2688 7.96753 71.2046 8.27026 71.2046 8.60969V12.3251Z" />
        <path d="M61.5612 8.91232C61.5612 8.21512 61.7033 7.59589 61.9877 7.05464C62.2813 6.51339 62.6804 6.09139 63.1849 5.78866C63.6986 5.47675 64.2903 5.3208 64.96 5.3208C65.8866 5.3208 66.6434 5.56391 67.2305 6.05011C67.8268 6.52715 68.1708 7.1739 68.2626 7.99036H66.5838C66.4829 7.60506 66.2948 7.31609 66.0196 7.12344C65.7536 6.92162 65.4187 6.82071 65.0151 6.82071C64.6665 6.82071 64.3546 6.90786 64.0793 7.08216C63.8133 7.24729 63.6069 7.4858 63.4601 7.79771C63.3133 8.10962 63.24 8.48115 63.24 8.91232C63.24 9.34349 63.3088 9.71502 63.4464 10.0269C63.584 10.3388 63.7812 10.5819 64.0381 10.7562C64.3041 10.9214 64.616 11.0039 64.9738 11.0039C65.3958 11.0039 65.7444 10.903 66.0196 10.7012C66.304 10.4994 66.492 10.2104 66.5838 9.83428H68.2763C68.2029 10.3664 68.0149 10.8342 67.7121 11.2379C67.4186 11.6323 67.0379 11.9442 66.57 12.1736C66.1022 12.3938 65.5701 12.5038 64.9738 12.5038C64.2858 12.5038 63.6849 12.3571 63.1711 12.0635C62.6574 11.7608 62.2584 11.3434 61.974 10.8113C61.6988 10.27 61.5612 9.63705 61.5612 8.91232Z" />
        <path d="M57.3417 12.5038C56.672 12.5038 56.0757 12.3525 55.5528 12.0497C55.0391 11.7378 54.6354 11.3158 54.3419 10.7838C54.0483 10.2425 53.9015 9.62328 53.9015 8.92608C53.9015 8.2197 54.0437 7.59589 54.3281 7.05464C54.6125 6.51339 55.0115 6.09139 55.5253 5.78866C56.039 5.47675 56.6261 5.3208 57.2866 5.3208C57.9747 5.3208 58.5664 5.46758 59.0617 5.76114C59.5571 6.04553 59.9378 6.44458 60.2039 6.95831C60.4791 7.47204 60.6167 8.08668 60.6167 8.80223V9.31138L54.7822 9.32514L54.8097 8.25181H58.9517C58.9517 7.78395 58.8003 7.40783 58.4976 7.12344C58.204 6.83905 57.8049 6.69686 57.3004 6.69686C56.9059 6.69686 56.5711 6.77943 56.2959 6.94455C56.0298 7.10968 55.828 7.35737 55.6904 7.68762C55.5528 8.01788 55.484 8.42152 55.484 8.89856C55.484 9.63246 55.6399 10.1875 55.9518 10.5636C56.2729 10.9397 56.7454 11.1278 57.3692 11.1278C57.8279 11.1278 58.204 11.0406 58.4976 10.8663C58.8003 10.692 58.9975 10.4443 59.0893 10.1233H60.6442C60.4974 10.8663 60.1305 11.4489 59.5434 11.8708C58.9654 12.2928 58.2315 12.5038 57.3417 12.5038Z" />
        <path d="M49.6819 12.5038C49.0122 12.5038 48.4159 12.3525 47.893 12.0497C47.3793 11.7378 46.9757 11.3158 46.6821 10.7838C46.3885 10.2425 46.2418 9.62328 46.2418 8.92608C46.2418 8.2197 46.384 7.59589 46.6683 7.05464C46.9527 6.51339 47.3518 6.09139 47.8655 5.78866C48.3792 5.47675 48.9664 5.3208 49.6269 5.3208C50.3149 5.3208 50.9066 5.46758 51.402 5.76114C51.8974 6.04553 52.2781 6.44458 52.5441 6.95831C52.8193 7.47204 52.9569 8.08668 52.9569 8.80223V9.31138L47.1224 9.32514L47.15 8.25181H51.2919C51.2919 7.78395 51.1405 7.40783 50.8378 7.12344C50.5442 6.83905 50.1452 6.69686 49.6406 6.69686C49.2462 6.69686 48.9113 6.77943 48.6361 6.94455C48.3701 7.10968 48.1682 7.35737 48.0306 7.68762C47.893 8.01788 47.8242 8.42152 47.8242 8.89856C47.8242 9.63246 47.9802 10.1875 48.2921 10.5636C48.6132 10.9397 49.0856 11.1278 49.7094 11.1278C50.1681 11.1278 50.5442 11.0406 50.8378 10.8663C51.1405 10.692 51.3378 10.4443 51.4295 10.1233H52.9845C52.8377 10.8663 52.4707 11.4489 51.8836 11.8708C51.3057 12.2928 50.5718 12.5038 49.6819 12.5038Z" />
        <path d="M38.121 15.4761V5.52721H39.676L39.786 6.55926C39.9879 6.16478 40.2998 5.86205 40.7218 5.65106C41.1437 5.43089 41.607 5.3208 42.1116 5.3208C42.7629 5.3208 43.3225 5.46758 43.7904 5.76114C44.2674 6.0547 44.6389 6.46293 44.905 6.98583C45.171 7.50874 45.304 8.12796 45.304 8.84352C45.304 9.54989 45.1802 10.1783 44.9325 10.7287C44.6848 11.2791 44.3224 11.7149 43.8454 12.036C43.3776 12.3479 42.8042 12.5038 42.1253 12.5038C41.6208 12.5038 41.1575 12.4075 40.7355 12.2149C40.3227 12.0222 40.0108 11.7562 39.7998 11.4168V15.4761H38.121ZM39.8136 8.92608C39.8136 9.32972 39.8915 9.6875 40.0475 9.99941C40.2034 10.3113 40.4236 10.559 40.708 10.7425C41.0016 10.9168 41.341 11.0039 41.7263 11.0039C42.1208 11.0039 42.4602 10.9168 42.7446 10.7425C43.029 10.559 43.2445 10.3113 43.3913 9.99941C43.5381 9.6875 43.6115 9.32972 43.6115 8.92608C43.6115 8.52244 43.5381 8.16466 43.3913 7.85275C43.2445 7.54085 43.029 7.29774 42.7446 7.12344C42.4602 6.93997 42.1208 6.84823 41.7263 6.84823C41.341 6.84823 41.0016 6.93538 40.708 7.10968C40.4236 7.28398 40.2034 7.52709 40.0475 7.83899C39.8915 8.1509 39.8136 8.51326 39.8136 8.92608Z" />
        <path d="M29.6474 5.07321C29.6474 4.48609 29.7987 3.96777 30.1015 3.51826C30.4042 3.06875 30.8216 2.72015 31.3537 2.47245C31.8949 2.21559 32.5188 2.08716 33.2251 2.08716C33.904 2.08716 34.4911 2.20642 34.9865 2.44493C35.491 2.68345 35.8809 3.02288 36.1561 3.46322C36.4405 3.90356 36.5919 4.42646 36.6102 5.03193H34.8489C34.8305 4.60076 34.6746 4.26592 34.381 4.0274C34.0875 3.77971 33.693 3.65587 33.1976 3.65587C32.6564 3.65587 32.2206 3.77971 31.8904 4.0274C31.5693 4.26592 31.4087 4.59618 31.4087 5.01817C31.4087 5.37594 31.5051 5.66033 31.6977 5.87133C31.8995 6.07315 32.2114 6.22451 32.6334 6.32543L34.2159 6.6832C35.0782 6.86668 35.7204 7.18317 36.1424 7.63268C36.5644 8.07302 36.7754 8.66932 36.7754 9.42156C36.7754 10.0362 36.624 10.5775 36.3213 11.0453C36.0185 11.5132 35.5874 11.8755 35.0278 12.1324C34.4773 12.3801 33.8306 12.5039 33.0875 12.5039C32.3811 12.5039 31.7619 12.3847 31.2298 12.1462C30.6978 11.8985 30.2804 11.5545 29.9776 11.1141C29.6841 10.6738 29.5281 10.1555 29.5098 9.55917H31.2711C31.2803 9.98116 31.4454 10.316 31.7665 10.5637C32.0968 10.8022 32.5417 10.9215 33.1013 10.9215C33.6884 10.9215 34.1517 10.8022 34.4911 10.5637C34.8397 10.316 35.014 9.99033 35.014 9.58669C35.014 9.23809 34.9223 8.96287 34.7388 8.76105C34.5553 8.55006 34.2572 8.40328 33.8444 8.32071L32.2481 7.96294C31.395 7.77946 30.7482 7.44921 30.3079 6.97217C29.8675 6.48597 29.6474 5.85298 29.6474 5.07321Z" />
      </g>
    </svg>
  );
};
