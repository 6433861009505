import CircularProgressInfinite from 'components/newListeningExperience/player/CircularProgressInfinite';
import { useRef, useState } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import { useOnClickOutside } from 'hooks/useClickOutside';
import { useTranslation } from 'hooks/useTypedTranslation';
import { DownloadMp3Progress } from 'modules/download-audio/components/DownloadProgress';
import { useExportingLibraryItemsStore } from 'modules/download-audio/exportStore';
import { cn } from 'utils/cn';

import NavIcon from '../NavIcon';

export const NavDownloadMp3PendingIcon: React.FC<{ size?: number; buttonClassName?: string; placeModal?: 'center' | 'right' }> = ({
  size = 20,
  buttonClassName,
  placeModal = 'center'
}) => {
  const { t } = useTranslation('common');
  const progressRef = useRef<HTMLDivElement>(null);
  const [progressOpened, setProgressOpened] = useState(false);

  const hasExportingItems = useExportingLibraryItemsStore(state => Object.keys(state.items).length > 0);
  const overallProgress = useExportingLibraryItemsStore(state => {
    if (!hasExportingItems) return 0;

    const allItemsProgress = Object.values(state.items).map(item => item.progress);
    return allItemsProgress.length ? allItemsProgress.reduce((acc, curr) => acc + curr, 0) / allItemsProgress.length : 0;
  });

  useOnClickOutside(progressRef, () => progressOpened && setProgressOpened(false));

  if (!hasExportingItems) return null;

  return (
    <div className="relative">
      <div data-tip data-tooltip-id="download-audio-v2">
        <NavIcon
          icon={<DownloadMp3PendingIcon progress={overallProgress} size={size} />}
          className={cn(progressOpened && 'bg-surface-accent', buttonClassName)}
          onClick={() => !progressOpened && setProgressOpened(true)}
        />
      </div>
      <ReactTooltip
        style={{
          borderRadius: '12px',
          backgroundColor: 'var(--colors-bg-secondary-inverted)',
          color: 'var(--colors-button-primary)',
          fontSize: '12px'
        }}
        className="canvas-tooltip"
        id="download-audio-v2"
        offset={6}
        hidden={progressOpened}
        place="bottom"
      >
        <div className="text-center">{t('Your downloading is in progress')}</div>
      </ReactTooltip>
      <DownloadMp3Progress ref={progressRef} hidden={!progressOpened} place={placeModal} />
    </div>
  );
};

export const CircularProgressBar: React.FC<{ progress?: number; size?: number; strokeWidth?: number; className?: string }> = ({
  progress = 0,
  size = 20,
  strokeWidth = 2,
  className
}) => {
  const radius = (size - strokeWidth) / 2;
  const circumference = 2 * Math.PI * radius;
  const strokeDashoffset = circumference - (progress / 100) * circumference;

  return (
    <span className={cn('inline-block box-border', className)} style={{ width: `${size}px`, height: `${size}px` }} role="progressbar">
      <svg className="w-full h-full" viewBox={`0 0 ${size} ${size}`}>
        <circle cx={size / 2} cy={size / 2} r={radius} className="stroke-border-primary-hovered" strokeWidth={strokeWidth} fill="none" />
        <circle
          className="stroke-current transition-all duration-500 ease-in-out"
          cx={size / 2}
          cy={size / 2}
          r={radius}
          fill="none"
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={strokeDashoffset}
          strokeLinecap="round"
          transform={`rotate(-90 ${size / 2} ${size / 2})`}
        />
      </svg>
    </span>
  );
};

export const DownloadMp3PendingIcon: React.FC<{ progress?: number; size?: number }> = ({ progress, size = 20 }) => {
  const arrowSize = Math.round(size * 0.6);

  return (
    <div className="flex flex-col relative items-center justify-center" style={{ width: `${size}px`, height: `${size}px` }}>
      <svg width={arrowSize} height={arrowSize} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M5.3637 10.4366C5.71517 10.7881 6.28502 10.7881 6.63649 10.4366L9.63649 7.43659C9.98797 7.08512 9.98797 6.51527 9.63649 6.1638C9.28502 5.81233 8.71517 5.81233 8.3637 6.1638L6.9001 7.6274V2.20793C6.9001 1.71088 6.49715 1.30793 6.0001 1.30793C5.50304 1.30793 5.1001 1.71088 5.1001 2.20793V7.6274L3.63649 6.1638C3.28502 5.81233 2.71517 5.81233 2.3637 6.1638C2.01223 6.51527 2.01223 7.08512 2.3637 7.43659L5.3637 10.4366Z"
          className="fill-icon-primary-cta-inverted"
        />
      </svg>
      {progress ? (
        <CircularProgressBar progress={progress} size={size} className="text-button-primary-cta absolute" />
      ) : (
        <CircularProgressInfinite className="text-button-primary-cta absolute" size={size} />
      )}
    </div>
  );
};
