import React from 'react';

import type { BlockHeadingMeta } from 'modules/sdk/lib/facade/legacyReader/classic/ClassicBlockInfo';

import { renderedContentStoreActions } from '../../../stores/renderedContentStore';
import type { ClassicBlockProps } from './';
import { ClassicElementRenderer } from './elements/ClassicElementRenderer';

export const ClassicBlockHeading = (props: ClassicBlockProps<BlockHeadingMeta>) => {
  const level = props.meta.data.level;
  let Heading: React.ElementType = 'h3';
  switch (level) {
    case 1:
      Heading = 'h1';
      break;
    case 2:
      Heading = 'h2';
      break;
    default:
      break;
  }

  return (
    <>
      <div className="clear-right" />
      <Heading
        className="whitespace-pre-wrap"
        ref={ref => renderedContentStoreActions.updateRenderedBlock(props.block.blockIndex, ref)}
        data-block-index={`${props.block.blockIndex}`}
      >
        {props.block.elements.map(element => (
          <ClassicElementRenderer key={element.elementId} element={element} />
        ))}
      </Heading>
    </>
  );
};
