import * as React from 'react';

function WhatsappIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_24000_4442)">
        <path
          d="M21.1751 11.567C21.0884 9.19404 20.0884 6.94632 18.3839 5.29314C16.6794 3.63995 14.4021 2.70914 12.0276 2.69507H11.9828C10.3837 2.69388 8.812 3.10903 7.42209 3.89966C6.0322 4.69029 4.87211 5.82916 4.05595 7.20421C3.2398 8.57927 2.79571 10.1431 2.76738 11.7419C2.73904 13.3407 3.12745 14.9192 3.89437 16.3224L3.08002 21.1954C3.07869 21.2054 3.07951 21.2155 3.08242 21.2252C3.08533 21.2349 3.09026 21.2438 3.09689 21.2514C3.10352 21.259 3.11168 21.2651 3.12085 21.2693C3.13002 21.2735 3.13998 21.2757 3.15006 21.2757H3.16407L7.98386 20.2036C9.2292 20.8015 10.593 21.1117 11.9744 21.1114C12.0622 21.1114 12.1499 21.1114 12.2377 21.1114C13.4529 21.0767 14.6492 20.8017 15.7574 20.3022C16.8658 19.8028 17.8642 19.0888 18.6951 18.2014C19.526 17.3141 20.173 16.271 20.5986 15.1322C21.0243 13.9935 21.2202 12.7818 21.1751 11.567ZM12.1919 19.5097C12.1191 19.5097 12.0463 19.5097 11.9744 19.5097C10.7535 19.5113 9.55008 19.2191 8.46575 18.658L8.21921 18.5291L4.9506 19.3015L5.55389 15.9946L5.41381 15.7573C4.74475 14.6167 4.38437 13.3214 4.36818 11.999C4.35198 10.6767 4.68052 9.37285 5.32143 8.21611C5.96235 7.05935 6.89353 6.0895 8.02327 5.40207C9.15302 4.71463 10.4423 4.33335 11.7643 4.29576C11.8377 4.29576 11.9115 4.29576 11.9856 4.29576C13.9814 4.30167 15.8951 5.0914 17.3142 6.4948C18.7333 7.89819 19.5444 9.80293 19.5725 11.7986C19.6007 13.7942 18.8438 15.7211 17.4648 17.164C16.0858 18.6069 14.1952 19.4503 12.2004 19.5126L12.1919 19.5097Z"
          fill="#25D366"
        />
        <path
          d="M8.77278 7.45788C8.65901 7.4601 8.54682 7.48513 8.44287 7.53149C8.33892 7.57785 8.24533 7.64459 8.16765 7.72777C7.94818 7.95284 7.33462 8.4945 7.29913 9.63198C7.26364 10.7694 8.05838 11.8948 8.16951 12.0526C8.28065 12.2104 9.68893 14.6675 12.0087 15.6639C13.3722 16.2514 13.9699 16.3522 14.3574 16.3522C14.5172 16.3522 14.6376 16.3354 14.7637 16.3279C15.1886 16.3018 16.1477 15.8106 16.3569 15.2764C16.5661 14.7422 16.5801 14.2753 16.525 14.1819C16.4699 14.0885 16.3186 14.0212 16.0908 13.9017C15.8629 13.7822 14.745 13.1845 14.5349 13.1004C14.457 13.0641 14.3731 13.0423 14.2874 13.036C14.2316 13.0389 14.1773 13.0554 14.1293 13.0841C14.0813 13.1127 14.041 13.1526 14.0119 13.2003C13.8252 13.4329 13.3965 13.9381 13.2526 14.0838C13.2212 14.12 13.1825 14.1492 13.1391 14.1695C13.0957 14.1898 13.0484 14.2007 13.0005 14.2015C12.9122 14.1976 12.8258 14.1743 12.7474 14.1333C12.0703 13.8458 11.4529 13.434 10.9273 12.9193C10.4361 12.4352 10.0194 11.881 9.69079 11.2747C9.56382 11.0393 9.69079 10.9179 9.80661 10.8078C9.92243 10.6975 10.0466 10.5453 10.1661 10.4136C10.2643 10.3011 10.346 10.1754 10.409 10.0401C10.4416 9.97727 10.458 9.90735 10.4569 9.83662C10.4558 9.76582 10.4371 9.69652 10.4024 9.63477C10.3474 9.51709 9.9355 8.36002 9.7422 7.89587C9.58531 7.49897 9.39852 7.4859 9.23507 7.47376C9.10061 7.46442 8.94651 7.45975 8.79242 7.45508H8.77278"
          fill="#25D366"
        />
      </g>
      <defs>
        <clipPath id="clip0_24000_4442">
          <rect width="19.2" height="19.2" fill="white" transform="translate(2.39999 2.3999)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default WhatsappIcon;
