import { IRecord, IUser, ItemActionType, RecordStatus, RecordType } from 'interfaces';
import React, { MouseEvent, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'store';
import { twMerge } from 'tailwind-merge';
import { carryParams, doItemsMatch } from 'utils';

import { ErrorSource, LibraryErrorOperation } from 'config/constants/errors';
import { useNavigate } from 'hooks/useNavigate';
import { useTranslation } from 'hooks/useTypedTranslation';
import { logError } from 'lib/observability';
import { deleteItem, isOnTrial, isPremium, isStripe, restoreItem } from 'lib/speechify';
import { selectors as librarySelectors } from 'store/library';
import { actions as toastActions } from 'store/toast';
import { logSegmentEvent, setTimeToPlay } from 'utils/analytics';
import { durationToHumanReadable } from 'utils/dates';

import Loading from '../../elements/Loading';
import ItemActionMenu from './ActionMenu';
import RowIcon from './components/RowIcon';

interface ItemCardProps {
  archivedItems?: IRecord[];
  className?: string;
  coverImagePath: string;
  id: string;
  isTrash?: boolean;
  moveTargets: string[];
  onAction?: (item: IRecord, actionType: ItemActionType, location?: string) => void;
  onRecentlyListened?: boolean;
  preventDrag?: boolean;
  progressPercent: number;
  recordType: string;
  selected?: boolean;
  selectionAvailable?: boolean;
  selectOnClick?: boolean;
  setArchivedItems?: (items: IRecord[]) => void;
  sourceURL: string;
  status: RecordStatus;
  timeAgo?: string;
  title: string;
  width?: string;
  wordCount: number;
  wpm?: number;
}

export const ItemCard: React.FC<ItemCardProps> = ({
  archivedItems,
  className,
  coverImagePath,
  id,
  isTrash,
  // ESLint: 'moveTargets' is assigned a value but never used
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  moveTargets = [],
  onAction = () => {},
  onRecentlyListened,
  // ESLint: 'preventDrag' is defined but never used
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  preventDrag,
  progressPercent,
  recordType,
  selected = false,
  selectionAvailable = false,
  selectOnClick = false,
  setArchivedItems,
  sourceURL,
  status,
  timeAgo,
  title,
  // ESLint: 'width' is defined but never used
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  width,
  wordCount,
  wpm = 200
}) => {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // state
  const [duration, setDuration] = useState<JSX.Element | null>(null);
  // @ts-expect-error TS(2322): Type 'IUser | null' is not assignable to type 'IUs... Remove this comment to see the full error message
  const currentUser = useSelector<IUser>(state => state.auth.user);
  const item = useSelector(state => librarySelectors.getById(id)(state), doItemsMatch);

  const isDownloadEnabled = isStripe(currentUser) && !isOnTrial(currentUser);
  const isPremiumUser = isPremium(currentUser) && !isOnTrial(currentUser);
  const downloadMp3 = useMemo(() => isDownloadEnabled && item.recordType === RecordType.PDF, [item, isDownloadEnabled]);
  const crossSellVoiceover = (!isPremium(currentUser) || (isPremium(currentUser) && isStripe(currentUser))) && !downloadMp3;

  const [isBeingDragged, setIsBeingDragged] = useState(false);

  const dragState = useSelector(state => ({ isDragging: state.mainPage.isDragging, activeDragTargetId: state.mainPage.activeDragTargetId }));

  useEffect(() => {
    if (wordCount && wpm) {
      setDuration(<>{durationToHumanReadable((wordCount / wpm) * 60)}</>);
    }
  }, [wordCount, wpm]);

  const restoreArchivedItem = async (itemId: string) => {
    try {
      // Remove from the state
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      const newArchivedItems = archivedItems.filter(item => item.id !== itemId);
      // @ts-expect-error TS(2722): Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      setArchivedItems(newArchivedItems);

      await restoreItem(itemId);

      dispatch(
        toastActions.add({
          title: '',
          description: t('Item restored')!,
          type: 'success'
        })
      );
    } catch (e) {
      // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
      logError(e, ErrorSource.LIBRARY, {
        context: {
          itemId,
          operation: LibraryErrorOperation.RESTORE_ITEM
        }
      });

      dispatch(
        toastActions.add({
          title: '',
          description: t('Error restoring the item')!,
          type: 'error'
        })
      );
    }
  };

  const deleteArchivedItem = async (itemId: string) => {
    try {
      // Remove from the state
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      const newArchivedItems = archivedItems.filter(item => item.id !== itemId);
      // @ts-expect-error TS(2722): Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      setArchivedItems(newArchivedItems);

      await deleteItem(itemId);

      dispatch(
        toastActions.add({
          title: '',
          description: t('Item deleted')!,
          type: 'success'
        })
      );
    } catch (e) {
      logError(e as Error, ErrorSource.LIBRARY, {
        context: {
          itemId,
          operation: LibraryErrorOperation.DELETE_ARCHIVED_ITEM
        }
      });

      dispatch(
        toastActions.add({
          title: '',
          description: t('Error deleting the item')!,
          type: 'error'
        })
      );
    }
  };

  const handleActionClick = async (actionType: ItemActionType) => {
    switch (actionType) {
      case ItemActionType.Open:
        setTimeToPlay('open-item', recordType);
        navigate(carryParams(`/item/${id}`));
        break;

      case ItemActionType.OpenOriginal:
        window.open(sourceURL, '_blank');
        break;

      case ItemActionType.DeleteArchivedItem:
        deleteArchivedItem(id);
        break;

      case ItemActionType.Restore:
        restoreArchivedItem(id);
        break;

      case ItemActionType.CrossSellVoiceOver:
        logSegmentEvent('web_app_voiceover_cross_sell_pressed', { source: 'card' });
        window.open('https://studio.speechify.com/?fromWebApp=true', '_blank');
        break;

      default:
        onAction(item, actionType);
    }
  };

  const handleItemClick = (e: MouseEvent) => {
    if (item.recordType === RecordType.WEB && status === RecordStatus.Processing) return;

    setTimeToPlay('open-item', recordType);

    if (e.metaKey) {
      window.open(carryParams(`/item/${id}`), '_blank');
    } else {
      navigate(carryParams(`/item/${id}`));
    }
  };

  const handleSelectionChange = () => {
    handleActionClick(selected ? ItemActionType.Unselect : ItemActionType.Select);
  };

  const handleSelectionAreaClick = (e: MouseEvent) => {
    e.stopPropagation();
    handleSelectionChange();
  };

  useEffect(() => {
    if (dragState.isDragging && dragState.activeDragTargetId === item.id) {
      setIsBeingDragged(true);
    } else {
      setIsBeingDragged(false);
    }
  }, [dragState, item]);

  return (
    <div
      className={twMerge(
        'bg-glass-200 h-full w-full cursor-pointer rounded-md hover:bg-[#E8EFFF] active:bg-[#D4DFF3] p-3 group',
        isBeingDragged && 'opacity-20',
        className
      )}
      onClick={handleItemClick}
    >
      <div className="flex flex-row justify-between pb-2 gap-2 ">
        <div className="flex-1 line-clamp-1 font-ABCDiatype font-medium text-sm leading-5 text-glass-700 break-all">{title}</div>

        <ItemActionMenu
          isCard={true}
          isTrash={isTrash}
          hasOriginal={!!sourceURL}
          onAction={handleActionClick}
          hasVoiceOverCrossSell={crossSellVoiceover}
          downloadMp3={downloadMp3}
          isPremium={isPremiumUser}
        />
      </div>
      <div className={twMerge('group relative col-span-1 flex select-none flex-col divide-y divide-gray-200 bg-white text-left rounded')}>
        <div
          className={twMerge('relative h-36 rounded border bg-white bg-opacity-75 bg-cover group-hover:bg-opacity-100')}
          style={coverImagePath ? { backgroundImage: `url(${coverImagePath})` } : {}}
        >
          {!coverImagePath && (
            <div className="flex items-center justify-center pt-10">
              <RowIcon kind={recordType as RecordType} className="h-14 w-14" />
            </div>
          )}
          {duration && (
            <div className="absolute bottom-3 right-2 flex items-center rounded-sm bg-glass-700 px-1 py-[3px] font-ABCDiatype text-xs text-glass-100 ">
              {duration}
            </div>
          )}
          <div className="absolute bottom-0 w-full rounded-b bg-gray-200">
            <div className={twMerge(progressPercent === 100 ? 'rounded-br' : '', 'h-1 rounded-bl bg-electric-350')} style={{ width: `${progressPercent}%` }}>
              &nbsp;
            </div>
          </div>
          {status === RecordStatus.Processing && (
            <div className="absolute left-0 top-0 flex h-full w-full items-center justify-center">
              <Loading />
            </div>
          )}

          {selectionAvailable && (
            <div
              className={twMerge(
                'absolute bottom-0 left-0 top-0 w-full rounded-t p-2 pt-1',
                'bg-glass-900 bg-opacity-50',
                'cursor-pointer',
                !selectOnClick ? 'hidden group-hover:block' : ''
              )}
              // @ts-expect-error TS(2322): Type '((e: MouseEvent<Element, MouseEvent>) => voi... Remove this comment to see the full error message
              onClick={selectOnClick ? handleSelectionAreaClick : null}
            >
              <input
                type="checkbox"
                className=" h-4 w-4 cursor-pointer focus:ring-0 border-2 border-glass-400 hover:bg-glass-200"
                checked={selected}
                onClick={e => e.stopPropagation()}
                onChange={handleSelectionChange}
              />
            </div>
          )}
        </div>
        {timeAgo && onRecentlyListened && (
          <div className="bg-glass-200 text-xs text-glass-500 font-normal font-ABCDiatype leading-4 pt-2 group-hover:bg-[#E8EFFF] group-active:bg-[#D4DFF3]">
            Listened {timeAgo}
          </div>
        )}
      </div>
    </div>
  );
};

export default ItemCard;
