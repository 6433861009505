import { getWebInstrumentations, initializeFaro } from '@grafana/faro-web-sdk';
import * as ct from 'countries-and-timezones';

import { ConsoleInstrumentation } from './ConsoleInstrumentation';

export const initFaro = (collectorUrl: string, isolate = false) => {
  const faro = initializeFaro({
    url: collectorUrl,
    instrumentations: [
      ...getWebInstrumentations({
        // we use custom console instrumentation to log errors as exceptions
        captureConsole: false,
        enablePerformanceInstrumentation: false
      }),
      new ConsoleInstrumentation()
    ],
    app: {
      name: 'Speechify Web App',
      version: process.env.version
    },
    isolate,
    beforeSend: event => {
      if (event.meta.session) {
        event.meta.session.id = 'anonymous';
      } else {
        event.meta.session = { id: 'anonymous' };
      }

      return event;
    }
  });

  // Wrapping it in a try/catch in case Intl is not available (old browsers)
  try {
    const session = faro.api.getSession();

    faro.api.setSession({
      ...session,
      attributes: {
        ...(session?.attributes || {}),
        country: ct.getCountryForTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone)?.id || '--'
      }
    });
  } catch (e) {
    console.error(e);
  }

  faro.api.pushLog(['Faro initialized']);

  return faro;
};
