import type { UnderlinedElementMeta } from 'modules/sdk/lib/facade/legacyReader/classic/ClassicBlockInfo';

import { ClassicElementRenderer } from '../ClassicElementRenderer';
import type { InternalClassicElementProps } from './';

export function ClassicElementUnderlined(props: InternalClassicElementProps<UnderlinedElementMeta>) {
  return (
    <span className="whitespace-pre-wrap underline" data-element-id={props.element.elementId}>
      {props.element.elements.map(element => (
        <ClassicElementRenderer key={element.elementId} element={element} />
      ))}
    </span>
  );
}
