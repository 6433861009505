import { createSlice } from '@reduxjs/toolkit';
import { IToast } from 'interfaces';
import { Action } from 'redux';

const name = 'toast';
const initialState: { id: number; toasts: IToast[] } = { id: 1, toasts: [] };

const { actions: generatedActions, reducer } = createSlice({
  name,
  initialState,
  reducers: {
    add: (state, action: Action & { payload: Omit<IToast, 'id'> & Partial<Pick<IToast, 'id'>> }) => {
      state.id += 1;
      state.toasts.push({ ...action.payload, id: action.payload.id ?? state.id });
    },
    remove: (state, action) => {
      state.toasts = state.toasts.filter(t => t.id !== action.payload);
    }
  }
});

const actions = { ...generatedActions, fetch };
const selectors = {};

export { actions, selectors };

export default reducer;
