import type {
  AiChatMessageRecord,
  AiChatRecord,
  CreateChatRequest,
  Feedback,
  GenerateSummaryOptions,
  ILiveResultSet,
  SummaryFormat,
  SummaryLength
} from '@speechifyinc/multiplatform-sdk';
import { type Result } from '@speechifyinc/multiplatform-sdk';
import { v4 } from 'uuid';

import { Nullable } from 'utils/types';

import { MultiplatformSDKInstance } from '../sdk';
import { SDKFacade } from './_base';

export type UserMessage = {
  id: string;
  body: string;
  loading: boolean;
  streaming?: boolean;
};

export type SummaryFeedbackType = 'positive' | 'negative';

export type SummaryFeedback = {
  type: SummaryFeedbackType;
  text: Nullable<string>;
};

export type ReplyMessage = {
  id: string;
  body: string;
  streaming?: boolean;
};

export type SummaryMessage = {
  id: string;
  body: string;
  length?: SummaryLengthTuple;
  mode?: SummaryFormatTuple;
  pageIndexes?: number[] | null;
  feedback: Nullable<SummaryFeedback>;
  setFeedback: (feedback: SummaryFeedback) => Promise<void>;
  streaming?: boolean;
};

export type ChatMessage =
  | {
      type: 'user';
      message: UserMessage;
    }
  | { type: 'summary'; message: SummaryMessage }
  | { type: 'reply'; message: ReplyMessage }
  | { type: 'summary_loading'; options: { mode: SummaryFormatTuple; length: SummaryLengthTuple; pageIndexes?: number[] }; message: { id: string } };

export type SummaryLengthTuple = 'short' | 'medium' | 'long';
export type SummaryFormatTuple = 'paragraph' | 'keypoints';

export class SDKAskAiFacade extends SDKFacade {
  private static _singleton: SDKAskAiFacade;
  private chat: AiChatRecord | null = null;
  private messages: AiChatMessageRecord[] = [];
  private messageResultSet: ILiveResultSet<AiChatMessageRecord> | null = null;
  private chatChangeListener: ((chat: AiChatRecord) => void) | null = null;
  private messagesChangeListener: ((messages: ChatMessage[]) => void) | null = null;
  private docId: string = '';

  constructor(sdk: MultiplatformSDKInstance) {
    super(sdk);
    SDKAskAiFacade._singleton = this;
  }

  static override get singleton(): SDKAskAiFacade {
    return SDKAskAiFacade._singleton;
  }

  private convertSummaryFormatToTuple(summaryFormat: SummaryFormat): SummaryFormatTuple {
    const { SummaryFormat } = this.sdk.sdkModule;
    switch (summaryFormat) {
      case SummaryFormat.Paragraph:
        return 'paragraph';
      case SummaryFormat.Outline:
        return 'keypoints';
      default:
        throw new Error('Invalid SummaryFormat');
    }
  }

  private convertSummaryLengthToTuple(summaryLength: SummaryLength): SummaryLengthTuple {
    const { SummaryLength } = this.sdk.sdkModule;
    switch (summaryLength) {
      case SummaryLength.Short:
        return 'short';
      case SummaryLength.Medium:
        return 'medium';
      case SummaryLength.Long:
        return 'long';
      default:
        throw new Error('Invalid SummaryLength');
    }
  }

  convertGenerateSummaryOptions(
    options: Nullable<GenerateSummaryOptions>
  ): { length: SummaryLengthTuple; mode: SummaryFormatTuple; pageIndexes: number[] | null } | null {
    const { GenerateSummaryOptions } = this.sdk.sdkModule;
    if (!options) return null;
    if (options instanceof GenerateSummaryOptions.ForDocument) {
      return { length: this.convertSummaryLengthToTuple(options.length), mode: this.convertSummaryFormatToTuple(options.format), pageIndexes: null };
    }

    if (options instanceof GenerateSummaryOptions.ForPages) {
      return {
        length: this.convertSummaryLengthToTuple(options.length),
        mode: this.convertSummaryFormatToTuple(options.format),
        pageIndexes: options.pageIndexes
      };
    }

    throw new Error('Unknown summary option', { cause: options });
  }

  convertFeedback(sdkFeedback: Feedback): SummaryFeedback {
    const { Feedback } = this.sdk.sdkModule;

    if (sdkFeedback instanceof Feedback.Positive) {
      return {
        type: 'positive',
        text: sdkFeedback.text
      };
    }

    if (sdkFeedback instanceof Feedback.Negative) {
      return {
        type: 'negative',
        text: sdkFeedback.text
      };
    }

    throw new Error('Unknown summary feedback type', sdkFeedback);
  }

  convertSummaryFeedback(summaryFeedback: SummaryFeedback): Feedback {
    const { Feedback } = this.sdk.sdkModule;

    switch (summaryFeedback.type) {
      case 'positive':
        return new Feedback.Positive(summaryFeedback.text);
      case 'negative':
        return new Feedback.Negative(summaryFeedback.text);
      default:
        throw new Error('Unknown feedback type', summaryFeedback.type);
    }
  }

  mapAiChatMessageRecordToChatMessage(aiChatMessage: AiChatMessageRecord): ChatMessage | null {
    const { AiChatMessage, PromptDeliveryStatus } = this.sdk.sdkModule;
    const { promisify } = this.sdk;

    if (aiChatMessage.message instanceof AiChatMessage.Response.Text)
      return {
        type: 'reply',
        message: {
          body: aiChatMessage.message.body,
          id: aiChatMessage.id
        }
      };

    if (aiChatMessage.message instanceof AiChatMessage.Response.Summary) {
      const convertedOptions = this.convertGenerateSummaryOptions(aiChatMessage.message.options);

      const setFeedback = promisify(aiChatMessage.message.setFeedback.bind(aiChatMessage.message));
      return {
        type: 'summary',
        message: {
          body: aiChatMessage.message.body,
          id: aiChatMessage.id,
          length: convertedOptions?.length,
          mode: convertedOptions?.mode,
          pageIndexes: convertedOptions?.pageIndexes,
          feedback: aiChatMessage.message.feedback ? this.convertFeedback(aiChatMessage.message.feedback) : null,
          setFeedback: (feedback: SummaryFeedback) => {
            const sdkFeedback = this.convertSummaryFeedback(feedback);
            return setFeedback(sdkFeedback);
          }
        }
      };
    }

    if (aiChatMessage.message instanceof AiChatMessage.Prompt.Text)
      return {
        type: 'user',
        message: {
          id: aiChatMessage.id,
          body: aiChatMessage.message.body,
          loading:
            aiChatMessage.message.promptDeliveryStatus !== PromptDeliveryStatus.Delivered &&
            aiChatMessage.message.promptDeliveryStatus !== PromptDeliveryStatus.Failed
        }
      };

    if (
      aiChatMessage.message instanceof AiChatMessage.Prompt.GenerateSummaryForPages &&
      aiChatMessage.message.promptDeliveryStatus === PromptDeliveryStatus.Pending
    ) {
      return {
        type: 'summary_loading',
        options: {
          length: this.convertSummaryLengthToTuple(aiChatMessage.message.options.length),
          mode: this.convertSummaryFormatToTuple(aiChatMessage.message.options.format),
          pageIndexes: aiChatMessage.message.options.pageIndexes
        },
        message: {
          id: v4()
        }
      };
    }

    if (
      aiChatMessage.message instanceof AiChatMessage.Prompt.GenerateSummaryForDocument &&
      aiChatMessage.message.promptDeliveryStatus === PromptDeliveryStatus.Pending
    ) {
      return {
        type: 'summary_loading',
        options: {
          length: this.convertSummaryLengthToTuple(aiChatMessage.message.options.length),
          mode: this.convertSummaryFormatToTuple(aiChatMessage.message.options.format)
        },
        message: { id: v4() }
      };
    }

    return null;
  }

  private notifyListeners() {
    if (this.chatChangeListener && this.chat) {
      this.chatChangeListener(this.chat);
    }
    if (this.messagesChangeListener) {
      this.messagesChangeListener(this.messages.map(this.mapAiChatMessageRecordToChatMessage.bind(this)).filter(Boolean) as ChatMessage[]);
    }
  }

  async createChat(request: CreateChatRequest) {
    const { promisify } = this.sdk;
    const { aiChatService } = this.sdk.client;

    try {
      const response: AiChatRecord = await promisify(aiChatService.createChat.bind(aiChatService))(request);
      this.chat = response;

      const newMessagesResultSet = await promisify(response.aiChat.getMessages.bind(response.aiChat))();
      this.messageResultSet = newMessagesResultSet;

      const messages: Array<AiChatMessageRecord> = await promisify(newMessagesResultSet.getItems.bind(newMessagesResultSet))();
      this.messages = messages;

      this.setupMessagesChangeListener();
      this.setupChatChangeListener();

      this.notifyListeners();

      return response;
    } catch (error) {
      console.error('Failed to create chat:', error);
      throw error;
    }
  }

  private convertSummaryFormat(summaryFormat: SummaryFormatTuple) {
    const { SummaryFormat } = this.sdk.sdkModule;
    switch (summaryFormat) {
      case 'paragraph':
        return SummaryFormat.Paragraph;
      case 'keypoints':
        return SummaryFormat.Outline;
    }
  }

  private convertSummaryLength(summaryLength: SummaryLengthTuple) {
    const { SummaryLength } = this.sdk.sdkModule;
    switch (summaryLength) {
      case 'short':
        return SummaryLength.Short;
      case 'medium':
        return SummaryLength.Medium;
      case 'long':
        return SummaryLength.Long;
    }
  }

  async summarizePages(pageNumbersForSummary: number[], summaryLength: SummaryLengthTuple, summaryFormat: SummaryFormatTuple) {
    const { promisify } = this.sdk;
    const { libraryService } = this.sdk.client;
    const { CreateChatRequest, Result, ChatSubject, DocumentType, AiChatMessage, GenerateSummaryOptions } = this.sdk.sdkModule;

    try {
      const libraryItem = await promisify(libraryService.getItem.bind(libraryService))(this.docId);
      const documentType = ChatSubject.Companion.getDocumentType(libraryItem);

      if (pageNumbersForSummary.length === 0 || documentType !== DocumentType.PDF) {
        console.log('Not a PDF document or no pages selected');
        return;
      }

      const summaryLengthConverted = this.convertSummaryLength(summaryLength);
      const summaryFormatConverted = this.convertSummaryFormat(summaryFormat);

      if (this.chat) {
        const summaryOptions = new GenerateSummaryOptions.ForPages(pageNumbersForSummary, summaryLengthConverted, summaryFormatConverted);
        const prompt = new AiChatMessage.Prompt.GenerateSummaryForPages(summaryOptions);
        const response: unknown = await promisify(this.chat.aiChat.sendPrompt.bind(this.chat.aiChat))(prompt);

        if (response instanceof Result.Failure) {
          throw (response as Result.Failure).error;
        }
      } else {
        const request = CreateChatRequest.Companion.createDocumentPageSummaryChatRequest(
          pageNumbersForSummary,
          libraryItem,
          summaryLengthConverted,
          summaryFormatConverted
        );
        await this.createChat(request);
      }

      this.notifyListeners();
    } catch (error) {
      console.error('Failed to summarize pages:', error);
      throw error;
    }
  }

  async summarizeDocument(summaryLength: SummaryLengthTuple, summaryFormat: SummaryFormatTuple) {
    const { promisify } = this.sdk;
    const { libraryService } = this.sdk.client;
    const { CreateChatRequest, Result, AiChatMessage, GenerateSummaryOptions } = this.sdk.sdkModule;

    try {
      const summaryLengthConverted = this.convertSummaryLength(summaryLength);
      const summaryFormatConverted = this.convertSummaryFormat(summaryFormat);

      if (!this.chat) {
        const libraryItem = await promisify(libraryService.getItem.bind(libraryService))(this.docId);
        const request = CreateChatRequest.Companion.createDocumentSummaryChatRequest(libraryItem, summaryLengthConverted, summaryFormatConverted);
        await this.createChat(request);
      } else {
        const summaryOptions = new GenerateSummaryOptions.ForDocument(summaryLengthConverted, summaryFormatConverted);
        const prompt = new AiChatMessage.Prompt.GenerateSummaryForDocument(summaryOptions);

        const response: unknown = await promisify(this.chat.aiChat.sendPrompt.bind(this.chat.aiChat))(prompt);
        if (response instanceof Result.Failure) {
          throw (response as Result.Failure).error;
        }
      }

      this.notifyListeners();
    } catch (error) {
      console.error('Failed to summarize document:', error);
      throw error;
    }
  }

  regenerateMessage = async () => {
    const { promisify } = this.sdk;
    if (this.chat) {
      await promisify(this.chat.aiChat.regenerateLastMessage.bind(this.chat.aiChat))();
    }
  };

  deleteChat = async () => {
    const { promisify } = this.sdk;
    if (this.chat) {
      await promisify(this.chat.delete.bind(this.chat))();

      // initialize again with empty set
      this.chat = null;
      this.messages = [];
      await this.initializeChatForDocument(this.docId);
    }
  };

  async initializeChatForDocument(docId: string) {
    this.docId = docId;
    const { promisify } = this.sdk;
    const { aiChatService } = this.sdk.client;
    const chats = await promisify(aiChatService.getChatForDocument.bind(aiChatService))(docId);
    if (chats) {
      this.chat = chats;
      const messagesResultSet = await promisify(chats.aiChat.getMessages.bind(chats.aiChat))();
      this.messageResultSet = messagesResultSet;
      const messages = await promisify(messagesResultSet.getItems.bind(messagesResultSet))();
      this.messages = messages;

      this.setupChatChangeListener();
      this.setupMessagesChangeListener();

      this.notifyListeners();
    }
  }

  private setupChatChangeListener() {
    if (this.chat) {
      this.chat.addChangeListener((newChat: AiChatRecord) => {
        this.chat = newChat;
        if (this.chatChangeListener) {
          this.chatChangeListener(newChat);
        }
      });
    }
  }

  private setupMessagesChangeListener() {
    const { Result } = this.sdk.sdkModule;
    if (this.messageResultSet) {
      this.messageResultSet.addChangeListener(async changeResult => {
        const { promisify } = this.sdk;
        if (changeResult instanceof Result.Success) {
          const newResultSet = changeResult as Result.Success<ILiveResultSet<AiChatMessageRecord>>;
          const newMessages = await promisify(newResultSet.value.getItems.bind(newResultSet.value))();
          this.messages = newMessages;
          if (this.messagesChangeListener) {
            this.messagesChangeListener(newMessages.map(this.mapAiChatMessageRecordToChatMessage.bind(this)).filter(Boolean) as ChatMessage[]);
          }
        } else {
          console.error('Failed to get updated messages:', (changeResult as Result.Failure).error);
        }
      });
    }
  }

  setChatChangeListener(listener: (chat: AiChatRecord) => void) {
    this.chatChangeListener = listener;
    if (this.chat) {
      listener(this.chat);
    }
  }
  setMessagesChangeListener(listener: (messages: ChatMessage[]) => void) {
    this.messagesChangeListener = listener;
    if (this.messages) {
      listener(this.messages.map(this.mapAiChatMessageRecordToChatMessage.bind(this)).filter(Boolean) as ChatMessage[]);
    }
  }

  async sendMessage(message: string) {
    const { AiChatMessage, CreateChatRequest, Result } = this.sdk.sdkModule;
    const { promisify } = this.sdk;
    const { libraryService } = this.sdk.client;

    try {
      if (!this.chat) {
        const libraryItem = await promisify(libraryService.getItem.bind(libraryService))(this.docId);
        const request = CreateChatRequest.Companion.createDocumentChatRequest(libraryItem, new AiChatMessage.Prompt.Text(message));
        await this.createChat(request);
      } else {
        const response: unknown = await promisify(this.chat.aiChat.sendPrompt.bind(this.chat.aiChat))(new AiChatMessage.Prompt.Text(message));
        if (response instanceof Result.Failure) {
          throw (response as Result.Failure).error;
        }
      }

      this.notifyListeners();
    } catch (error) {
      console.error('Failed to send message:', error);
      throw error;
    }
  }

  getTransformedMessages() {
    return this.messages.map(this.mapAiChatMessageRecordToChatMessage.bind(this));
  }

  destroy() {
    this.chatChangeListener = null;
    this.messagesChangeListener = null;
    this.chat = null;
    this.messages = [];
    this.messageResultSet = null;
    this.docId = '';
  }
}
