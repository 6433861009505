import packageJson from '../../../package.json';

export const isDevPreviewDomain =
  typeof window !== 'undefined' ? window?.location?.origin?.includes('preview.speechify.dev') : process.env.VERCEL_ENV === 'preview';

export const PSPDFKIT_KEY = isDevPreviewDomain ? process.env.NEXT_PUBLIC_PSPKIT_DEV_PREVIEW : process.env.NEXT_PUBLIC_PSPKIT;

export const PSPDFKIT_VERSION = packageJson.dependencies['pspdfkit'];

export const CDN_PSPDFKIT_BASE_URL = `https://cdn.speechify.com/web/pspdfkit/${PSPDFKIT_VERSION}/`;
