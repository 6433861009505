import { logSegmentEvent } from 'utils/analytics';

import { AnalyticsEventKey, AnalyticsEventProperty } from './analyticsTypes';

export const logAnalyticsEvent = <Key extends AnalyticsEventKey>(analyticsKey: Key, properties?: AnalyticsEventProperty<Key>) => {
  if (process.env.NODE_ENV === 'development') {
    console.debug('Logging analytics event:', analyticsKey, properties);
  }
  if (process.env.NEXT_PUBLIC_IS_STORYBOOK == 'true') {
    return;
  }
  return logSegmentEvent(analyticsKey, properties);
};

export { AnalyticsEventKey };
