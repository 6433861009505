import * as React from 'react';

function XTwitterIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="text-bg-primary-inverted" {...props}>
      <path
        d="M17.1761 3.84229H19.9362L13.9061 10.7342L21 20.1126H15.4456L11.0951 14.4247L6.11723 20.1126H3.35544L9.80517 12.7409L3 3.84229H8.69545L12.6279 9.04128L17.1761 3.84229ZM16.2073 18.4606H17.7368L7.86441 5.40758H6.2232L16.2073 18.4606Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default XTwitterIcon;
