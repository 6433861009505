import type { AnchorElementMeta } from 'modules/sdk/lib/facade/legacyReader/classic/ClassicBlockInfo';

import { ClassicElementRenderer } from '../ClassicElementRenderer';
import type { InternalClassicElementProps } from './';

export function ClassicElementAnchor(props: InternalClassicElementProps<AnchorElementMeta>) {
  return (
    <a
      className="whitespace-pre-wrap"
      dir="auto"
      data-element-id={props.element.elementId}
      href={props.meta.data.url}
      target="_blank"
      rel="noreferrer noopener"
    >
      {props.element.elements.map(element => (
        <ClassicElementRenderer key={element.elementId} element={element} />
      ))}
    </a>
  );
}
