import { MultiplatformSDKInstance } from '../../sdk';
import { SDKFacade } from '../_base';

export class SDKSingletonClassicReaderFacade extends SDKFacade {
  private static _singleton: SDKSingletonClassicReaderFacade;

  constructor(sdk: MultiplatformSDKInstance) {
    super(sdk);
    SDKSingletonClassicReaderFacade._singleton = this;
  }

  static override get singleton(): SDKSingletonClassicReaderFacade {
    return SDKSingletonClassicReaderFacade._singleton;
  }

  get FormattingTree() {
    return this.sdk.sdkModule.FormattingTree;
  }

  get Formatting() {
    return this.sdk.sdkModule.Formatting;
  }

  get ClassicBlock() {
    return this.sdk.sdkModule.ClassicBlock;
  }

  get Link() {
    return this.sdk.sdkModule.Link;
  }

  get ContextualAction() {
    return this.sdk.sdkModule.ContextualAction;
  }
}
