import type { ListElementMeta } from 'modules/sdk/lib/facade/legacyReader/classic/ClassicBlockInfo';

import { ClassicElementRenderer } from '../ClassicElementRenderer';
import type { InternalClassicElementProps } from './';

export function ClassicElementList(props: InternalClassicElementProps<ListElementMeta>) {
  return (
    <ul style={{ listStyleType: props.meta.data.listStyleType }} data-element-id={props.element.elementId}>
      {props.element.elements.map(element => (
        <ClassicElementRenderer key={element.elementId} element={element} />
      ))}
    </ul>
  );
}
