import type { PageControlsProps } from 'components/newListeningExperience/PageControls';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { PDFOverlayInfo } from 'modules/sdk/lib';

import { usePlaybackStore } from '../stores/playback/playbackStore';

export type PDFPageInfo = {
  currentPageIndex: number;
  pageCount: number;
};

export function usePageIndicator(): PageControlsProps {
  const currentOverlayInfo = usePlaybackStore(s => s.currentOverlayInfo);
  const currentPspdfKitFacade = usePlaybackStore(s => s.currentPspdfKitFacade);
  const isPdf = useMemo(() => currentOverlayInfo instanceof PDFOverlayInfo, [currentOverlayInfo]);

  const [currentPageIndex, setCurrentPageIndex] = useState(currentPspdfKitFacade?.instance.viewState.currentPageIndex ?? 1);
  const pageCount = useMemo(() => (currentOverlayInfo instanceof PDFOverlayInfo ? currentOverlayInfo.pageCount : 0), [currentOverlayInfo]);

  const onPageIndexChange = useCallback(
    (newPageIndex: number) => {
      currentPspdfKitFacade?.goToPage(newPageIndex - 1); // 0-based index
    },
    [currentPspdfKitFacade]
  );

  useEffect(() => {
    if (!isPdf) return;
    return currentPspdfKitFacade?.addPageIndexListener(pageIndex => {
      setCurrentPageIndex(pageIndex + 1); // 1-based index
    });
  }, [isPdf, currentPspdfKitFacade]);

  if (!isPdf) {
    return { isPdf: false };
  }

  return {
    isPdf: true,
    currentPageIndex,
    pageCount,
    onPageIndexChange
  };
}
