import React from 'react';

import type { BlockListMeta } from 'modules/sdk/lib/facade/legacyReader/classic/ClassicBlockInfo';

import { renderedContentStoreActions } from '../../../stores/renderedContentStore';
import { ClassicBlockRenderer } from '../ClassicBlockRenderer';
import type { ClassicBlockProps } from './';

export const ClassicBlockList = (props: ClassicBlockProps<BlockListMeta>) => {
  const { isNumbered, items } = props.meta.data;

  const List: React.ElementType = isNumbered ? 'ol' : 'ul';

  return (
    <div ref={ref => renderedContentStoreActions.updateRenderedBlock(props.block.blockIndex, ref)} data-block-index={`${props.block.blockIndex}`}>
      <List>
        {items.map(item => (
          <ClassicBlockRenderer key={item.blockIndex} block={item} />
        ))}
      </List>
    </div>
  );
};
