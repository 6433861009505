import { createNonPersistentStore } from 'lib/zustand';

import { ThumbnailImage } from '../hooks/useLoadThumbnails';

export const useThumbnailsCache = createNonPersistentStore<{ thumbnailsCache: Map<string, ThumbnailImage[]> }>(
  () => ({ thumbnailsCache: new Map<string, ThumbnailImage[]>() }),
  {
    isListeningScreenStore: true
  }
);

export const setLoadingThumbnails = (itemId: string, count: number) => {
  const { thumbnailsCache } = useThumbnailsCache.getState();

  thumbnailsCache.set(
    itemId,
    Array.from({ length: count }, () => null)
  );

  useThumbnailsCache.setState({ thumbnailsCache });
};

export const addThumbnailToCache = (itemId: string, index: number, thumbnail: ThumbnailImage) => {
  const { thumbnailsCache } = useThumbnailsCache.getState();
  const cachedImages = thumbnailsCache.get(itemId) || [];

  cachedImages[index] = thumbnail;

  thumbnailsCache.set(itemId, cachedImages);

  useThumbnailsCache.setState({ thumbnailsCache });
};
