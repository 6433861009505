import type { LibraryItem as SDKLibraryItem } from '@speechifyinc/multiplatform-sdk';
import { WebAppImportFlow, WebAppImportType } from 'components/library/import/constants';

import { ErrorSource } from 'config/constants/errors';
import { logError } from 'lib/observability';

import { SDKContentSubType } from '../analytics/contentSubType';
import type { MultiplatformSDKInstance } from '../sdk';
import { SDKFacade } from './_base';
import { ContentMetaType, ListenableContent } from './listenableContent';

function unreachable(value: never) {
  return value;
}

export class SDKLibraryFacade extends SDKFacade {
  private static _singleton: SDKLibraryFacade;
  constructor(sdk: MultiplatformSDKInstance) {
    super(sdk);
    SDKLibraryFacade._singleton = this;
  }

  static override get singleton(): SDKLibraryFacade {
    return SDKLibraryFacade._singleton;
  }

  public getItem = this.sdk.promisify(this.sdk.client.libraryService.getItem.bind(this.sdk.client.libraryService));

  public getItemAndWaitUntilListenable = async (itemId: string): Promise<SDKLibraryItem> => {
    const SDKContent = this.sdk.sdkModule.LibraryItem.Content;

    const item = await this.getItem(itemId);
    if (item instanceof SDKContent) {
      if (!item.isInListenableState) {
        await new Promise<void>(resolve => {
          const interval = setInterval(async () => {
            const updatedItem = (await this.getItem(itemId)) as SDKLibraryItem.Content;
            if (updatedItem.isInListenableState) {
              clearInterval(interval);
              resolve();
            }
          }, 1000);
        });
      }
      return item;
    }
    return item;
  };

  // ContentSubType is actually a string, but for easier and consolidated tracking we created enums here.
  public webAppImportFlowToSDKContentSubType = (importFlow: WebAppImportType) => {
    switch (importFlow) {
      case WebAppImportType.FILE_UPLOAD:
        return SDKContentSubType.LOCAL_FILES;
      case WebAppImportType.WEB_LINK:
        return SDKContentSubType.WEB_LINK;
      case WebAppImportType.TEXT:
        return SDKContentSubType.TYPE_OR_PASTE_TEXT;
      case WebAppImportType.GOOGLE_DRIVE:
        return SDKContentSubType.GOOGLE_DRIVE;
      case WebAppImportType.DROPBOX:
        return SDKContentSubType.DROPBOX;
      case WebAppImportType.ONE_DRIVE:
        return SDKContentSubType.ONE_DRIVE;
      case WebAppImportType.CANVAS:
        return SDKContentSubType.CANVAS;
      case WebAppImportType.AI_STORY:
        return SDKContentSubType.AI_STORY;
      default:
        return unreachable(importFlow);
    }
  };

  public listenableContentToSDKContentType = (listenableContent: ListenableContent) => {
    const metaType = listenableContent.metaType;
    const { ContentType } = this.sdk.sdkModule;
    switch (metaType) {
      case ContentMetaType.PDF:
        return ContentType.PDF;
      case ContentMetaType.DOCX:
        return ContentType.DOCX;
      case ContentMetaType.TXT:
        return ContentType.TXT;
      case ContentMetaType.HTML:
        return ContentType.HTML;
      case ContentMetaType.EPUB:
        return ContentType.EPUB;
      case ContentMetaType.SCAN:
        return ContentType.SCAN;
      case ContentMetaType.UNKNOWN:
        logError(
          new Error(
            `webAppImportFlowToSDKContentType error: Falling back to SDK ContentType.TXT due to unknown meta type for listenableContent: ${listenableContent.constructor.name}`
          ),
          ErrorSource.FILE_IMPORT
        );
        return ContentType.TXT;
      default:
        return unreachable(metaType);
    }
  };

  public webAppImportFlowToSDKImportFlow = (webAppImportFlow: WebAppImportFlow) => {
    const { ImportFlow } = this.sdk.sdkModule;
    switch (webAppImportFlow) {
      case WebAppImportFlow.PLUS_BUTTON_MODAL:
        return ImportFlow.PlusButtonModal;
      case WebAppImportFlow.PLUS_BUTTON_MODAL_DRAG_AND_DROP:
        return ImportFlow.PlusButtonModalDragAndDrop;
      case WebAppImportFlow.PLUS_BUTTON_MODAL_SELECT_FILES:
        return ImportFlow.PlusButtonModalSelectFiles;
      case WebAppImportFlow.LIBRARY_DRAG_AND_DROP:
        return ImportFlow.LibraryDragAndDrop;
      case WebAppImportFlow.LIBRARY_SELECT_FILES:
        return ImportFlow.LibrarySelectFiles;
      case WebAppImportFlow.LIBRARY_SCREEN_SUGGESTIONS:
        return ImportFlow.LibraryScreenSuggestions;
      case WebAppImportFlow.PILL_PLAYER:
        return ImportFlow.PillPlayer;
      case WebAppImportFlow.LISTENING_SCREEN:
        return ImportFlow.ListeningScreen;
      default:
        return unreachable(webAppImportFlow);
    }
  };
}
