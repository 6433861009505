import { v4 } from 'uuid';

import { getSDK } from 'modules/sdk/lib';
import { SummaryFeedback, SummaryFormatTuple, SummaryLengthTuple } from 'modules/sdk/lib/facade/askAi';

import { convertFromApiLength, convertFromApiSummaryFormat, validateChatRequest, validateChatResponse } from './helpers';
import { APIMessage, ChatMessage, ChatRecord, ChatResponse, CreateChatRequest, SummaryFormatType, SummaryLengthType, whileCondition } from './types';

export class APIAskAiFacade {
  private static instance: APIAskAiFacade | null = null;

  private readonly apiBaseUrl: string;
  private chat: ChatRecord | null = null;
  private chatChangeListener: ((chat: ChatRecord) => void) | null = null;
  private docId: string = '';
  private readonly idToken: string;
  private messages: ChatMessage[] = [];
  private messagesChangeListener: ((messages: ChatMessage[]) => void) | null = null;

  constructor(idToken: string, apiBaseUrl: string = process.env.NEXT_PUBLIC_LLM_API_URL!) {
    this.apiBaseUrl = apiBaseUrl;
    this.idToken = idToken;
  }

  public static getInstance(idToken: string, apiBaseUrl?: string): APIAskAiFacade {
    if (!APIAskAiFacade.instance) {
      APIAskAiFacade.instance = new APIAskAiFacade(idToken, apiBaseUrl);
    }

    return APIAskAiFacade.instance;
  }

  async createChat(): Promise<ChatResponse> {
    if (!this.docId) {
      throw new Error('No document ID provided');
    }

    const sdkInstance = await getSDK();

    const { client: sdkClient, promisify, sdkModule } = sdkInstance.sdk;
    const { libraryService } = sdkClient;
    const { ChatSubject } = sdkModule;

    const libraryItem = await promisify(libraryService.getItem.bind(libraryService))(this.docId);
    const documentType = ChatSubject.Companion.getDocumentType(libraryItem);
    const documentTypeName = documentType.name;

    try {
      const chatRequest: CreateChatRequest = {
        title: 'Document Chat',
        source: {
          type: 'libraryItem',
          id: this.docId,
          documentType: documentTypeName
        }
      };

      validateChatRequest(chatRequest);

      const response = await this.fetchWithAuth('/v2/chats', {
        method: 'POST',
        body: JSON.stringify(chatRequest)
      });

      const chatResponse: ChatResponse = await response?.json();

      validateChatResponse(chatResponse);

      this.chat = {
        id: chatResponse.chatId,
        subject: chatResponse.subject
          ? {
              type: chatResponse.subject.type,
              source: {
                type: chatResponse.subject.source.type,
                id: chatResponse.subject.source.id,
                documentType: documentTypeName
              }
            }
          : {
              type: 'default',
              source: {
                type: 'libraryItem',
                id: this.docId,
                documentType: documentTypeName
              }
            },
        createdAt: chatResponse.createdAt || '',
        lastActiveAt: chatResponse.lastActiveAt || ''
      };

      this.notifyListeners();

      return chatResponse;
    } catch (error) {
      console.error('Failed to create chat:', error);
      throw this.handleError(error);
    }
  }

  async deleteChat(): Promise<void> {
    if (!this.chat?.id) {
      return;
    }

    try {
      await this.fetchWithAuth(
        `/ai/chats/${this.chat.id}`,
        {
          method: 'DELETE'
        },
        'text/plain;charset=UTF-8'
      );

      this.chat = null;
      this.messages = [];

      if (this.docId) {
        await this.initializeChatForDocument(this.docId);
      }

      this.notifyListeners();
    } catch (error) {
      console.error('Failed to delete chat:', error);
      throw this.handleError(error);
    }
  }

  destroy(): void {
    this.chat = null;
    this.chatChangeListener = null;
    this.docId = '';
    this.messages = [];
    this.messagesChangeListener = null;

    APIAskAiFacade.instance = null;
  }

  getTransformedMessages(): ChatMessage[] {
    return this.messages;
  }

  async regenerateMessage(messageId: string): Promise<void> {
    if (!this.chat?.id) {
      await this.createChat();
    }

    try {
      // identify message to regenerate

      const messageToRegenerate = this.messages.find(m => (m.type === 'summary' || m.type === 'reply') && m.message.id === messageId);

      if (!messageToRegenerate) return;
      if (messageToRegenerate.type !== 'summary' && messageToRegenerate.type !== 'reply') return;

      // request regeneration

      const response = await this.fetchWithAuth(`/v2/chats/${this.chat?.id}/messages/${messageId}/regenerate`, { method: 'GET' });

      const reader = response?.body?.getReader();

      if (!reader) {
        throw new Error('No response body received from API');
      }

      // stream

      await this.readStreamedResponse(reader, (responseText: string) => {
        messageToRegenerate.message.streaming = true;
        messageToRegenerate.message.body = responseText;
        this.notifyListeners();
      });

      messageToRegenerate.message.streaming = false;

      this.notifyListeners();
    } catch (error) {
      console.error('Failed to regenerate message:', error);
      throw this.handleError(error);
    }
  }

  async sendMessage(message: string): Promise<void> {
    if (!this.chat?.id) {
      await this.createChat();
    }

    try {
      // add loading message

      const userMessage: ChatMessage = {
        type: 'user',
        message: {
          id: v4(),
          body: message,
          loading: true
        }
      };

      this.messages.push(userMessage);
      this.notifyListeners();

      // send message

      const response = await this.fetchWithAuth(`/v2/chats/${this.chat?.id}/message?text=${encodeURIComponent(message)}`, { method: 'GET' });

      const reader = response?.body?.getReader();

      if (!reader) {
        throw new Error('No response body received from API');
      }

      const replyMessage: ChatMessage = {
        type: 'reply',
        message: {
          id: v4(),
          body: ''
        }
      };

      // stream response

      await this.readStreamedResponse(reader, (responseText: string) => {
        if (userMessage.message.loading === true) {
          replyMessage.message.streaming = true;
          userMessage.message.loading = false;

          this.messages.push(replyMessage);
        }

        replyMessage.message.body = responseText;
        this.notifyListeners();
      });

      // finalize

      replyMessage.message.streaming = false;
      this.notifyListeners();
    } catch (error) {
      console.error('Failed to send message:', error);
      throw this.handleError(error);
    }
  }

  setChatChangeListener(listener: (chat: ChatRecord) => void): void {
    this.chatChangeListener = listener;

    if (this.chat) {
      listener(this.chat);
    }
  }

  setMessagesChangeListener(listener: (messages: ChatMessage[]) => void): void {
    this.messagesChangeListener = listener;

    if (this.messages.length > 0) {
      listener(this.messages);
    }
  }

  async summarizeDocument(summaryLength: SummaryLengthTuple, summaryFormat: SummaryFormatTuple, onStreamingStart?: () => void): Promise<void> {
    return this.summarizePages([], summaryLength, summaryFormat, onStreamingStart);
  }

  async summarizePages(
    pageNumbersForSummary: number[],
    summaryLength: SummaryLengthTuple,
    summaryFormat: SummaryFormatTuple,
    onStreamingStart?: () => void
  ): Promise<void> {
    if (!this.chat?.id) {
      await this.createChat();
    }

    try {
      const apiFormat: SummaryFormatType = summaryFormat === 'keypoints' ? 'Outline' : 'Paragraph';
      const apiLength: SummaryLengthType = (summaryLength.charAt(0).toUpperCase() + summaryLength.slice(1)) as SummaryLengthType;

      // add loading message

      const loadingMessage = {
        type: 'summary_loading' as const,
        options: {
          mode: summaryFormat,
          length: summaryLength,
          pageIndexes: pageNumbersForSummary
        },
        message: {
          id: v4()
        }
      } satisfies ChatMessage;

      this.messages.push(loadingMessage);
      this.notifyListeners();

      const queryParams = new URLSearchParams({
        format: apiFormat,
        length: apiLength,
        pageIndexes: JSON.stringify(pageNumbersForSummary)
      });

      // request summary

      const response = await this.fetchWithAuth(`/v2/chats/${this.chat?.id}/summary?${queryParams}`, { method: 'GET' });

      const reader = response?.body?.getReader();

      if (!reader) {
        throw new Error('No response body received from API');
      }

      const messageId = v4();

      const summaryMessage: ChatMessage = {
        type: 'summary',
        message: {
          id: messageId,
          body: '',
          length: summaryLength,
          mode: summaryFormat,
          pageIndexes: pageNumbersForSummary,
          feedback: null,
          setFeedback: async (feedback: SummaryFeedback): Promise<void> => {
            if (!this.chat?.id) {
              throw new Error('Cannot set feedback: No active chat');
            }

            await this.provideFeedback(this.chat.id, messageId, feedback);
          },
          streaming: false
        }
      };

      // stream summary

      await this.readStreamedResponse(reader, (summaryText: string, streamingMessageId: string) => {
        if (onStreamingStart) {
          onStreamingStart();
        }

        if (this.messages.includes(loadingMessage)) {
          this.messages = this.messages.filter(msg => msg.type !== 'summary_loading');
          summaryMessage.message.streaming = true;
          this.messages.push(summaryMessage);
        }

        summaryMessage.message.id = streamingMessageId;
        summaryMessage.message.body = summaryText;

        summaryMessage.message.setFeedback = async (feedback: SummaryFeedback): Promise<void> => {
          if (!this.chat?.id) {
            throw new Error('Cannot set feedback: No active chat');
          }

          await this.provideFeedback(this.chat.id, streamingMessageId, feedback);
        };

        this.notifyListeners();
      });

      // finalize

      summaryMessage.message.streaming = false;
      this.notifyListeners();
    } catch (error) {
      this.messages = this.messages.filter(msg => msg.type !== 'summary_loading');
      this.notifyListeners();

      console.error('Failed to summarize pages:', error);
      throw this.handleError(error);
    }
  }

  async initializeChatForDocument(docId: string): Promise<void> {
    this.docId = docId;

    try {
      const response = await this.fetchWithAuth(`/ai/chats/lookup/libraryitem/${docId}`);

      const chat: ChatRecord | null = await response?.json();

      if (chat) {
        this.chat = chat;

        const messagesResponse = await this.fetchWithAuth(`/ai/chats/${chat.id}/messages?since=0`);
        const apiMessages: APIMessage[] = await messagesResponse?.json();

        this.messages = this.transformMessages(apiMessages);

        this.notifyListeners();
      }
    } catch (error) {
      console.error('Failed to initialize chat:', error);
      throw this.handleError(error);
    }
  }

  private async fetchWithAuth(endpoint: string, options: RequestInit = {}, contentType: string = 'application/json'): Promise<Response | null> {
    const headers = new Headers({
      'Content-Type': contentType,
      Authorization: `Bearer ${this.idToken}`,
      'X-Speechify-Client': 'WebApp',
      'X-Speechify-Client-Version': process.env.version || '0.0.0',
      ...(options.headers || {})
    });

    const response = await fetch(`${this.apiBaseUrl}${endpoint}`, {
      ...options,
      headers
    });

    if (response.status === 404) {
      return null;
    }

    if (!response.ok) {
      const errorData = await response.json().catch(() => ({ message: response.statusText }));
      throw new Error(`API request failed: ${errorData.message}`);
    }

    return response;
  }

  private filterSummaryLoadingMessages(messages: ChatMessage[]): ChatMessage[] {
    return messages.filter((message, index) => {
      const nextMessage = messages[index + 1];

      return !(message.type === 'summary_loading' && nextMessage?.type === 'summary');
    });
  }

  private handleError(error: unknown): Error {
    if (error instanceof Error) {
      return error;
    }

    return new Error('An unknown error occurred');
  }

  private notifyListeners(): void {
    if (this.chatChangeListener && this.chat) {
      this.chatChangeListener(this.chat);
    }

    if (this.messagesChangeListener) {
      this.messagesChangeListener(this.messages);
    }
  }

  private async readStreamedResponse(
    reader: ReadableStreamDefaultReader<Uint8Array>,
    onMessageCallback?: (responseText: string, streamingMessageId: string) => void
  ): Promise<string> {
    let fullText = '';

    while (whileCondition) {
      const { done, value } = await reader.read();
      if (done) break;

      const chunk = new TextDecoder().decode(value);
      const lines = chunk.split('\n');

      for (const line of lines) {
        if (line.startsWith('data: ')) {
          const jsonString = line.slice('data: '.length);

          try {
            const parsed = JSON.parse(jsonString);
            fullText = parsed.message.body;

            if (onMessageCallback) {
              onMessageCallback(fullText, parsed.message.id);
            }
          } catch (e) {
            // partial data, keep buffering if needed
          }
        }
      }
    }

    return fullText;
  }

  private transformMessages(apiMessages: APIMessage[]): ChatMessage[] {
    if (!apiMessages) {
      return [];
    }

    const chatMessages = apiMessages
      .map((msg): ChatMessage | null => {
        if (msg.message.type === 'prompt' && msg.message.prompt?.type === 'text') {
          const { body = '' } = msg.message.prompt;

          return {
            type: 'user',
            message: {
              id: msg.id,
              body,
              loading: false
            }
          };
        }

        if (
          msg.message.type === 'prompt' &&
          (msg.message.prompt?.type === 'generateSummaryForDocument' || msg.message.prompt?.type === 'generateSummaryForPages')
        ) {
          const { options, type } = msg.message.prompt;

          return {
            type: 'summary_loading',
            options: {
              length: convertFromApiLength(options?.length || 'Short'),
              mode: convertFromApiSummaryFormat(options?.format || 'Paragraph'),

              ...(type === 'generateSummaryForPages' && {
                pageIndexes: options?.pageIndexes
              })
            },
            message: {
              id: msg.id
            }
          };
        }

        if (msg.message.type === 'response' && msg.message.response && msg.message.response.type === 'summary') {
          const { body = '', feedback, options } = msg.message.response;

          return {
            type: 'summary',
            message: {
              body,
              feedback,
              id: msg.id,
              length: convertFromApiLength(options?.length || 'Short'),
              mode: convertFromApiSummaryFormat(options?.format || 'Paragraph'),
              pageIndexes: options?.pageIndexes,
              setFeedback: async (feedback: SummaryFeedback): Promise<void> => {
                if (!this.chat?.id) {
                  throw new Error('Cannot set feedback: No active chat');
                }

                await this.provideFeedback(this.chat.id, msg.id, feedback);
              }
            }
          };
        }

        if (msg.message.type === 'response' && msg.message.response) {
          const { body = '' } = msg.message.response;

          return {
            type: 'reply',
            message: {
              body,
              id: msg.id
            }
          };
        }

        return null;
      })
      .filter((msg): msg is ChatMessage => msg !== null);

    const filteredChatMessages = this.filterSummaryLoadingMessages(chatMessages);
    return filteredChatMessages;
  }

  private async provideFeedback(chatId: string, messageId: string, feedback: SummaryFeedback): Promise<void> {
    const convertedFeedback = {
      feedback: {
        type: feedback.type === 'positive' ? 'ThumbUp' : 'ThumbDown',
        text: feedback.text
      }
    };

    await this.fetchWithAuth(`/ai/feedback/${chatId}/${messageId}`, {
      method: 'POST',
      body: JSON.stringify(convertedFeedback)
    });
  }
}

export async function getAskAI() {
  const { firebaseAuth } = await import('lib/firebase/firebase.client');
  const idToken = await firebaseAuth.currentUser?.getIdToken();

  if (!idToken) {
    throw new Error('No authentication token available');
  }

  const askAi = APIAskAiFacade.getInstance(idToken);
  return { askAi };
}
