import assert from 'assert';

import { IRecord } from 'interfaces';

import { isCorsAllowed, wrapWithCorsProxy } from 'lib/speechify/adaptors/cors';
import { Nullable } from 'utils/types';

import { BaseListenableContent } from '../base';
import { ContentMetaType, ContentType } from '../content.types';

export abstract class BaseItemListenableContent extends BaseListenableContent {
  public readonly record: IRecord;
  public readonly parentFolderId: string;
  public readonly coverImageUrl: Nullable<string>;
  public readonly sourceUrl: Nullable<string> = null;
  public readonly originalSourceUrl: Nullable<string> = null;
  public readonly contentType: ContentType = ContentType.libraryItem;
  public readonly totalWords: number;
  public readonly analyticsProperties: Record<string, unknown>;

  constructor(item: IRecord) {
    super();
    this.parentFolderId = item.parentFolderId || '';
    this.record = item;
    this.useItem.setState({ id: item.id, record: item });
    this.coverImageUrl = item.coverImagePath;
    this.sourceUrl = item.sourceURL;
    this.originalSourceUrl = item.originalSourceURL;
    this.totalWords = item.wordCount ?? 0;
    this.analyticsProperties = item.analyticsProperties;
  }

  public get itemId(): string {
    return this.record.id;
  }

  override subscribeToAutoUpdateUrlWhenItemIdReady(): () => void {
    // We do not need to update the url for items as we know the id there from the beginning
    // this is no-op
    return () => {};
  }

  abstract get metaType(): ContentMetaType;

  content = async (): Promise<string> => {
    const url = this.sourceUrl;
    assert(url, 'PDFItemListenableContent: sourceUrl is null');
    if (isCorsAllowed(url)) return url;
    return wrapWithCorsProxy(url);
  };

  public get title(): string {
    return this.useItem.getState().record?.title || this.record.title;
  }

  public get wordCount(): number {
    return this.totalWords;
  }

  public get importType() {
    return null;
  }
}
