import { type MeasurementContext } from '@grafana/faro-core/dist/types/api/measurements/types';

export enum MeasurementKey {
  libraryReady = 'library_ready',
  instantListeningBookReaderReady = 'instant_listening_book_reader_ready',
  instantListeningClassicReaderReady = 'instant_listening_classic_reader_ready',
  bookReaderReady = 'book_reader_ready',
  classicReaderReady = 'classic_reader_ready',
  sdkSetup = 'sdk_setup',
  sdkBundleFactoryCreation = 'sdk_bundle_factory_creation',
  sdkBundleCreation = 'sdk_bundle_creation',
  sdkGetAllVoices = 'sdk_get_all_voices',
  zustandIdbHydration = 'zustand_idb_hydration',
  classicReaderTextLoading = 'classic_reader_text_loading',
  skipContentSettingsHydration = 'skip_content_settings_hydration'
}

type MeasurementContent<Context extends MeasurementContext> = {
  startTimestamp: number;
} & Context;

export type MeasurementTypes = {
  [MeasurementKey.libraryReady]: MeasurementContent<{}>;
  [MeasurementKey.instantListeningBookReaderReady]: MeasurementContent<{}>;
  [MeasurementKey.instantListeningClassicReaderReady]: MeasurementContent<{}>;
  [MeasurementKey.bookReaderReady]: MeasurementContent<{}>;
  [MeasurementKey.classicReaderReady]: MeasurementContent<{}>;
  [MeasurementKey.sdkSetup]: MeasurementContent<{}>;
  [MeasurementKey.sdkBundleFactoryCreation]: MeasurementContent<{}>;
  [MeasurementKey.sdkBundleCreation]: MeasurementContent<{}>;
  [MeasurementKey.sdkGetAllVoices]: MeasurementContent<{}>;
  [MeasurementKey.zustandIdbHydration]: MeasurementContent<{
    storeName: string;
  }>;
  [MeasurementKey.classicReaderTextLoading]: MeasurementContent<{}>;
  [MeasurementKey.skipContentSettingsHydration]: MeasurementContent<{}>;
};

export type MeasurementType<Key extends MeasurementKey> = MeasurementTypes[Key];
export type MeasurementContextType<Key extends MeasurementKey> = Omit<MeasurementType<Key>, 'startTimestamp'>;
