import { createSelector, createSlice } from '@reduxjs/toolkit';
import { IError, IRecord, ISearchResult, ItemType, LibraryActionType, ViewType } from 'interfaces';
import { uniqBy } from 'lodash';

import { FolderReferenceFactory } from 'lib/speechify';
import {
  addFolder,
  addInitialFiles,
  addTextDocument,
  addWebLink,
  archiveItem,
  archiveItems,
  deleteItem,
  fetchItem,
  fetchItemsCount,
  fetchSharedItem,
  moveItems,
  restoreItem,
  searchArticles,
  setName,
  setParentFolderId,
  setSearchQuery,
  subscribe,
  unsubscribe
} from 'store/library/actions';
import { itemTypeFilterFn } from 'utils/filter';
import { isThisFulfilledAction, isThisPendingAction, isThisRejectedAction, setValue } from 'utils/redux';
import { sortItemFn } from 'utils/sort';

import { RootState } from '../index';

const name = 'library';

// cacheFolderIds
// has this device previously visited this folder?
// if so, we have a cached result (even if it's empty)

const initialState: {
  cacheFolderIds: (string | null)[];
  currentFolderId: string | null;
  currentPage: number;
  currentUploadingId: string | null;
  error: IError;
  filter: string;
  folders: IRecord[];
  isInited: boolean;
  isLoading: boolean;
  isSyncing: boolean;
  itemCount: number;
  items: IRecord[];
  lastCompletedItemId: string;
  maximizeChecklist: boolean;
  onboardingDemoDocAdded: boolean;
  playerSettingsOpen: boolean;
  searchQuery: string;
  searchResults: ISearchResult[];
  sharedItems: IRecord[];
  showAddNew: boolean;
  showAddNewMenuBackdrop: boolean;
  sort: string;
  totalItemCount: number;
  viewType: ViewType | null;
  hideCanvasNotification?: boolean;
} = {
  cacheFolderIds: [],
  currentFolderId: null,
  currentPage: 1,
  currentUploadingId: null,
  error: {},
  filter: 'all',
  folders: [],
  isInited: false,
  isLoading: false,
  isSyncing: false,
  itemCount: 0,
  items: [],
  lastCompletedItemId: '',
  maximizeChecklist: false,
  onboardingDemoDocAdded: false,
  playerSettingsOpen: false,
  searchQuery: '',
  searchResults: [],
  sharedItems: [],
  showAddNew: false,
  showAddNewMenuBackdrop: false,
  sort: 'added',
  totalItemCount: 0,
  viewType: null
};

const { actions: generatedActions, reducer } = createSlice({
  name,
  initialState,
  reducers: {
    clearSyncing: state => {
      state.isSyncing = false;
    },
    hideAddNew: state => {
      state.showAddNew = false;
      state.showAddNewMenuBackdrop = false;
    },
    itemCompleted: (state, action) => {
      state.lastCompletedItemId = action.payload.itemId;
    },
    maximizeChecklist: (state, action) => {
      state.maximizeChecklist = action.payload;
    },
    reset: () => initialState,
    setCacheFolderId: (state, action) => {
      state.cacheFolderIds = state.cacheFolderIds || [];

      const idsSet = new Set(state.cacheFolderIds);
      idsSet.add(action.payload);

      state.cacheFolderIds = Array.from(idsSet);
    },
    setCurrentFolderId: (state, action) => {
      if (state.currentFolderId !== action.payload) {
        state.currentPage = 1;
      }

      setValue('currentFolderId')(state, action);
    },
    setCurrentUploadingId: (state, action) => setValue('currentUploadingId')(state, action),
    setFolders: (state, action) => {
      const currentFolderId = state.currentFolderId || null;
      const otherFolders = (state.folders || []).filter(folder => folder.parentFolderId !== currentFolderId);

      return { ...state, folders: uniqBy([...(action.payload as IRecord[]), ...otherFolders], 'id') };
    },
    setIsInited: (state, action) => setValue('isInited')(state, action),
    setItem: (state, action) => {
      const currentItems = state.items.map((item: IRecord) => {
        if (action.payload.id === item.id) {
          return { ...item };
        } else {
          return item;
        }
      });

      return { ...state, isInited: true, items: currentItems };
    },
    setItems: (state, action) => {
      const currentFolderId = state.currentFolderId || null;
      const otherItems = (state.items || []).filter(item => item.parentFolderId !== currentFolderId);

      return { ...state, isLoading: false, items: uniqBy([...action.payload, ...otherItems], 'id') };
    },
    setItemCount: (state, action) => {
      return { ...state, itemCount: action.payload };
    },
    setItemSort: (state, action) => {
      return { ...state, sort: action.payload };
    },
    setItemsFilterType: (state, action) => {
      const filterByType = action.payload;
      const sortBy = state.sort;
      const filteredItems = state.items
        .filter(item => item.type === ItemType.Record)
        .filter(itemTypeFilterFn(filterByType, state.currentFolderId))
        .sort(sortItemFn(sortBy));

      state.filter = filterByType;
      state.itemCount = filteredItems.length;
    },
    setPlayerSettingsOpen: (state, action) => {
      state.playerSettingsOpen = action.payload;
    },
    setSyncing: state => {
      state.isSyncing = true;
    },
    showAddNew: state => {
      state.showAddNew = true;
    },
    showAddNewWithBackdrop: state => {
      state.showAddNew = true;
      state.showAddNewMenuBackdrop = true;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setOnboardingDemoDocAdded: (state, action) => {
      state.onboardingDemoDocAdded = action.payload;
    },
    togglePlayerSettingOpen: state => {
      state.playerSettingsOpen = !state.playerSettingsOpen;
    },
    setViewType: (state, action) => {
      state.viewType = action.payload;
    },
    hideCanvasNotification: state => {
      state.hideCanvasNotification = true;
    }
  },
  extraReducers: builder => {
    builder.addCase(archiveItem.pending, (state, action) => {
      const itemsKey: keyof Pick<typeof state, 'items' | 'folders'> = action.meta.arg.isFolder ? 'folders' : 'items';
      state[itemsKey] = state[itemsKey].filter(item => item.id !== action.meta.arg.itemId);
    });

    builder.addCase(archiveItems.pending, (state, action) => {
      const itemsCache = new Map(state.items.map(item => [item.id, item]));
      const foldersCache = new Map(state.folders.map(item => [item.id, item]));

      for (const id of action.meta.arg) {
        if (itemsCache.has(id)) {
          itemsCache.delete(id);
        } else if (foldersCache.has(id)) {
          foldersCache.delete(id);
        }
      }

      if (state.items.length !== itemsCache.size) {
        state.items = [...itemsCache.values()];
      }

      if (state.folders.length !== foldersCache.size) {
        state.folders = [...foldersCache.values()];
      }
    });

    builder.addCase(deleteItem.pending, (state, action) => {
      state.items = state.items.filter(item => item.id !== action.meta.arg);
    });

    builder
      .addCase(fetchItem.fulfilled, (state, action) => {
        const existingItem = state.items.find(item => item.id === action.payload.id);

        if (existingItem) {
          state.items = state.items.map(item => (item.id === action.payload.id ? { ...item, ...action.payload } : item));
        } else {
          state.items.push({ ...action.payload });
        }

        // reset fetchItem error state
        state.error = state.error || {};
        state.error[LibraryActionType.fetchItem] = undefined;
      })

      .addCase(fetchItem.rejected, (state, action) => {
        const ERROR_MSG = 'Missing or insufficient permissions.';

        if (action?.error?.message === ERROR_MSG) {
          state.error = state.error || {};
          state.error[LibraryActionType.fetchItem] = action.meta.arg;
        }
      });

    builder.addCase(fetchSharedItem.fulfilled, (state, action) => {
      state.sharedItems = [{ ...action.payload, characterIndex: 0, pageIndex: 0 } as IRecord];
    });

    builder.addCase(moveItems.fulfilled, (state, action) => {
      const payload = action.payload;

      if (payload) {
        const getMovedItems = (itemIds: string[], items: IRecord[]) =>
          itemIds.reduce<IRecord[]>((moved, itemId) => {
            const movedItem = items.find(item => item.id === itemId);

            if (movedItem) {
              movedItem.parentFolderId = payload.parentFolderId;
              moved.push(movedItem);
            }

            return moved;
          }, []);

        const { itemIds, folderIds } = payload;
        const movedItems = getMovedItems(itemIds, state.items);
        const movedFolders = getMovedItems(folderIds, state.folders);

        state.items = uniqBy([...state.items, ...movedItems], 'id');
        state.folders = uniqBy([...state.folders, ...movedFolders], 'id');
      }
    });

    builder.addCase(setSearchQuery.fulfilled, (state, action) => {
      if (action.payload) state.searchQuery = action.payload.searchQuery;
    });

    // ESLint: 'state' is defined but never used & 'action' is defined but never used
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-unused-vars
    builder.addCase(restoreItem.fulfilled, (state, action) => {
      // we do nothing here as we need to also store the archived items or so in Redux store to render on Trash page
    });

    builder.addCase(searchArticles.fulfilled, (state, action) => {
      if (action.payload) state.searchResults = action.payload.searchResults;
    });

    builder.addCase(setParentFolderId.fulfilled, (state, action) => {
      const payload = action.payload;

      if (!payload) return;

      const itemsKey: keyof Pick<typeof state, 'items' | 'folders'> = payload.isFolder ? 'folders' : 'items';
      const movedItem = state[itemsKey].find(item => item.id === payload.itemId);

      if (movedItem) {
        movedItem.parentFolderId = payload.parentFolderId;
        state[itemsKey] = uniqBy([...state[itemsKey], movedItem], 'id');
      }
    });

    builder.addCase(setName.fulfilled, (state, action) => {
      const payload = action.payload;

      if (payload) {
        const { itemId, name, isFolder } = payload;
        const items = isFolder ? state.folders : state.items;
        const item = items.find((item: IRecord) => item.id === itemId);

        if (item) {
          item.title = name;
        }
      }
    });

    builder.addCase(subscribe.pending, state => {
      state.itemCount = 0;
    });

    builder.addCase(subscribe.fulfilled, state => {
      const sortBy = state.sort;
      const filterByType = state.filter;
      const filteredItems = state.items
        .filter(item => item.type === ItemType.Record)
        .filter(itemTypeFilterFn(filterByType, state.currentFolderId))
        .sort(sortItemFn(sortBy));

      state.itemCount = filteredItems.length;
    });

    builder.addCase(fetchItemsCount.fulfilled, (state, action) => {
      state.totalItemCount = action.payload;
    });

    builder.addCase(addFolder.fulfilled, (state, action) => {
      if (!action.payload) return;

      const folderReference = action.payload.reference;

      if (state.folders.find(folder => folderReference.equals(FolderReferenceFactory.fromId(folder.id)))) return;

      state.folders.push({
        childrenCount: 0,
        createdAt: new Date(),
        id: action.payload.reference.asRaw(),
        parentFolderId: action.meta.arg.parentFolderId ?? null,
        title: action.meta.arg.title,
        type: ItemType.Folder
      } as IRecord);
    });

    builder
      .addMatcher(isThisPendingAction(name), state => {
        state.isLoading = true;
      })
      .addMatcher(isThisRejectedAction(name), state => {
        state.isLoading = false;
      })
      .addMatcher(isThisFulfilledAction(name), state => {
        state.isLoading = false;
      });
  }
});

export const getById = (itemId: string) =>
  createSelector(
    (state: RootState) => state,
    (state: RootState) => ({ ...state.library.items.filter((item: IRecord) => item.id === itemId)[0] })
  );

export const getFolderById = (folderId: string) =>
  createSelector(
    (state: RootState) => state,
    (state: RootState): IRecord => ({ ...state.library.folders?.filter((folder: IRecord) => folder.id === folderId)[0] })
  );

export const getFolders = createSelector(
  (state: RootState) => state,
  (state: RootState) => state.library.items.filter((item: IRecord) => item.type === ItemType.Folder)
);

export const getSharedItemById = (itemId: string) =>
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      const item = state.library.sharedItems?.find((item: IRecord) => item.id === itemId);
      if (item) return { ...item };
      return undefined;
    }
  );

export const getError = (action: string) =>
  createSelector(
    (state: RootState) => state,
    (state: RootState) => state.library.error[action]
  );

const actions = {
  ...generatedActions,
  addFolder,
  addInitialFiles,
  addTextDocument,
  addWebLink,
  archiveItem,
  archiveItems,
  deleteItem,
  fetchItem,
  fetchItemsCount,
  fetchSharedItem,
  moveItems,
  restoreItem,
  searchArticles,
  setName,
  setParentFolderId,
  setSearchQuery,
  subscribe,
  unsubscribe
};

const selectors = { getById, getFolderById, getFolders, getSharedItemById, getError };

export { actions, selectors };

export default reducer;
