import { useRouter } from 'next/router';
import { useCallback } from 'react';

import { profilingStoreActions } from 'modules/profiling/profilingStore';

interface TransitionOptions {
  shallow?: boolean;
  locale?: string | false;
  scroll?: boolean;
  unstable_skipClientCache?: boolean;
}

export type NavigateFunction = (url: string, as?: string, options?: TransitionOptions) => void;

export function useNavigate(): NavigateFunction {
  const router = useRouter();

  return useCallback(
    (url: string, as?: string, options?: TransitionOptions) => {
      profilingStoreActions.navigate();
      router.push(url, as, options);
    },
    [router]
  );
}
