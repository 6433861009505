import type { BlockParagraphMeta } from 'modules/sdk/lib/facade/legacyReader/classic/ClassicBlockInfo';

import { renderedContentStoreActions } from '../../../stores/renderedContentStore';
import type { ClassicBlockProps } from './';
import { ClassicElementRenderer } from './elements/ClassicElementRenderer';

export const ClassicBlockParagraph = (props: ClassicBlockProps<BlockParagraphMeta>) => {
  return (
    <div
      className="whitespace pre-wrap my-5 clear-left"
      ref={ref => renderedContentStoreActions.updateRenderedBlock(props.block.blockIndex, ref)}
      data-block-index={`${props.block.blockIndex}`}
    >
      {props.block.elements.map(element => (
        <ClassicElementRenderer key={element.elementId} element={element} />
      ))}
    </div>
  );
};
