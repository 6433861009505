import { MetaAttributes, PushErrorOptions } from '@grafana/faro-core';
import { PushEventOptions } from '@grafana/faro-core/dist/types/api/events/types';
import { type MeasurementsAPI, PushMeasurementOptions } from '@grafana/faro-core/dist/types/api/measurements/types';
import { Faro, LogsAPI, MetaUser } from '@grafana/faro-web-sdk';

import { ErrorWithContext } from 'utils/error';

import { ConsoleInstrumentation } from './ConsoleInstrumentation';
import { isGrafanaCloudFeatureFlagEnabled } from './featureFlag';
import { initFaro } from './initFaro';
import { prepareData } from './prepare-data';

let faroSpeechify: Faro | undefined;
let faroCloud: Faro | undefined;

(async () => {
  if (typeof window !== 'undefined' && window.location.hostname !== 'localhost') {
    if (process.env.NEXT_PUBLIC_FARO_COLLECTOR_URL) {
      faroSpeechify = initFaro(process.env.NEXT_PUBLIC_FARO_COLLECTOR_URL);
    }

    const isCloudFaroEnabled = await isGrafanaCloudFeatureFlagEnabled();

    if (isCloudFaroEnabled && process.env.NEXT_PUBLIC_FARO_CLOUD_COLLECTOR_URL) {
      faroCloud = initFaro(process.env.NEXT_PUBLIC_FARO_CLOUD_COLLECTOR_URL, true);
    }
  }
})();

const log = (...args: Parameters<LogsAPI['pushLog']>) => {
  faroSpeechify?.api.pushLog(...args);
  faroCloud?.api.pushLog(...args);
};

const logFaroError = (faro: Faro | undefined, error: Error, source: string | undefined = undefined, options: PushErrorOptions = { context: {} }) => {
  if (faro) {
    let errorToLog = error;

    if (errorToLog && errorToLog.message === 'Chrome runtime not available') {
      return;
    }

    if (!(error instanceof Error)) {
      errorToLog = new Error(error);
    }

    let context = source ? { ...options.context, source } : options.context;
    if (error instanceof ErrorWithContext) {
      context = { ...context, ...error.context };
    }

    faro.api.pushError(errorToLog, {
      ...(options || {}),
      context
    });
    const consoleInstrumentation = faro.instrumentations.instrumentations.find(i => i instanceof ConsoleInstrumentation);

    if (consoleInstrumentation) {
      if (consoleInstrumentation.unpatchedConsole) {
        try {
          if (source) {
            consoleInstrumentation.unpatchedConsole.error(errorToLog, source);
          } else {
            consoleInstrumentation.unpatchedConsole.error(errorToLog);
          }
        } catch (e) {
          console.error('Error logging failed through instrumentation, falling back to default console.error', errorToLog, source);
        }
      }
    }
  } else if (source) {
    console.error(error, source);
  } else {
    console.error(error);
  }
};

const logError = (error: Error, source: string | undefined = undefined, options: PushErrorOptions = { context: {} }) => {
  logFaroError(faroSpeechify, error, source, options);
  logFaroError(faroCloud, error, source, options);
};

const logEventInObservability = (name: string, attributes?: MetaAttributes, domain?: string, options?: PushEventOptions) => {
  faroSpeechify?.api.pushEvent(name, prepareData(attributes), domain, options);
  faroCloud?.api.pushEvent(name, prepareData(attributes), domain, options);
};

const logMeasurement = (...args: Parameters<MeasurementsAPI['pushMeasurement']>) => {
  (process.env.NODE_ENV === 'development' || process.env.NEXT_PUBLIC_VERBOSE_MEASUREMENT) &&
    (() => {
      const output = [args[0]['type'], args[0]['values'], args[1] && args[1].context].filter(Boolean);
      console.info('[measure] ', ...output);
    })();
  faroSpeechify?.api.pushMeasurement(...args);
  faroCloud?.api.pushMeasurement(...args);
};

const measure = (name: string, time: number, options: PushMeasurementOptions = {}) => {
  logMeasurement(
    {
      type: name,
      values: {
        [name]: time
      }
    },
    options
  );
};

const createMeasurement = (name: string) => {
  const startTime = performance.now();

  return () => {
    const endTime = performance.now() - startTime;
    logMeasurement({
      type: name,
      values: {
        [name]: endTime
      }
    });
  };
};

// ESLint: Unexpected any & Unexpected any
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-explicit-any
const instrumentAction = async <F extends (...args: any[]) => any>(cb: F, name: string): Promise<ReturnType<F>> => {
  try {
    const end = createMeasurement(name);
    const result = await cb();
    end();
    return result;
  } catch (e) {
    if (e instanceof Error) {
      faroSpeechify?.api.pushError(e, { type: name });
      faroCloud?.api.pushError(e, { type: name });
    }

    throw e;
  }
};

const updateFaroSessionAttributes = (faro: Faro | undefined, attributes: MetaAttributes) => {
  if (faro) {
    const session = faro.api.getSession() || {};

    faro.api.setSession({
      ...session,
      attributes: {
        ...(session?.attributes || {}),
        ...prepareData(attributes)
      }
    });

    faro.api.pushLog(['Faro session updated']);
  }
};

const updateSessionAttributes = (attributes: MetaAttributes) => {
  updateFaroSessionAttributes(faroSpeechify, attributes);
  updateFaroSessionAttributes(faroCloud, attributes);
};

const setUser = (user: MetaUser) => {
  faroSpeechify?.api.setUser(user);
  faroCloud?.api.setUser(user);
};

export { createMeasurement, instrumentAction, log, logError, logEventInObservability, logMeasurement, measure, setUser, updateSessionAttributes };
