import type { SpeechifyURI } from '@speechifyinc/multiplatform-sdk';
import { WebAppImportFlow, WebAppImportType } from 'components/library/import/constants';

import type { Callback } from 'lib/speechify/adaptors/lib/typeAliases';

import { BaseListenableContent } from '../base';
import { ContentMetaType, ContentType } from '../content.types';

export abstract class BaseURLListenableContent extends BaseListenableContent {
  public readonly importType: WebAppImportType;
  public readonly importFlow: WebAppImportFlow;

  public readonly folderId?: string;
  public readonly contentType: ContentType = ContentType.url;

  public readonly url: string;
  public readonly urlWithoutCorsProxy: string;

  protected _name: string;
  protected _mimeType: string;

  public readonly onImportCompleteCallback: Callback<SpeechifyURI>;
  public readonly onErrorCallback?: (error: Error) => void;

  constructor({
    folderId,
    mimeType,
    name,
    onImportCompleteCallback,
    onErrorCallback,
    url,
    urlWithoutCorsProxy,
    importType,
    importFlow
  }: {
    folderId?: string;
    mimeType: string;
    name: string;
    onImportCompleteCallback: Callback<SpeechifyURI>;
    onErrorCallback?: (error: Error) => void;
    url: string;
    urlWithoutCorsProxy?: string;
    importType: WebAppImportType;
    importFlow: WebAppImportFlow;
  }) {
    super();
    this._mimeType = mimeType;
    this._name = name || url;
    this.url = url;
    this.urlWithoutCorsProxy = urlWithoutCorsProxy || url;
    this.folderId = folderId;
    this.onImportCompleteCallback = onImportCompleteCallback;
    this.onErrorCallback = onErrorCallback;
    this.importType = importType;
    this.importFlow = importFlow;
  }

  abstract get metaType(): ContentMetaType;

  content = async (): Promise<string> => {
    return this.url;
  };

  public get title(): string {
    return this.useItem.getState().record?.title || this._name;
  }

  public get wordCount(): number {
    // TODO: Implement word count for URL item
    return 0;
  }

  public get needsUrlHostNameOverride(): boolean {
    return this.urlWithoutCorsProxy !== this.url;
  }

  public get urlHostnameWithoutCorsProxy(): string {
    const url = new URL(this.urlWithoutCorsProxy);
    return url.hostname;
  }
}
