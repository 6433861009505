import type { TableCellElementMeta, TableElementMeta, TableRowElementMeta } from 'modules/sdk/lib/facade/legacyReader/classic/ClassicBlockInfo';

import { ClassicElementRenderer } from '../ClassicElementRenderer';
import type { InternalClassicElementProps } from './';

export function ClassicElementTable(props: InternalClassicElementProps<TableElementMeta>) {
  return (
    <table className="whitespace-pre-wrap underline" data-element-id={props.element.elementId}>
      <tbody>
        {props.element.elements.map(element => (
          <ClassicElementRenderer key={element.elementId} element={element} />
        ))}
      </tbody>
    </table>
  );
}

export function ClassicElementTableRow(props: InternalClassicElementProps<TableRowElementMeta>) {
  return (
    <tr data-element-id={props.element.elementId}>
      {props.element.elements.map(element => (
        <ClassicElementRenderer key={element.elementId} element={element} />
      ))}
    </tr>
  );
}

export function ClassicElementTableCell(props: InternalClassicElementProps<TableCellElementMeta>) {
  const children = props.element.elements.map(element => <ClassicElementRenderer key={element.elementId} element={element} />);

  if (props.meta.data.isHeader) {
    return (
      <th data-element-id={props.element.elementId} scope="col">
        {children}
      </th>
    );
  }
  return (
    <td className="align-top" data-element-id={props.element.elementId}>
      {children}
    </td>
  );
}
