import LoadingSkeleton from 'components/elements/LoadingSkeleton';
import { NavProps } from 'components/newListeningExperience/topnav/Nav';
import dynamic from 'next/dynamic';
import React, { FC } from 'react';

import { FeatureNameEnum, useFeatureVariant } from 'hooks/useFeatureFlags';
import { ListenableContent } from 'modules/sdk/lib';

const ListeningExperienceV2 = dynamic(
  () =>
    import(
      /* webpackChunkName: "components/listening/ListeningExperienceV2" */
      'modules/listening/components/ListeningExperienceV2'
    ),
  { ssr: false, loading: () => <LoadingSkeleton /> }
);

const ListeningExperienceV3 = dynamic(
  () =>
    import(
      /* webpackChunkName: "components/listening/ListeningExperienceV3" */
      'modules/listening/components/ListeningExperienceV3'
    ).then(mod => mod.ListeningExperienceV3),
  { ssr: false, loading: () => <LoadingSkeleton /> }
);

export type ListeningExperienceProps = {
  listenableContent: ListenableContent;
} & Pick<NavProps, 'onBackArrowClick'>;

export const ListeningExperience: FC<ListeningExperienceProps> = ({ listenableContent, onBackArrowClick }) => {
  const isV3Enabled = useFeatureVariant(FeatureNameEnum.WEB_APP_READER_API);

  if (isV3Enabled.isLoading) {
    return null;
  }

  if (isV3Enabled.variant) {
    return <ListeningExperienceV3 listenableContent={listenableContent} onBackArrowClick={onBackArrowClick} />;
  } else {
    return <ListeningExperienceV2 listenableContent={listenableContent} onBackArrowClick={onBackArrowClick} />;
  }
};
