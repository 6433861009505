import { AxiosRequestConfig } from 'axios';

import { auth } from 'lib/speechify';

export const getAuthHeaders = async (): Promise<AxiosRequestConfig> => {
  const idToken = await auth.currentUser?.getIdToken();

  return {
    headers: {
      Authorization: `Bearer ${idToken}`
    }
  };
};
