import * as React from 'react';

function OutlookIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_24026_4969)">
        <path
          d="M22 12.4499C22.0011 12.2986 21.9203 12.1578 21.787 12.0786H21.7846L21.7763 12.0741L14.846 8.10513C14.8161 8.08557 14.785 8.06769 14.753 8.05159C14.4854 7.91803 14.1676 7.91803 13.9 8.05159C13.8679 8.0677 13.8369 8.08557 13.807 8.10513L6.87674 12.0741L6.86838 12.0786C6.65657 12.2061 6.59163 12.4755 6.72335 12.6804C6.76216 12.7408 6.81561 12.7911 6.87907 12.827L13.8093 16.796C13.8393 16.8154 13.8704 16.8332 13.9023 16.8495C14.1699 16.9831 14.4878 16.9831 14.7554 16.8495C14.7873 16.8332 14.8183 16.8154 14.8484 16.796L21.7786 12.827C21.917 12.7489 22.0016 12.6049 22 12.4499Z"
          fill="#0A2767"
        />
        <path
          d="M7.68463 9.79995H12.2325V13.8333H7.68463V9.79995ZM21.0697 5.7V3.855C21.0807 3.3937 20.7033 3.01105 20.2265 3H8.42464C7.94785 3.01105 7.57045 3.3937 7.58138 3.855V5.7L14.5581 7.49999L21.0697 5.7Z"
          fill="#0364B8"
        />
        <path d="M7.58139 5.69995H12.2325V9.74995H7.58139V5.69995Z" fill="#0078D4" />
        <path d="M16.8837 5.69995H12.2325V9.74995L16.8837 13.7999H21.0698V9.74995L16.8837 5.69995Z" fill="#28A8EA" />
        <path d="M12.2325 9.75H16.8837V13.8H12.2325V9.75Z" fill="#0078D4" />
        <path d="M12.2325 13.7998H16.8837V17.8498H12.2325V13.7998Z" fill="#0364B8" />
        <path d="M7.68463 13.8333H12.2325V17.4998H7.68463V13.8333Z" fill="#14447D" />
        <path d="M16.8837 13.7998H21.0697V17.8498H16.8837V13.7998Z" fill="#0078D4" />
        <path
          d="M21.787 12.8023L21.7781 12.8068L14.8479 16.5778C14.8177 16.5958 14.787 16.6129 14.7549 16.6282C14.6372 16.6824 14.5093 16.713 14.3791 16.7182L14.0004 16.504C13.9685 16.4885 13.9374 16.4712 13.9074 16.4522L6.88417 12.5742H6.88092L6.65115 12.45V20.0837C6.65474 20.5931 7.08426 21.0032 7.61068 21H21.0553C21.0632 21 21.0702 20.9963 21.0786 20.9963C21.1898 20.9895 21.2994 20.9673 21.4042 20.9306C21.4494 20.9121 21.4931 20.8901 21.5349 20.865C21.566 20.8479 21.6195 20.8105 21.6195 20.8105C21.8578 20.6399 21.9988 20.3705 22 20.0838V12.45C21.9998 12.596 21.9184 12.7306 21.787 12.8023Z"
          fill="url(#paint0_linear_24026_4969)"
        />
        <path
          opacity="0.5"
          d="M21.6279 12.4198V12.8878L14.3814 17.715L6.87906 12.5773C6.87906 12.5748 6.87697 12.5728 6.8744 12.5728L6.18604 12.1723V11.8348L6.46976 11.8303L7.06975 12.1633L7.0837 12.1678L7.13486 12.1993C7.13486 12.1993 14.186 16.0918 14.2046 16.1008L14.4744 16.2538C14.4976 16.2448 14.5209 16.2358 14.5488 16.2268C14.5628 16.2178 21.5488 12.4153 21.5488 12.4153L21.6279 12.4198Z"
          fill="#0A2767"
        />
        <path
          d="M21.7869 12.8023L21.7781 12.8072L14.8479 16.5782C14.8176 16.5962 14.787 16.6133 14.7549 16.6286C14.4857 16.7558 14.171 16.7558 13.9018 16.6286C13.87 16.6134 13.8389 16.5965 13.8088 16.5782L6.87859 12.8072L6.87023 12.8023C6.73643 12.7321 6.65254 12.5972 6.65115 12.45V20.0837C6.65448 20.593 7.08386 21.0032 7.61018 21C7.61019 21 7.6102 21 7.61022 21H21.0409C21.5672 21.0032 21.9966 20.593 22 20.0838C22 20.0838 22 20.0838 22 20.0837V12.45C21.9998 12.596 21.9184 12.7306 21.7869 12.8023Z"
          fill="#1490DF"
        />
        <path
          opacity="0.1"
          d="M14.9488 16.5225L14.8451 16.5787C14.815 16.5972 14.784 16.6142 14.7521 16.6296C14.6378 16.6838 14.5136 16.7156 14.3865 16.7232L17.0232 19.74L21.6228 20.8123C21.7488 20.7202 21.8491 20.5991 21.9144 20.46L14.9488 16.5225Z"
          fill="black"
        />
        <path
          opacity="0.05"
          d="M15.4186 16.2668L14.8451 16.5787C14.815 16.5972 14.784 16.6142 14.7521 16.6295C14.6378 16.6838 14.5136 16.7156 14.3865 16.7231L15.6218 20.0185L21.6242 20.8109C21.8606 20.6392 21.9998 20.3698 22 20.0837V19.9852L15.4186 16.2668Z"
          fill="black"
        />
        <path
          d="M7.62324 20.9999H21.0395C21.246 21.001 21.4473 20.9379 21.6139 20.8199L14 16.5049C13.968 16.4894 13.9369 16.4721 13.907 16.4532L6.8837 12.575H6.88045L6.65115 12.45V20.0576C6.65063 20.5776 7.08585 20.9994 7.62324 20.9999C7.62323 20.9999 7.62323 20.9999 7.62324 20.9999Z"
          fill="#28A8EA"
        />
        <path
          opacity="0.1"
          d="M13.1628 7.4247V17.0232C13.1619 17.3597 12.9504 17.6622 12.6279 17.7882C12.528 17.8297 12.4204 17.8512 12.3116 17.8512H6.65115V7.04985H7.58139V6.59985H12.3116C12.7815 6.60158 13.1618 6.97009 13.1628 7.4247Z"
          fill="black"
        />
        <path
          opacity="0.2"
          d="M12.6977 7.87469V17.4732C12.6988 17.5819 12.675 17.6895 12.6279 17.7882C12.4987 18.0962 12.1901 18.2983 11.8465 18.2998H6.65115V7.04984H11.8465C11.9815 7.04853 12.1145 7.0811 12.2325 7.14435C12.5177 7.28332 12.6976 7.56581 12.6977 7.87469Z"
          fill="black"
        />
        <path
          opacity="0.2"
          d="M12.6977 7.87469V16.5732C12.6954 17.0276 12.3162 17.3959 11.8465 17.3998H6.65115V7.04984H11.8465C11.9815 7.04853 12.1145 7.0811 12.2325 7.14435C12.5177 7.28332 12.6976 7.56581 12.6977 7.87469Z"
          fill="black"
        />
        <path
          opacity="0.2"
          d="M12.2325 7.87465V16.5731C12.232 17.0283 11.8518 17.3976 11.3814 17.3998H6.65115V7.0498H11.3814C11.8517 7.05005 12.2328 7.41915 12.2325 7.87421C12.2325 7.87436 12.2325 7.8745 12.2325 7.87465Z"
          fill="black"
        />
        <path
          d="M2.85255 7.0498H11.38C11.8508 7.0498 12.2325 7.41911 12.2325 7.87465V16.1249C12.2325 16.5805 11.8508 16.9498 11.38 16.9498H2.85255C2.3817 16.9498 2 16.5805 2 16.1249V7.87465C2 7.41911 2.38171 7.0498 2.85255 7.0498Z"
          fill="url(#paint1_linear_24026_4969)"
        />
        <path
          d="M4.66465 10.5096C4.87478 10.0765 5.21395 9.714 5.63861 9.46877C6.1089 9.20827 6.64437 9.0784 7.18606 9.09346C7.68811 9.08292 8.18351 9.20606 8.61815 9.44941C9.0268 9.68519 9.35593 10.0307 9.56558 10.4439C9.79391 10.8993 9.9077 11.4004 9.89768 11.9064C9.90875 12.4352 9.79167 12.9592 9.55582 13.4364C9.34117 13.8644 9.0016 14.2222 8.57908 14.4655C8.12768 14.7164 7.61389 14.8427 7.0935 14.8309C6.58072 14.8429 6.0744 14.7184 5.62978 14.4709C5.21758 14.2349 4.88433 13.889 4.66978 13.4746C4.4401 13.0259 4.32495 12.5304 4.33397 12.0297C4.3244 11.5054 4.43744 10.9857 4.66465 10.5096ZM5.70279 12.9531C5.81482 13.2269 6.00481 13.4644 6.2507 13.638C6.50115 13.8074 6.80105 13.8947 7.10651 13.8873C7.43181 13.8998 7.75246 13.8094 8.02 13.6299C8.26277 13.4569 8.44777 13.2187 8.55209 12.945C8.66871 12.6393 8.72626 12.3155 8.72186 11.9897C8.72546 11.6607 8.67136 11.3336 8.56185 11.0222C8.46513 10.741 8.2861 10.4931 8.04651 10.3085C7.78568 10.1205 7.46589 10.0251 7.14092 10.0385C6.82885 10.0306 6.52223 10.1187 6.26512 10.29C6.01507 10.4643 5.82148 10.7039 5.70698 10.9808C5.45297 11.6153 5.45165 12.3185 5.70325 12.954L5.70279 12.9531Z"
          fill="white"
        />
        <path d="M16.8837 5.69995H21.0697V9.74995H16.8837V5.69995Z" fill="#50D9FF" />
      </g>
      <defs>
        <linearGradient id="paint0_linear_24026_4969" x1="14.3256" y1="12.45" x2="14.3256" y2="21" gradientUnits="userSpaceOnUse">
          <stop stopColor="#35B8F1" />
          <stop offset="1" stopColor="#28A8EA" />
        </linearGradient>
        <linearGradient id="paint1_linear_24026_4969" x1="3.7776" y1="6.40529" x2="10.1295" y2="17.7761" gradientUnits="userSpaceOnUse">
          <stop stopColor="#1784D9" />
          <stop offset="0.5" stopColor="#107AD5" />
          <stop offset="1" stopColor="#0A63C9" />
        </linearGradient>
        <clipPath id="clip0_24026_4969">
          <rect width="20" height="18" fill="white" transform="translate(2 3)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default OutlookIcon;
