import { renderedContentStoreActions } from 'modules/listening/features/reader/stores/renderedContentStore';
import type { TextElementMeta } from 'modules/sdk/lib/facade/legacyReader/classic/ClassicBlockInfo';

import type { LeafClassicElementProps } from './';

export function ClassicElementText(props: LeafClassicElementProps<TextElementMeta>) {
  return (
    <span
      dir="auto"
      ref={ref => {
        renderedContentStoreActions.updateRenderedContent(`${props.element.elementId}`, ref);
      }}
    >
      {props.meta.data.text}
    </span>
  );
}
