import FileUploader, { UploadStatusEnum, UploadingFile } from 'components/upload/FileUploader';
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'store';
import { carryParams } from 'utils';

import { useNavigate } from 'hooks/useNavigate';
import { useTranslation } from 'hooks/useTypedTranslation';
import { actions as importActions, selectors as importSelectors } from 'store/import';
import { actions as toastActions } from 'store/toast';

export function FilesProgress() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation('common');
  const { files: importingFiles } = useSelector(importSelectors.getImportState);
  const uploadingFiles = useMemo<UploadingFile[]>(
    () =>
      Object.entries(importingFiles).map(([id, fileState]) => ({
        id,
        file: fileState.file,
        status:
          fileState.state === 'completed' ? UploadStatusEnum.Complete : fileState.state === 'failed' ? UploadStatusEnum.Failed : UploadStatusEnum.Processing
      })),
    [importingFiles]
  );

  const handleFileClick = useCallback(
    (uploadingFile: UploadingFile) => {
      const fileState = importingFiles[uploadingFile.id];

      if (fileState.state === 'completed' && fileState.itemId) {
        navigate(carryParams(`/item/${fileState.itemId}`));
      }
    },
    [importingFiles, navigate]
  );

  const handleClose = useCallback(() => {
    const importingFileCount = uploadingFiles.filter(file => file.status === UploadStatusEnum.Processing).length;

    if (importingFileCount > 0) {
      dispatch(
        toastActions.add({
          title: t('Uploading in the background')!,
          description: `${importingFileCount} ${t('items left')}`,
          type: 'waiting'
        })
      );
    }

    dispatch(importActions.resetImportingFiles());
  }, [dispatch, t, uploadingFiles]);

  return <>{uploadingFiles.length > 0 && <FileUploader onClose={handleClose} onOpen={handleFileClick} uploadingFiles={uploadingFiles} />}</>;
}
