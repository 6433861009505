import type { ListItemElementMeta } from 'modules/sdk/lib/facade/legacyReader/classic/ClassicBlockInfo';

import { ClassicElementRenderer } from '../ClassicElementRenderer';
import type { InternalClassicElementProps } from './';

export function ClassicElementListItem(props: InternalClassicElementProps<ListItemElementMeta>) {
  return (
    <li data-element-id={props.element.elementId}>
      {props.element.elements.map(element => (
        <ClassicElementRenderer key={element.elementId} element={element} />
      ))}
    </li>
  );
}
